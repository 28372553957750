import { useLocation, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { rootRouter } from './rootRouter';
import { searchRoute } from '@/utils/searchRoute';
import { getToken } from '@/utils/token';

/**
 * @description 路由守卫组件
 * */
function AuthRouter(props) {
  const { pathname } = useLocation();
  const status = useSelector((state) => state.status);
  const route = searchRoute(pathname, rootRouter);

  const token = getToken();
  //  * 判断是否登录
  if (route?.requiresAuth) {
    if (!token) {
      return <Navigate to="/login" replace />;
    }
    if (status) {
      return <Navigate to="/authentication" replace />;
    }
  }

  // eslint-disable-next-line react/prop-types
  return props.children;
}

export default AuthRouter;
