import { Steps, Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useRequest } from 'ahooks';
import { getFinancingInfo } from '@/services/myApplication';

const { Step } = Steps;

/*
* 进度全部流程前端固定，后端返回当前进度 根据status显示不同UI（0：正常，1：拒绝，2：取消, 3: 还款成功（流程完成））
* */
const arr = [
  { title: '已申请', subTitle: '已提交贷款申请，请您等待响应' },
  { title: '审核中', subTitle: '正在处理你的申请，请耐心等待' },
  { title: '已签约', subTitle: '已签约' },
  { title: '放款成功', subTitle: '已经放款成功，请您按时归还贷款' },
  { title: '还款成功', subTitle: '贷款已完成还款' },
];

export default function Timeline() {
  const { search } = useLocation();
  const { data = {}, loading } = useRequest(() => getFinancingInfo({ id: qs.parse(search).id, type: qs.parse(search).type }));
  // eslint-disable-next-line no-unsafe-optional-chaining
  const endObj = data?.item?.[data?.item.length - 1];
  // eslint-disable-next-line no-unsafe-optional-chaining
  const current = data?.item?.length - 1;

  return (
    <Spin spinning={loading}>
      <div>
        <div className="myapplication-detail__title">进度查询</div>
        <div className="myapplication-detail__sub-title">我的申请信息</div>
        <div className="myapplication-detail__grid">
          <div>
            产品名称：
            {data?.productName || '-'}
          </div>
          <div>
            银行名称：
            {data?.publishOrgName}
          </div>
          <div>
            申请金额（万元）：
            {data?.loanMoney}
          </div>
          <div>
            资金用途：
            {data?.loanType}
          </div>
          <div>
            贷款期限：
            {data?.loanTerm}
            个月
          </div>
          <div>
            申请时间：
            {data?.gmtCreate}
          </div>
        </div>
        <div className="myapplication-detail__sub-title">进度信息</div>
        {
          (endObj?.status === 0 || endObj?.status === 3)
          && (
            <Steps current={endObj?.status === 3 ? 6 : current} labelPlacement="vertical">
              {
                arr.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key,max-len
                  <Step key={index} title={current > 2 && index === 1 ? '审核通过' : item.title} description={data?.item?.[index]?.time} subTitle={item.subTitle} />
                ))
              }
            </Steps>
          )
        }
        {
          endObj?.status === 1
          && (
            <Steps current={current} labelPlacement="vertical" status="error">
              {
                arr.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Step key={index} title={index === current ? '审核未通过' : item.title} description={data?.item?.[index]?.time} subTitle={index === current ? endObj?.operateReason : item.subTitle} />
                ))
              }
            </Steps>
          )
        }

        {
          endObj?.status === 2
          && (
            <Steps current={current} labelPlacement="vertical" status="error" className="steps-cancel">
              {
                arr.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Step key={index} title={index === current ? '已取消' : item.title} description={data?.item?.[index]?.time} subTitle={index === current ? '用户取消' : item.subTitle} />
                ))
              }
            </Steps>
          )
        }

      </div>
    </Spin>
  );
}
