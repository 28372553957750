// 年度基础对象
export const baseAnnualInformation = {
  employeeCount: '',
  operatingIncome: '',
  totalProfit: '',
  totalLiabilities: '',
  totalAssets: '',
  fixedAssets: '',
  ownerEquity: '',
  year: '2021',
};

// 贷款信息基础对象
export const baseLoanDetailsVos = {
  // 贷款银行
  loanBank: '',
  // 贷款期间
  loanDateRangeStartTime: '',
  // 贷款金额
  loanAmount: '',
  // 贷款利率
  loanInterestRate: '',
  // 贷款种类
  loanType: '',
  // 资金用途
  capitalUse: '',
  // 贷款是否用于粮食生产及初加工
  loanForFoodProduction: false,
  // 贷款使用主要内容
  mainPurposeOfLoan: '',
  // 银行贷款汇总表及银行贷款合同
  bankLoanSummaryTableContract: '',
  // 支付银行利息汇总表及利息结算清单
  financialDocuments: '',
  // 银行贷款到帐凭证及支出明细账
  financialDocumentsFileName: '',
};

export const documentation = {
  // 法人执照副本及章程，国家乡村农业振兴带头人结业证书
  bylawsCertificate: [],
  // 上年度财务报表
  lastYearFinancials: [],
  // 证明与农民利益联结的相关资料
  farmersBenefitDocs: [],
  // 人民银行出具的完整的《企业信用报告》
  pboeCreditReport: [],
  // 承诺书
  commitmentLetter: [],
  // 人民银行签章的《信用报告异议信息专项证明》
  creditReportDisputeCertificate: [],
  // 其他必要的相关材料
  otherRequiredDocuments: [],
};
