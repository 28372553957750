import { Segmented, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import FixMenu from '@/components/FixMenu/FixMenu';
import bannerTitle from '@/assets/home/banner-title.png';
import { getBank, getInsurance } from '@/services/common';
import { getStatistical, getGuarantee } from '@/services/home';
import getMoney from '@/utils/getMoney';
import icon2 from '@/assets/home/icon2.png';
import icon3 from '@/assets/home/icon3.png';
import icon4 from '@/assets/home/icon4.png';
import icon5 from '@/assets/home/icon5.png';
import qrcode from '@/assets/qrcode.jpg';
import './index.less';

const list = [
  { title: '入驻金融机构', num: 'orgNum', icon: icon2 },
  { title: '金融产品', num: 'productNum', icon: icon3 },
  { title: '申请笔数', num: 'loanNum', icon: icon4 },
  { title: '融资金额', num: 'amountNum', icon: icon5 },
];

export default function Home() {
  const Navigate = useNavigate();
  const [key, setKey] = useState('银行');

  const { data: statistical = {}, run } = useRequest(
    getStatistical,
    {
      manual: true,
    },
  );
  const { data: bank = [], loading } = useRequest(getBank);
  const { data: insurance = [] } = useRequest(getInsurance);
  const { data: guarantee = {} } = useRequest(getGuarantee);

  useEffect(() => {
    const timer = setInterval(() => {
      const areaName = sessionStorage.getItem('areaName');
      if (areaName) {
        run(areaName);
        clearInterval(timer);
      }
    }, 1000);
  }, []);

  function handleChange(e) {
    setKey(e);
  }
  function handleTo(id) {
    if (key === '担保') {
      Navigate('/guarantee');
    } else {
      Navigate(`/home/mechanism?id=${id}&type=${key}`);
    }
  }

  // eslint-disable-next-line no-nested-ternary
  const data = key === '银行' ? bank : key === '保险' ? insurance : [guarantee];
  return (
    <div className="home">
      <div className="home__banner">
        <img src={bannerTitle} alt="" className="home__banner-title" />
        <div className="home__banner-code">
          <img src={qrcode} alt="" width={123} height={124} />
          <div>手机扫一扫，办理更便捷</div>
        </div>
      </div>
      <div className="home__statistics-box">
        <div className="home__statistics">
          {
              list.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="home__statistics-li" key={index}>
                  <img src={item.icon} alt="" />
                  <div className="home__statistics-li-num">
                    {index === 3 ? getMoney(statistical?.[item.num])?.num : statistical?.[item.num]}
                    {index === 3 && (
                      <span className="home__unit">
                      { getMoney(statistical?.[item.num])?.unit }
                      </span>
                    )}
                  </div>
                  <div className="home__statistics-li-title">{item.title}</div>
                </div>
              ))
          }
        </div>
      </div>
      <div className="home__Co">
        <div className="home__Co-conw">
          <div className="home__Co-title">合作机构</div>
          <Segmented block defaultValue={key} options={['银行', '保险', '担保']} onChange={handleChange} />
          <Spin spinning={loading}>
            <div className="home__Co-carousel-row">
              {
                  data.map((e) => (
                    <div key={e.orgId} className="home__Co-carousel-img-box">
                      <img src={e.url} alt="" key={e.orgId} onClick={() => handleTo(e.orgId)} className="home__Co-img" />
                    </div>
                  ))
              }
            </div>
          </Spin>
        </div>
      </div>
      <FixMenu />
    </div>
  );
}
