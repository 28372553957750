// 申请平台授权书
const PlatformAuthInfo = `<div style="text-align: left;color: #000 !important;">
<h1 style="text-align: center;margin: 20px 0">“信贷直通车”平台信息授权协议</h1>
<div style="text-indent:20px;">欢迎您使用“信贷直通车”平台！</div>
<div style="text-indent:20px;font-weight: bold;margin: 10px 0 20px 0">请您认真阅读并充分理解本协议全部内容。选中本协议链接前的勾选框或点击“同意”即视为您同意并接受本协议的约定。</div>
<div style="text-indent:20px;">名词解释：</div>
<div style="text-indent:20px;">本协议所称“平台”，概指“信贷直通车”平台和平台运营方（即四川征信有限公司）。</div>
<div style="text-indent:20px;">本协议所称“用户”，概指在本平台完成注册、认证并使用本平台服务的您及您所代表的机构主体（包括不限于农户、自然人、企业、工商个体户、事业单位、社会团体等）。</div>
<div style="text-indent:20px;">本协议所称“被授权方”，概指平台及相关合作机构。合作机构包括但不限于平台合作银行机构与保险机构等金融机构、平台合作企业信用服务机构与数据信息机构，以及为用户提供相关服务的其他机构。</div>
<h3 style="text-indent:20px;margin: 30px 0 10px 0">一、信息采集与使用</h3>
<p style="text-indent:20px;">1.用户同意并授权被授权方在为用户提供相关服务过程中，通过合法合规的渠道与方式，查询、归集、保存、整理、加工及使用用户相关信息。</p>
<p style="text-indent:20px;">2.被授权方获取与归集信息的渠道包括但不限于：<span style="font-weight: bold">中华人民共和和国农业农村部、四川省农业农村厅、四川省农机购置补贴机具作业综合奖补信息平台、四川省农产品质量安全追溯管理信息平台、相关政府机关部门与事业单位、合法合规提供数据服务的机构等</span>
（统称为“数据渠道”）。
</p>
<div style="text-indent:20px;">
    <p>3.被授权方进行查询、收集、保存、整理、加工及使用的用户信息种类主要包括但不限于：</p>
    <div>(1)中华人民共和国农业农村部信贷直通车单笔信息查询。</div>
    <div>(2)四川省农业农村厅土地确权数据、农民耕地地力保护补贴信息、草原生态保护补贴奖励信息。</div>
    <div>(3)四川省农机购置补贴机具作业综合奖补信息平台相关信息、四川省农产品质量安全追溯管理信息平台相关信息。</div>
    <div>(4)主体登记信息，年报信息，股东与关联信息等。</div>
    <div>(5)税务、社保、公积金、用水用电用气信息、水电气费缴纳等经营信息。</div>
    <div>(6)不动产、无形资产、抵质押等资产信息。</div>
    <div>(7)依法依规向社会公开的信息，如司法公示信息，行政管理公示信息、严重失信主体名单信息等。</div>
    <div>(8)用户向平台提供的身份信息、联系方式等信息，以及用户使用平台服务或访问平台网页时所产生的Cookie和浏览记录等。</div>
    <p>(9)为提供相关服务，合法合规获取的的其它信息。</p>
</div>
<div style="text-indent:20px;font-weight: bold">
<p style="font-weight: 400">4.被授权方使用用户信息的用途与场景包括但不限于：</p>
<div>(1)为用户进行资信评估、授信审核与风险监测分析；</div>
<div>(2)为用户推荐融资服务与产品，撮合融资业务；</div>
<div>(3)为用户进行信用管理服务与数据统计分析等；</div>
<div>(4)根据法律法规、监管部门所要求的用途与场景；</div>
<p>(5)其他经用户明确同意的用途与场景。</p>
</div>
<p style="text-indent:20px;">
5. <span style="font-weight: bold">针对平台与第三方合作提供的服务，</span>平台将要求第三方机构同意并遵守本协议约定，承担与平台同等责任。在此情况下，用户同意并授权平台将用户信息提供给该第三方使用。
</p>
<p style="text-indent:20px;">
6.用户在本协议项下的授权，对四川征信有限公司所运营的、与“信贷直通车”平台共享用户体系的“天府信用通”平台等其他关联平台同样有效。用户同意并接受本协议，视为对相关关联平台作出等同于对“信贷直通车”平台的授权。
</p>
<p style="text-indent:20px;font-weight: bold">
7.本协议授权有效期自用户阅读并确定接受本协议之日起。在用户与所有被授权方的全部相关业务终结的情况下，用户可随时通过主动注销本平台账户来撤销相关授权。自用户平台账户注销之日起，本协议授权自动解除。
</p>
<h3 style="text-indent:20px;margin: 30px 0 10px 0">二、信息保存与保护</h3>
<p style="text-indent:20px;">8.平台全部数据与信息均将保存在中国境内，平台会如实、妥善地保存用户相关信息</p>
<p style="text-indent:20px;">9.平台承诺不公开或透露用户的密码、手机号码等非公开信息，除非因用户本人的需要、法律或其他合法程序的要求、服务条款的改变或修订等。</p>
<p style="text-indent:20px;">10.平台在法律法规规定期限内保留用户信息。超出上述期限后，平台将对用户信息进行删除或匿名化处理。</p>
<p style="text-indent:20px;">11.平台已通过国家网络安全等级保护的测评和备案，并获得相应的认证，并采用符合业界标准的安全防护措施来保护用户信息。</p>
<p style="text-indent:20px;">12.平台为保障信息安全，尽可能降低信息被泄露、损毁、误操作等风险，将在必要时使用各类安全技术。包括但不限于对敏感或隐私信息采用脱敏、加密（例如MD5、SSL等）等技术手段，使用网络专线或加密通道进行数据传输，部署网络安全设备或软件等。</p>
<p style="text-indent:20px;">13.平台将按照法律法规的要求，在发生信息安全事件时，及时采取相关措施进行化解，同时将事件相关情况以邮件、信函、电话、推送通知等方式中的一种或多种告知用户。</p>
<h3 style="text-indent:20px;margin: 30px 0 10px 0">三、用户权利与义务</h3>

<div style="text-indent:20px;">
<p>14.在以下情形中，用户可通过028-67135691联系平台，向平台提出更正或删除相关信息的请求：</p>
<div>(1)用户发现平台中的用户信息缺失或有误；</div>
<div>(2)平台处理信息的行为违反相关法律法规；</div>
<div>(3)平台收集、使用的用户信息未征得用户同意；</div>
<p>(4)平台处理信息的行为违反本协议约定。</p>
</div>
<p style="text-indent:20px;">15.为保障信息安全，用户需要向平台提供书面请求，或以其他方式证明用户身份。若用户提出的请求涉及相关机构主体，需要提供加盖机构主体公章的书面请求。</p>
<div style="text-indent:20px;">
<div>16.按照相关法律法规要求，如涉及以下情形平台将可能无法响应用户的请求：</div>
<div>(1)与国家安全、国防安全相关的；</div>
<div>(2)与公共安全、公共卫生、重大公共利益相关的；</div>
<div>(3)与犯罪侦查、起诉、审判和判决执行等相关的；</div>
<div>(4)有充分证据表明用户存在主观恶意或滥用权利的；</div>
<div>(5)响应用户请求将导致用户或其他个人、组织的合法权益受到严重损害的；</div>
<div>(6)涉及平台商业秘密的。</div>
</div>

<h3 style="text-indent:20px;margin: 30px 0 10px 0">四、平台权利与义务</h3>
<p style="text-indent:20px;">
17.用户知晓并同意，平台可归集并保存用户在使用平台服务时所产生的相关填报信息、融资需求信息等；若用户后续在被授权金融机构处成功获贷，则平台可归集并保存用户的授信审批信息、放款审批信息、贷款状态、贷款违约信息等，用于融资服务相关工作成效统计分析，用户理解和接受回传贷款违约信息所带来的负面影响。
</p>
<p style="text-indent:20px;">18.平台将根据服务需要对本协议及相关规则做出相应修改，修改后的内容将
<span style="font-weight: bold;">通过平台网站或微信公众号等渠道</span>进行公布，公布后即时生效，并取代此前相关内容。<span style="font-weight: bold;">
如用户在协议更新生效后继续使用平台服务，即表示用户接受更新后的协议。</span></p>
<p style="text-indent:20px;">19.平台在向用户提供具体业务服务时，将视具体情况另行对该业务服务所必要进行的信息收集与使用进行说明，并在用户同意接受后进行相关操作。</p>

<h3 style="text-indent:20px;margin: 30px 0 10px 0">五、责任声明</h3>

<div style="text-indent:20px;">
<p>20.用户知晓并同意在以下情形发生时，平台与被授权方不对用户任何损失承担任何责任：</p>
<div>(1)由于计算机病毒、木马或其他恶意程序、黑客攻击、网络供应商故障等原因造成的服务中断或延迟；</div>
<div>(2)用户操作不当或通过违法违规方式使用平台服务；</div>
<div>(3)因地震、洪水等不可抗力因素造成平台不能提供服务的；</div>
<div>(4)用户在使用平台服务过程中由第三方服务引发的损失和责任由该第三方承担；</div>
<div>(5)其他本平台无法控制或合理预见的情形。</div>
</div>

<h3 style="text-indent:20px;margin: 30px 0 10px 0">六、联系平台</h3>

<p style="text-indent:20px;">21.用户对本协议有任何疑问、意见或建议，请联系平台客服热线：028-67135691。</p>

<div style="text-indent:20px;font-weight: bold;margin: 20px 0">
用户声明：被授权方已依法向用户提示了上述条款（特别是加粗部分），用户在此声明已知悉并理解全部内容，确认并同意本协议全部条款。
</div>

</div>`;

export default PlatformAuthInfo;
