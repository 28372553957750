export const APPLICANT_TYPE = 'applicantType'; // 申请人类型
export const GMT_STATUS = 'gmtStatus'; // 贷款期限
export const LOAN_AMOUNT = 'loanAmount'; // 贷款额度
export const STATUS = 'status'; // 申请进度
export const GMT_INFORMATION_STATUS = 'gmtInformationStatus'; // 申请时间
export const BASIC_TYPE = 'basicType'; // 主体类型
export const BUSINESS_TYPE = 'businessType'; // 主营业务类型
export const LOAN_TYPE = 'loanType'; // 资金用途
export const BUSINESS_STATUS = 'businessStatus'; // 业务类型
export const BASIC_LEVEL = 'basicLevel'; // 主体等级
export const USE_POSITION_TYPE = 'userPositionType'; // 经办人身份
export const AGRICULTURE_CATEGORY = 'agricultureCategory'; // 企业产业区分
export const ORIENTATIO_TYPE = 'orientatio_type'; // 贷款投向
export const BUSINESS_UNIT = 'businessUnit'; // 经营种类计量单位
export const PROJECT_TYPE = 'projectType'; // 重大项目-项目类型
export const RATING_CREDIT = 'ratingCredit'; // 重大项目-信用等级
export const ENTERPRISE_TYPE = 'enterpriseType'; // 重大项目-企业类型
export const CAPITAL_PROJECT_TYPE = 'SocialCapitalProjectType'; // 招商项目-项目类型
export const CAPITAL_AMOUNT_TYPE = 'SocialCapitalWebAmountStatus'; // 招商项目-项目金额
export const FACILITY_LOAN_TYPE = 'facilityLoanType'; // 贷款贴息-贷款类型
export const GUARANTEE_LEVEL = 'guaranteeLevel'; // 担保奖补-担保公司级别
export const COMPANY_FUND_TYPE = 'establishmentType'; // 担保奖补-公司设立类型
export const applicantType = { // 使用对象
  1: '个人',
  2: '企业',
  3: '个人/企业',
};

export const policyType = { // 政策类型
  0: { name: '中央政策', class: 'zczx__tag' },
  1: { name: '省级政策', class: 'zczx__tag-s' },
  2: { name: '市州政策', class: 'zczx__tag-z' },
  3: { name: '区县政策', class: 'zczx__tag-q' },
};
