import request from '@/utils/request';

// 设置超时为2分钟
const timeout = 120000;

// 贷款贴息列表查询
export function getFacilityLoanList(data) {
  return request({
    url: '/loan/subsidy/user/list',
    method: 'POST',
    data: { ...data, type: 1, status: 1 },
  });
}

// 贷款贴息公示列表查询
export function getFacilityLoanPublicList(data) {
  return request({
    url: '/loan/subsidy/release/web/list',
    method: 'POST',
    data: { ...data },
  });
}

// 贷款贴息详细查询
export function getFacilityLoanDetail(id) {
  return request({
    url: '/loan/subsidy/detail',
    method: 'GET',
    params: { id },
  });
}

// 贷款贴息附件下载
export function attchmentFileDownload(id) {
  return request({
    url: '/loan/subsidy/proof/download',
    method: 'GET',
    responseType: 'blob',
    timeout,
    params: { id },
  });
}
// 贷款贴息附件下载
export function attchmentFileDownload2(id) {
  return request({
    url: '/loan/subsidy/download',
    method: 'GET',
    responseType: 'blob',
    timeout,
    params: { id },
  });
}
// 贷款贴息附件下载
export function loanFileDownload(id, fileType) {
  return request({
    url: '/loan/subsidy/loan/file/download',
    method: 'GET',
    responseType: 'blob',
    timeout,
    params: { id, fileType },
  });
}
// 贷款贴息附件下载
export function loanFileDownloadWithURL(url, fileName) {
  return request({
    url,
    method: 'GET',
    responseType: 'blob',
    timeout,
    fileName,
  });
}

// 贷款贴息申请
export function addApply(data) {
  return request({
    url: '/loansubsidy/application/add',
    method: 'POST',
    data,
  });
}

// 贷款贴息重新申请
export function updateBackApply(data) {
  return request({
    url: '/loansubsidy/application/edit',
    method: 'POST',
    data,
  });
}

// 贷款贴息申请详情
export function getfacilityLoanApplicationDetail(id) {
  return request({
    url: `/loansubsidy/application/detail?id=${id}`,
    method: 'GET',
  });
}

// 贷款贴息申请详情
export function getApplicationOption() {
  return request({
    url: '/loansubsidy/application/option',
    method: 'GET',
  });
}
