import { any } from 'prop-types';
import '../../MajorProjectTimeline.less';
import { Collapse } from 'antd';
import { useRequest } from 'ahooks';
import { downloadFile } from '@/utils/utils';
import { attchmentFileDownload } from '@/services/industryBankLoan';
import AttachmentDownload from '@/components/AttachmentDownload/AttachmentDownload';

function Documentation({ documentation }) {
  const { loading: downloadLoading, run: downloadRun } = useRequest(attchmentFileDownload, {
    manual: true,
    onSuccess: ({ data: fileData, filename }) => {
      downloadFile(fileData, filename);
    },
  });
  return (
    <div style={{ marginTop: 20 }}>
      <Collapse defaultActiveKey={['1']}>
        <Collapse.Panel key={1} header="其他证明材料">
          <div className="manage-detail-box">
            <div className="manage-detail-li">
              <div className="manage-detail-li-title w-large">法人执照副本及章程，国家乡村农业振兴带头人结业证书：</div>
              <div className="manage-detail-li-content">
                {documentation?.bylawsCertificate?.map((file) => (
                  <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />))}
              </div>
            </div>
            <div className="manage-detail-li">
              <div className="manage-detail-li-title w-large">上年度财务报表：</div>
              {documentation?.lastYearFinancials?.map((file) => (
                <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
              ))}
            </div>
            <div className="manage-detail-li">
              <div className="manage-detail-li-title w-large">证明与农民利益联结的相关资料：</div>
              {documentation?.farmersBenefitDocs?.map((file) => (
                <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />))}
            </div>
            <div className="manage-detail-li">
              <div className="manage-detail-li-title w-large">人民银行出具的完整的《企业信用报告》：</div>
              {documentation?.pboeCreditReport?.map((file) => (
                <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />))}
            </div>
            <div className="manage-detail-li">
              <div className="manage-detail-li-title w-large">承诺书：</div>
              {documentation?.commitmentLetter?.map((file) => (
                <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />))}
            </div>
            <div className="manage-detail-li">
              <div className="manage-detail-li-title w-large">
                人民银行签章的《信用报告异议信息专项证明》
                <br />
                {' '}
                （对企业信用报告有异议的才提供，需当地人民银行所盖鲜章）：
              </div>
              <div className="manage-detail-li-content">
                {documentation?.creditReportDisputeCertificate?.map((file) => (
                  <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />))}
              </div>
            </div>
            <div className="manage-detail-li">
              <div className="manage-detail-li-title w-large">
                其他必要的相关材料
                <br />
                （如：证明用于固定资产投资的材料等）：
              </div>
              {documentation?.otherRequiredDocuments?.map((file) => (
                <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />))}
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default Documentation;

Documentation.propTypes = {
  documentation: any,
};
