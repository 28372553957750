import request from '@/utils/request';

const areaName = sessionStorage.getItem('areaName') || '510000';

// 获取政策信息
export function getPolicy(data) {
  return request({
    url: '/plicy/query/web',
    method: 'POST',
    data: { ...data, name: areaName },
  });
}

// 政策信息详情
export function getPolicyDetail(id) {
  return request({
    url: `/plicy/query/${id}`,
    method: 'GET',
  });
}
