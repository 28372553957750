import request from '@/utils/request';

/*
* 登录注册模块
* */

// 验证码校验
export function getSmsCode(phone) {
  return request({
    url: '/user/getSmsCode',
    method: 'GET',
    params: { phone },
  });
}

// 检查账号是否存在
export function getCheckUserExist(data) {
  return request({
    url: '/user/checkUserExist',
    method: 'POST',
    data,
  });
}

// 用户登录
export function login(data) {
  return request({
    url: '/user/login',
    method: 'POST',
    data,
  });
}

// 用户注册
export function register(data) {
  return request({
    url: '/user/register',
    method: 'POST',
    data,
  });
}

// 注册-根据主体是企业还是个人获取可选的主体类型
export function getRegisterBasicType(type) {
  return request({
    url: `/common/basicType/${type}`,
    method: 'GET',
  });
}
