import {
  useState, useEffect, Fragment, forwardRef, useImperativeHandle,
} from 'react';
import {
  Button,
  Form,
  Card,
  Col,
  InputNumber,
  Collapse,
  Popconfirm,
  message,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isEmpty, isNull } from 'lodash';
import { any, func } from 'prop-types';
import FormList from './FormList';
import '../index.less';
import { getFileListVal } from '../Application/fileDataUtil';
import { moneyDelete } from '@/utils/utils';

const bondBtn = {
  padding: 0, marginLeft: 10,
};

const dateFormat = 'YYYY-MM-DD';
let claim = 1; // 默认1
const temp = [{
  key: 1, disable: false, id: undefined, isNew: true,
}];

const Application = forwardRef(({
  initialData, loanTypeDict, formRef, amountChange,
}, ref) => {
  const [form] = Form.useForm();
  // const backId = null;

  const [claimFormList, setClaimFormList] = useState(temp);
  const [data, setData] = useState({}); // 理赔信息
  const [activeKey, setActiveKey] = useState([1]); // 展开的key
  const [editKey, setEditKey] = useState(1); // 编辑的key

  useImperativeHandle(ref, () => ({ // 暴露给父组件的方法
    claimFormList,
    editKey,
  }));

  const onCollapseChange = (key) => {
    setActiveKey(key);
  };

  const listLoanAmountChanged = () => {
    const values = form.getFieldsValue();

    const arr = claimFormList;
    let sum = 0;
    claimFormList.forEach((e, index) => {
      const amount = values[`loanAmount_${arr[index].key}`];
      if (!isNull(amount)) sum += amount;
    });
    form.setFieldsValue({ loanAmount: sum });
    amountChange(sum);
  };

  useEffect(() => {
    listLoanAmountChanged();
  }, [claimFormList]);

  // 数据回显处理
  const getData = (res) => {
    let claimArr = [...temp];
    const obj = {};
    if (!isEmpty(res)) {
      const allKeys = [];
      claimArr = [];
      claim = 0;
      res.forEach((item) => {
        claim += 1;
        allKeys.push(claim);
        obj[`lendingBank_${claim}`] = item.lendingBank;
        obj[`lendingStart_${claim}`] = [moment(item.lendingStart), moment(item.lendingEnd)];
        obj[`loanAmount_${claim}`] = moneyDelete(item.loanAmount);
        obj[`loanRate_${claim}`] = item.loanRate;
        obj[`loanType_${claim}`] = String(item.loanType);
        obj[`facilityLoanFileVos_${claim}`] = item.facilityLoanFileVos;
        obj[`loanContractFileVos_${claim}`] = item.loanContractFileVos;
        obj[`fundsWithdrawalFileVos_${claim}`] = item.fundsWithdrawalFileVos;
        obj[`loanFundsFileVos_${claim}`] = item.loanFundsFileVos;
        obj[`bankInterestFileVos_${claim}`] = item.bankInterestFileVos;
        obj[`otherFileVos_${claim}`] = item.otherFileVos;
        claimArr.push({
          key: claim, disable: true, id: item?.id, oneData: item,
        });
      });
      setEditKey(undefined); // 编辑key
      setActiveKey(allKeys); // 重新申请时，默认展开所有key，方便计算总贷款额
    }

    setClaimFormList(claimArr);
    form.resetFields();
    form.setFieldsValue(obj);
    setData(obj);
  };

  // 编辑时，此处处理已存在的数据
  useEffect(() => {
    getData(initialData);
  }, [initialData]);

  const getFormListData = (values, index, claimId) => {
    const specialBondInformationList = [];
    const arr = claimFormList;
    claim += 1;
    const lendingBank = values[`lendingBank_${arr[index].key}`];
    const loanAmount = values[`loanAmount_${arr[index].key}`];
    const loanType = values[`loanType_${arr[index].key}`];
    const loanRate = values[`loanRate_${arr[index].key}`];

    const [start, end] = values[`lendingStart_${arr[index].key}`];
    const lendingStart = start && moment(start).format(dateFormat);
    const lendingEnd = end && moment(end).format(dateFormat);

    let facilityLoanFileVos = values[`facilityLoanFileVos_${arr[index].key}`];
    let loanContractFileVos = values[`loanContractFileVos_${arr[index].key}`];
    let fundsWithdrawalFileVos = values[`fundsWithdrawalFileVos_${arr[index].key}`];
    let loanFundsFileVos = values[`loanFundsFileVos_${arr[index].key}`];
    let bankInterestFileVos = values[`bankInterestFileVos_${arr[index].key}`];
    let otherFileVos = values[`otherFileVos_${arr[index].key}`];

    if (!isEmpty(facilityLoanFileVos)) {
      facilityLoanFileVos = getFileListVal(facilityLoanFileVos);
    }
    if (!isEmpty(loanContractFileVos)) {
      loanContractFileVos = getFileListVal(loanContractFileVos);
    }
    if (!isEmpty(fundsWithdrawalFileVos)) {
      fundsWithdrawalFileVos = getFileListVal(fundsWithdrawalFileVos);
    }
    if (!isEmpty(loanFundsFileVos)) {
      loanFundsFileVos = getFileListVal(loanFundsFileVos);
    }
    if (!isEmpty(bankInterestFileVos)) {
      bankInterestFileVos = getFileListVal(bankInterestFileVos);
    }
    if (!isEmpty(otherFileVos)) {
      otherFileVos = getFileListVal(otherFileVos);
    }

    specialBondInformationList.push({
      lendingBank,
      loanAmount,
      lendingStart,
      lendingEnd,
      loanType,
      loanRate,
      facilityLoanFileVos,
      loanContractFileVos,
      fundsWithdrawalFileVos,
      loanFundsFileVos,
      bankInterestFileVos,
      otherFileVos,
      id: claimId,
    });
    return specialBondInformationList;
  };

  // 添加
  const add = () => {
    if (editKey) {
      message.error('同时只能编辑一个金额信息');
    } else {
      const claimArr = [...claimFormList];
      const newArr = [...activeKey];
      claim += 1;
      claimArr.push({
        key: claim, id: undefined, isNew: true, numLimit: 0,
      });
      newArr.push(claim);
      setEditKey(claim);
      setClaimFormList(claimArr);
      setActiveKey(newArr);
    }
  };

  // 删除
  const handleDelete = (event, itemKey, claimId) => {
    event.stopPropagation();

    const arr = [...claimFormList].filter((e) => e.key !== itemKey);

    if (claimId && arr.length === 0) {
      // 重新申请时，无法删除最后一个信息
      message.error('必须包含一个贷款信息');
      return;
    }

    const allFieldValues = form.getFieldsValue();
    const allKeys = Object.keys(allFieldValues);

    allKeys.forEach((key) => {
      // 删除后，需要将对应field的值设置为null
      if (key.endsWith(`_${itemKey}`)) {
        allFieldValues[key] = null;
      }
    });
    form.setFieldsValue(allFieldValues);

    // 使用claim作为唯一值后，必须保持claim递增
    // claim -= 1;
    setEditKey(undefined);
    setClaimFormList(arr);
  };

  // 更新已投资金额信息
  const onUpdate = (event, key, disabled) => {
    event.stopPropagation();
    if (editKey) {
      message.error('同时只能编辑一个金额信息');
    } else {
      const arr = [...claimFormList];
      arr[key].disable = disabled;
      setEditKey(key + 1);
      setClaimFormList(arr);
      // form.setFieldsValue(data);
    }
  };

  // 取消更新
  const onCanalUpdate = (event, key, disabled) => {
    event.stopPropagation();
    const arr = [...claimFormList];
    arr[key].disable = disabled;
    setEditKey(undefined);
    setClaimFormList(arr);
    form.setFieldsValue(data);
  };

  // 保存已投资金额信息
  const onUpdateClaimForm = (event, key, claimId) => {
    event.stopPropagation();
    form.validateFields().then((values) => {
      const arr = getFormListData(values, key, claimId);
      const val = {
        ...arr?.[0],
      };
      if (claimId) {
        // 更新
        const newArr = [...claimFormList];
        newArr[key].disable = true;
        newArr[key].oneData = val;
        setEditKey(undefined);
        setClaimFormList(newArr);
        setData(values);
      } else {
        // 添加
        const newArr = [...claimFormList];
        newArr[key].disable = true;
        setEditKey(undefined);
        setClaimFormList(newArr);
      }
    });
  };

  return (
    <Form ref={formRef} name="reject" labelCol={{ span: 6 }} form={form} wrapperCol={{ span: 18 }} autoComplete="off">
      <Card
        title="贷款信息"
        className="form-info__box"
        extra={
          (
            <Button type="primary" ghost onClick={() => add()}>
              <PlusOutlined />
              增加贷款信息
            </Button>
          )
        }
      >
        <Col span={12}>
          <Form.Item
            name="loanAmount"
            label="总贷款金额"
          >
            <InputNumber
              placeholder=""
              addonAfter="万元"
              disabled
              min={0}
              max={99999999}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Collapse activeKey={activeKey} onChange={onCollapseChange}>
          {claimFormList.map((item, index) => (
            <Collapse.Panel
              header={`贷款信息${index + 1}`}
              key={item.key}
              extra={[
                item?.disable ? (
                  <Button type="link" style={bondBtn} key={1} onClick={(e) => onUpdate(e, index, false)}>
                    更正
                  </Button>
                ) : (
                  <Fragment key={2}>
                    <Button type="link" style={bondBtn} onClick={(e) => onUpdateClaimForm(e, index, item?.id)}>
                      保存
                    </Button>
                    {!item.isNew && (
                      <Button type="link" style={bondBtn} onClick={(e) => onCanalUpdate(e, index, true)}>
                        取消
                      </Button>
                    )}
                    {!(item.isNew && index === 0) && (
                      <span
                        style={{ marginLeft: 10 }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Popconfirm
                          title="确认删除当前数据吗?"
                          onConfirm={(e) => handleDelete(e, item?.key, item?.id)}
                          okText="确认"
                          cancelText="取消"
                        >
                          <Button type="link" style={bondBtn} danger key={2}>
                            删除
                          </Button>
                        </Popconfirm>
                      </span>
                    )}
                  </Fragment>
                ),
              ]}
            >
              <FormList
                initialData={item}
                key={item.key}
                formKey={item.key}
                disable={item?.disable}
                loanType={loanTypeDict}
                amountChanged={listLoanAmountChanged}
              />
            </Collapse.Panel>
          ))}
        </Collapse>
      </Card>
    </Form>
  );
});

export default Application;

Application.propTypes = {
  initialData: any,
  loanTypeDict: any,
  formRef: any,
  amountChange: func,
};

Application.defaultProps = {
  initialData: {},
  loanTypeDict: {},
};
