import { Button } from 'antd';
import { bool, func } from 'prop-types';
import './CustomFooter.less';

export default function CustomFooter({ confirmLoading, onCancel, onOk }) {
  return (
    <div className="custom-footer">
      <Button type="primary" ghost onClick={onCancel}>
        取消
      </Button>
      <Button type="primary" onClick={onOk} loading={confirmLoading}>
        确定
      </Button>
    </div>
  );
}
CustomFooter.propTypes = {
  confirmLoading: bool,
  onCancel: func,
  onOk: func,
};
