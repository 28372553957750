import {
  useState, useEffect,
} from 'react';
import {
  Button,
  Checkbox,
  Form,
  Modal,
  Spin,
  message,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import qs from 'query-string';
import moment from 'moment/moment';
import { clone, forIn } from 'lodash';
import { useSelector } from 'react-redux';
import { getArea } from '@/services/common';

import {
  addApply, getfacilityLoanApplicationDetail, updateBackApply, getApplicationOption,
} from '@/services/industryBankLoan';
import './index.less';
import ApplicationLoanCard from '../components/ApplicationLoanCard';
// import { moneyDelete } from '@/utils/utils';
import { baseAnnualInformation, baseLoanDetailsVos, documentation } from '@/pages/industryBankLoan/Application/model';
import SubjectBasicInfo from '@/pages/industryBankLoan/Application/components/SubjectBasicInfo';
import ProjectBasicInfo from '@/pages/industryBankLoan/Application/components/ProjectBasicInfo';
import useKeepAlive from '@/hooks/useKeepAlive';

const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 24 },
  },
};
// const dataFormat = 'YYYY-MM-DD';

export default function Application() {
  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const { search, state: passedState } = useLocation();
  const { backId } = qs.parse(search);
  const { userType } = useSelector((state) => state);
  const user = useSelector((state) => state.userInfo);
  const [loanAmountInit, setLoanAmountInit] = useState(0);

  const [initialValues] = useState({
    annualInformation: [
      Object.assign(clone(baseAnnualInformation), { year: '2021' }),
      Object.assign(clone(baseAnnualInformation), { year: '2022' }),
      Object.assign(clone(baseAnnualInformation), { year: '2023' }),
    ],
    loanDetailsVos: [
      { ...baseLoanDetailsVos },
    ],
    documentation,
    // 个人用户
    subjectBasicInfo: {
      unitName: userType === 2 ? user.realName : user.displayName,
      identityCode: userType === 2 ? user.idcardNo : user.displayCode,
    },
    projectBasicInfo: {
      declarationYear: passedState?.year,
    },
  });

  const [authInfoModal, setAuthInfoModal] = useState(false); // 授权书内容

  const { data: dict = {} } = useRequest(getApplicationOption);
  const { data: area = [] } = useRequest(getArea); // 区域

  const { loading: applyLoading, run: backRun } = useRequest(getfacilityLoanApplicationDetail, {
    manual: true,
    onSuccess(res) {
      if (res) {
        // 贷款信息
        const loanDetailsVos = [...res.loanDetailsVos];
        const documentations = { ...res.documentation };
        // 贷款信息
        loanDetailsVos.forEach((item, index) => {
          loanDetailsVos[index].loanDateRange = [
            moment(item.loanDateRangeStartTime, 'YYYY-MM-DD'),
            moment(item.loanDateRangeEndTime, 'YYYY-MM-DD'),
          ];
          loanDetailsVos[index].loanType = +item.loanType;
          loanDetailsVos[index].capitalUse = +item.capitalUse;

          // 银行贷款汇总表及银行贷款合同
          loanDetailsVos[index].bankLoanSummaryTableContract_i = [
            {
              uid: '1',
              name: item.tableContractFileName,
              status: 'done',
              url: item.bankLoanSummaryTableContract,
            },
          ];
          // 支付银行利息汇总表及利息结算清单
          loanDetailsVos[index].financialDocuments_i = [
            {
              uid: '1',
              name: item.financialDocumentsFileName,
              status: 'done',
              url: item.financialDocuments,
            },
          ];
          // 银行贷款到账凭证及支出明细账
          loanDetailsVos[index].loanReceiptAndExpenseDetail_i = [
            {
              uid: '1',
              name: item.expenseDetailFileName,
              status: 'done',
              url: item.loanReceiptAndExpenseDetail,
            },
          ];
        });

        // 贷款总金额
        const count = loanDetailsVos.reduce((acc, cur) => acc + Number(cur.loanAmount ?? 0), 0);
        setLoanAmountInit(count);
        const docInfo = {};
        forIn(documentations, (docs, key) => {
          const docTemp = []; // 其他证明材料
          const docTempI = []; // 用于form表单显示
          docs?.forEach((value) => {
            if (value) {
              docTemp.push({
                uid: value.id,
                name: value.fileName,
                status: 'done',
                url: value.fileURL,
              });
              docTempI.push({
                fileName: value.fileName,
                fileURL: value.fileURL,
              });
            }
          });
          docInfo[`${key}`] = docTempI;
          docInfo[`${key}_i`] = docTemp;
        });
        form.setFieldsValue({
          // 申报主体基本信息
          subjectBasicInfo: {
            ...res.subjectBasicInfo,
            registrationTime: res.subjectBasicInfo.registrationTime && moment(res.subjectBasicInfo.registrationTime),
            registrationLocation_i: res.subjectBasicInfo.registrationLocation.split(','),
            cityOrState: +res.subjectBasicInfo.cityOrState,
          },
          // 年度
          annualInformation: [...res.annualInformation],
          // 项目基本信息
          projectBasicInfo: {
            ...res.projectBasicInfo,
            projectRegion_i: res.projectBasicInfo.projectRegion.split(','),
          },
          // 贷款信息
          loanDetailsVos,
          documentation: { ...docInfo },
        });
      }
    },
  });

  const { loading, run: add } = useRequest(addApply, {
    manual: true,
    onSuccess() {
      Navigate('/result');
    },
  });

  const { loading: updateLoading, run: updateRun } = useRequest(updateBackApply, {
    manual: true,
    onSuccess() {
      Navigate('/result');
    },
  });

  // 保证token不过期
  useKeepAlive();

  useEffect(() => {
    if (backId) {
      backRun(backId);
    }

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      return '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  function onFinish() {
    form.validateFields().then((values) => {
      const { loanDetailsVos } = values;
      const count = loanDetailsVos.reduce((acc, cur) => acc + Number(cur.loanAmount), 0);
      const { loanAmount } = { ...values.projectBasicInfo };

      if (count > loanAmount) {
        message.error('贷款金额不能大于项目贷款金额');
        return;
      }

      if (!backId) {
        add(values);
      } else {
        updateRun({
          id: backId,
          ...values,
        });
      }
    }).catch(({ values }) => {
      setTimeout(() => {
        const errorList = document.querySelectorAll('.ant-form-item-has-error');
        errorList[0]?.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }, 500);

      console.log('error', values);
    });
  }

  function checkAuthorize(_, value) {
    if (!value) {
      return Promise.reject(new Error('勾选同意并接受'));
    }
    return Promise.resolve();
  }

  return (
    <div className="major-financing-application">
      <div className="content-width">
        <div className="margin-top20">
          <Spin spinning={applyLoading}>
            <Form
              {...formItemLayout}
              form={form}
              name="form"
              className="form"
              onFinish={onFinish}
              initialValues={initialValues}
            >
              <SubjectBasicInfo
                formRef={form}
                initialValues={initialValues}
                dict={dict}
                area={area}
              />
              <ProjectBasicInfo
                formRef={form}
                area={area}
              />
              <ApplicationLoanCard
                formRef={form}
                loanTypeDict={dict}
                loanAmountInit={loanAmountInit}
              />
              <div className="authorize">
                <Form.Item
                  required
                  name="authorize"
                  wrapperCol={{ span: 24 }}
                  valuePropName="checked"
                  rules={[
                    { validator: checkAuthorize },
                  ]}
                >
                  <Checkbox
                    onChange={(e) => {
                      form.setFieldsValue({ authorize: false });
                      if (e.target.checked) {
                        setAuthInfoModal(true);
                      }
                    }}
                  >
                    同意并接受
                    <Button type="link" className="authorize-name" onClick={() => setAuthInfoModal(true)}>《承诺函》</Button>
                  </Checkbox>
                </Form.Item>
              </div>
              <div className="submit-btn-box">
                <Button type="primary" onClick={onFinish} loading={loading || updateLoading}>
                  提交申请
                </Button>
              </div>
            </Form>
          </Spin>
          <Modal
            title="承诺函"
            open={authInfoModal}
            footer={
              [
                <Button key="skip" onClick={() => setAuthInfoModal(false)}>
                  取消
                </Button>,
                <Button
                  type="primary"
                  key="confirm"
                  onClick={() => {
                    setAuthInfoModal(false);
                    form.setFieldsValue({ authorize: true });
                  }}
                >
                  确认
                </Button>,
              ]
            }
            onCancel={() => setAuthInfoModal(false)}
          >
            <div>
              本人（企业、合作社） 承诺在申报2024年农业产业化银行贷款贴息时提交的所有材料真实、合法、有效，若发现材料存在虚假、瞒报等情况，
              本人（企业、合作社）自愿放弃本次申报，并退还获得的财政补贴资金，并承担违规违法责任。
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}
