import request from '@/utils/request';

// 保险产品详情
export function getInsuranceDetail(id) {
  return request({
    url: `/insurance/info/query/insurance/${id}`,
    method: 'GET',
  });
}

// 获取保險可选的城市列表
export function getInsurCityList(insurProductId) {
  return request({
    url: '/common/insurcitylist',
    method: 'GET',
    params: { insurProductId },
  });
}

// 根据产品ID和区域获取可选择的保险公司
export function getSelectAbleInsurance(params) {
  return request({
    url: '/common/selectAbleInsurance',
    method: 'GET',
    params,
  });
}
