import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import moment from 'moment';
import { Provider } from 'react-redux';
import store from '@/redux/store';
import App from './App';
import 'moment/locale/zh-cn';
import AuthRouter from './pages/routers/authRouter';

moment.locale('zh-cn');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <ConfigProvider locale={zhCN}>
        <HashRouter>
          <AuthRouter>
            <App />
          </AuthRouter>
        </HashRouter>
      </ConfigProvider>
    </Provider>,
);
