import { Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import FixMenu from '@/components/FixMenu/FixMenu';
import { getGuaranteeDetail } from '@/services/guarantee';
import './index.less';

export default function Index() {
  const Navigate = useNavigate();

  const { data, loading } = useRequest(getGuaranteeDetail);

  function handleChange() {
    Navigate('/guarantee/application');
  }

  return (
    <div className="dbzd">
      <div className="dbzd__banner">
        担保助贷
      </div>
      <Spin spinning={loading}>
        <div className="dbzd__info content-width">
          <div className="dbzd__info-title">{data?.orgName}</div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: data?.content }} />
            地址：
            {data?.address}
            <br />
            联系方式：
            {data?.tel}
            <br />
            官网：
            <a href={data?.dirUrl} target="_blank" rel="noreferrer">{data?.dirUrl}</a>
          </div>
          <div className="dbzd__btn">
            <Button type="primary" onClick={() => handleChange()}>担保贷款申请</Button>
          </div>
        </div>
      </Spin>
      <FixMenu />
    </div>
  );
}
