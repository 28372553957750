import { useState, useEffect } from 'react';
import {
  Button, Tabs, Spin, message, Modal, Form, Select, Checkbox, Input,
} from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import qs from 'query-string';
import { isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import InsuranceCard from '@/components/ProductFilter/InsuranceCard';
import { getInsuranceDetail, getInsurCityList, getSelectAbleInsurance } from '@/services/insurance';
import { addInsurance } from '@/services/common';
import { getToken } from '@/utils/token';
import AuthorizationInfo from '@/components/FormInfo/AuthorizationInfo';
import './index.less';

export default function Detail() {
  const Navigate = useNavigate();
  const { search } = useLocation();
  const [form] = Form.useForm();
  const { status } = useSelector((state) => state);
  const { id, type, areaCode } = qs.parse(search);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [authInfoModal, setAuthInfoModal] = useState(false); // 授权书内容
  const [check, setCheck] = useState(false); // 是否勾选授权协议

  // 获取可选城市
  const { data: cityList, run: cityRun } = useRequest(() => getInsurCityList(id), {
    manual: true,
  });

  const { data: orgData, run: orgRun } = useRequest(getSelectAbleInsurance, {
    manual: true,
    onSuccess(res) {
      if (!isEqual(res, orgData)) {
        form.setFieldsValue({ orgId: undefined });
      }
    },
  });

  useEffect(() => {
    if (type === 'bx') {
      cityRun();
    } else {
      orgRun({ name: areaCode, productId: id });
    }
  }, [type]);

  const { data, loading } = useRequest(() => getInsuranceDetail(id));
  const { run } = useRequest(addInsurance, {
    manual: true,
    onSuccess() {
      setIsModalOpen(false);
      message.success('购买成功');
      Navigate('/insurance');
    },
  });

  function handleChange() {
    if (check) {
      if (!getToken()) {
        Navigate('/login');
      } else if (status) {
        Navigate('/authentication');
      } else {
        setIsModalOpen(true);
      }
    }
  }

  function handleOk() {
    form.validateFields().then((values) => {
      const obj = {
        applicationRequestVo: {
          orgId: values.orgId,
          insureNum: values.insureNum,
        },
        blankListProducts: {
          productId: data?.productId,
          productName: data?.productName,
          publishOrgId: data?.publishOrgId,
          publishOrgName: data?.publishOrgName,
        },
      };
      run(obj);
    });
  }

  function onChange(e) {
    orgRun({ name: e, productId: id });
  }

  function handleCancel() {
    setIsModalOpen(false);
    form.resetFields();
  }

  const items = [
    { label: '保险信息', key: '1', children: <div dangerouslySetInnerHTML={{ __html: data?.insuranceInfo }} /> },
    { label: '投保详情', key: '2', children: <div dangerouslySetInnerHTML={{ __html: data?.insuranceDetail }} /> },
    { label: '理赔流程', key: '3', children: <div dangerouslySetInnerHTML={{ __html: data?.claimsProcess }} /> },
  ];

  return (
    <div className="insurance-info">
      <Spin spinning={loading}>
        <div className="content-width padding-top40">
          <InsuranceCard data={data} isShow />
          <div className="insurance-info__title">保险产品详情</div>
          <Tabs items={items} />
        </div>
      </Spin>
      <div className="insurance-info__btn">
        <div style={{ marginBottom: 20 }}>
          <Checkbox onChange={(e) => setCheck(e.target.checked)}>
            同意并接受
            <Button type="link" className="authorize-name" onClick={() => setAuthInfoModal(true)}>《平台信息授权协议》</Button>
          </Checkbox>
          {!check && <div style={{ color: 'red' }}>请勾选授权协议</div>}
        </div>
        <Button type="primary" onClick={() => handleChange()}>立即购买</Button>
      </div>
      <Modal title="消息确认" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} destroyOnClose>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 6,
          }}
          wrapperCol={{
            span: 18,
          }}
          autoComplete="off"
        >
          {
            type === 'bx' && (
              <Form.Item
                label="意向区域"
                name="area"
                rules={[
                  {
                    required: true,
                    message: '请选择意向区域',
                  },
                ]}
              >
                <Select placeholder="请选择意向区域" onChange={onChange}>
                  {
                    cityList?.map((item) => (
                      <Select.Option value={item.val} key={item.val}>{item.name}</Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            )
          }
          <Form.Item
            name="orgId"
            label="意向保险公司"
            rules={[
              {
                required: true,
                message: '请选择意向保险公司',
              },
            ]}
          >
            <Select placeholder="请选择意向保险公司">
              {
                orgData?.map((item) => (
                  <Select.Option value={item.key} key={item.key}>{item.title}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            name="insureNum"
            label="投保数量"
            rules={[
              {
                required: true,
                message: '请输入投保数量',
              },
              {
                max: 10,
              },
            ]}
          >
            <Input placeholder="请输入投保数量" />
          </Form.Item>
        </Form>
      </Modal>
      <Modal title="授权协议查看" open={authInfoModal} width={900} footer={null} onCancel={() => setAuthInfoModal(false)}>
        <div dangerouslySetInnerHTML={{ __html: AuthorizationInfo }} style={{ maxHeight: 600, overflowY: 'scroll' }} />
      </Modal>
    </div>
  );
}
