import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Personal from './components/personal';
import Enterprise from './components/enterprise';

export default function Index() {
  const Navigate = useNavigate();
  const status = useSelector((state) => state.status);

  useEffect(() => {
    if (!status) {
      Navigate('/home');
    }
  }, []);

  return (
    <div>
      {
        status === 'needPersonMaterial' && <Personal />
      }
      {
        status === 'needCompanyMaterial' && <Enterprise />
      }
    </div>
  );
}
