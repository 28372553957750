import { USER_BASIC_INFO } from './types';
import { getUserBasicInfo } from '@/services/user';

export const getActionBusinessInfo = () => async (dispatch) => {
  const res = await getUserBasicInfo();
  dispatch({
    type: USER_BASIC_INFO,
    businessInfo: res ?? {},
  });
};
