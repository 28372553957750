import { Typography, Tabs, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import FormInfo from '@/components/FormInfo/FormInfo';
import ProductFilter from '@/components/ProductFilter/ProductFilter';
import { getBankDetail, getInsuranceDetail } from '@/services/home';
import './index.less';
import PolicyInsurance from '@/pages/insurance/components/PolicyInsurance';

export default function Index() {
  const { search } = useLocation();
  const { id, type } = qs.parse(search);
  const [isBank, setIsBank] = useState(true);
  const { data, run, loading } = useRequest(getInsuranceDetail, { manual: true });
  const { data: bankData, run: bankRun, loading: bankLoading } = useRequest(
    getBankDetail,
    { manual: true },
  );

  function getData(flag) {
    if (flag) {
      bankRun(id);
    } else {
      run(id);
    }
  }

  useEffect(() => {
    let flag = true;
    if (type === '保险') {
      setIsBank(false);
      flag = false;
    }
    getData(flag);
  }, []);

  const bank = [
    { label: '融资产品', key: '1', children: <ProductFilter filter={false} /> },
    {
      label: '指定银行融资',
      key: '2',
      children:
  <div>
    <div className="yhzd__gbrz-text">
      指定银行融资是针对具有资金需求的企业或者个人，直接录入您的融资需求，一键直达申请银行。无需选择具体的融资产品，银行专员快速响应，满足您多元化的融资需求。
      如果您有需要，请直接填写如下信息，进行申请。
    </div>
    <FormInfo type="gbrz" />
  </div>,
    },
  ];

  const items = [
    { label: '政策性保险', key: '2', children: <PolicyInsurance insuranceId={id} /> },
    { label: '其他涉农保险', key: '3', children: <ProductFilter type="bx" filter={false} /> },
  ];

  return (
    <div className="mechanism">
      <div className="content-width">
        <Spin spinning={loading || bankLoading}>
          <div className="mechanism__bg">
            <div className="mechanism__info">
              <div className="mechanism__info-logo">
                <img src={data?.url || bankData?.url} alt="" width={250} height={75} />
              </div>
              <div className="mechanism__info-con">
                <Typography.Paragraph
                  ellipsis={{
                    rows: 4,
                    expandable: true,
                    symbol: '展开全部',
                  }}
                >
                  {data?.content || bankData?.content}
                </Typography.Paragraph>
              </div>
            </div>
          </div>
        </Spin>
        <Tabs items={isBank ? bank : items} className={isBank ? 'bank-tab' : 'insurance-tab'} />
      </div>
    </div>
  );
}
