import { useState, useEffect } from 'react';
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
  message,
  Tooltip,
  Radio,
} from 'antd';
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import qs from 'query-string';
import moment from 'moment/moment';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import AuthorizationInfo from '@/components/FormInfo/AuthorizationInfo';
import { getArea, getDictType } from '@/services/common';
import {
  uploadFileUrl, addApply, getApplicationInfo, updateBackApply, getMajorProjectTypeSel,
} from '@/services/majorFinancing';
import './index.less';
import { PROJECT_TYPE, RATING_CREDIT, ENTERPRISE_TYPE } from '@/utils/mappingDict';

const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
};

const defaultParams = [PROJECT_TYPE, RATING_CREDIT, ENTERPRISE_TYPE].join(',');

export default function Application() {
  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const { search } = useLocation();
  const { backId } = qs.parse(search);
  const { userType } = useSelector((state) => state);
  const [authInfoModal, setAuthInfoModal] = useState(false); // 授权书内容
  const [list, setList] = useState([]); // 附件内容
  const [num, setNum] = useState(0); // 财政资金

  const { data: area = [] } = useRequest(getArea); // 区域
  const { data: dict = {} } = useRequest(getDictType, { // 筛选项字典
    defaultParams: [defaultParams],
  });

  // 项目类型
  const { data: projectTypeSel = [] } = useRequest(getMajorProjectTypeSel);

  const { loading: applyLoading, run: backRun } = useRequest(getApplicationInfo, {
    manual: true,
    onSuccess(res) {
      if (res) {
        const fileList = [];
        if (!isEmpty(res?.projectFiles)) {
          // eslint-disable-next-line array-callback-return
          res.projectFiles.map((item, index) => {
            fileList.push({
              uid: index.toString(),
              name: item.fileName,
              status: 'done',
              url: item.fileUrl,
            });
          });
        }
        setList(fileList);
        setNum(Number(res?.financialFunds));
        const obj = {
          ...res,
          projectFiles: fileList,
          areaId: [res.provinceId, res.cityId, res.areaId],
          startTime: res.startTime && moment(res.startTime, 'YYYY-MM-DD'),
          endTime: res.endTime && moment(res.endTime, 'YYYY-MM-DD'),
        };
        form.setFieldsValue(obj);
      }
    },
  });

  const { loading, run } = useRequest(addApply, {
    manual: true,
    onSuccess() {
      Navigate('/result');
    },
  });

  const { loading: updateLoading, run: updateRun } = useRequest(updateBackApply, {
    manual: true,
    onSuccess() {
      Navigate('/result');
    },
  });

  const props = {
    name: 'file',
    accept: '.rar,.zip,.doc,.docx,.pdf',
    action: uploadFileUrl,
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        message.error('请上传10M以下文件!');
      }
      return isLt2M;
    },
    onChange({ file, fileList }) {
      if (isEmpty(fileList)) {
        form.setFieldsValue({ projectFiles: undefined });
      }
      setList(fileList);
      if (file.status === 'done') {
        message.success('上传成功');
      } else if (file.status === 'error') {
        message.error('上传失败');
      }
    },
  };

  useEffect(() => {
    if (userType !== 3) {
      Navigate('/home');
    }
    if (backId) {
      backRun(backId);
    }
  }, []);

  function onFinish(values) {
    if (values?.financialFunds > 0 && !isEmpty(values?.projectFiles)) {
      // eslint-disable-next-line no-param-reassign
      values.projectFiles = values.projectFiles.fileList.map((item) => ({
        fileName: item.name,
        fileUrl: item.response.data,
      }));
    } else {
      // eslint-disable-next-line no-param-reassign
      values.projectFiles = undefined;
    }
    const obj = {
      ...values,
      provinceId: values.areaId[0],
      cityId: values.areaId[1],
      areaId: values.areaId[2],
      startTime: values.startTime && moment(values.startTime).format('YYYY-MM-DD'),
      endTime: values.endTime && moment(values.endTime).format('YYYY-MM-DD'),
      id: backId,
    };
    if (backId) {
      // 退回记录从新申请
      updateRun(obj);
    } else {
      // 新申请
      run(obj);
    }
  }

  function checkAuthorize(_, value) {
    const promise = Promise;
    if (!value) {
      return promise.reject('请勾选同意协议');
    }
    return promise.resolve();
  }

  function onNumChange(value) {
    setNum(value);
  }
  return (
    <div className="major-financing-application">
      <div className="content-width">
        <div className="major-financing-application__title">重大融资申请</div>
        <div className="margin-top20">
          <Spin spinning={applyLoading}>
            <Form
              {...formItemLayout}
              form={form}
              name="form"
              className="form"
              onFinish={onFinish}
            >
              <div className="form-info__box">
                <div className="form-info__box-title">融资需求信息</div>
                <div className="form-info__box-con">
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="projectName"
                        label="项目名称"
                        rules={[
                          {
                            required: true,
                            message: '请输入项目名称',
                          },
                          { whitespace: true },
                        ]}
                      >
                        <Input placeholder="请输入项目名称" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="projectCode"
                        label="项目审批代码"
                      >
                        <Input placeholder="请输入项目审批代码" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="itemTypes"
                        label="建设类型"
                        rules={[
                          {
                            required: true,
                            message: '请选择建设类型',
                          },
                        ]}
                      >
                        <Cascader options={projectTypeSel} placeholder="请选择建设类型" fieldNames={{ label: 'name', value: 'id', children: 'children' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="projectType"
                        label="建设性质"
                        rules={[
                          {
                            required: true,
                            message: '请选择建设性质',
                          },
                        ]}
                      >
                        <Select placeholder="请选择建设性质">
                          {
                            dict?.projectType?.map((item) => (
                              <Select.Option value={item.val} key={item.val}>
                                {item.name}
                              </Select.Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="projectSum"
                        label={(
                              <span>
                              投资总额
                                <Tooltip title="投资总额 = 贷款总需求 + 财政投入 + 资本金">
                                  <QuestionCircleOutlined />
                                </Tooltip>
                              </span>
                              )}
                        rules={[
                          {
                            required: true,
                            message: '请输入投资总额',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="请输入投资总额"
                          addonAfter="万元"
                          min={1000}
                          max={99999999}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="financialFunds"
                        label="财政投入"
                        rules={[
                          {
                            required: true,
                            message: '请输入财政投入（如无，请填写0）',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="请输财政投入"
                          addonAfter="万元"
                          min={0}
                          max={99999999}
                          onChange={onNumChange}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="selfOwnedFunds"
                        label="资本金"
                        rules={[
                          {
                            required: true,
                            message: '请输入资本金',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="请输入资本金"
                          addonAfter="万元"
                          min={0}
                          max={99999999}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    {
                      num > 0 && (
                        <Col span={12}>
                          <Form.Item
                            name="projectFiles"
                            label="财政投入佐证材料"
                            rules={[
                              {
                                required: true,
                                message: '请上传财政投入佐证材料',
                              },
                            ]}
                          >
                            <Upload {...props} fileList={list}>
                              <Button icon={<UploadOutlined />}>文件上传</Button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      )
                    }
                    <Col span={12}>
                      <Form.Item
                        name="financingAmount"
                        label="贷款总需求"
                        rules={[
                          { required: true, message: '请输入贷款总需求' },
                        ]}
                      >
                        <InputNumber
                          placeholder="请输入贷款总需求"
                          addonAfter="万元"
                          min={0}
                          max={99999999}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="capitalTerm"
                        label="资金使用期限"
                        rules={[
                          { required: true, message: '请输入资金使用期限' },
                        ]}
                      >
                        <InputNumber
                          placeholder="请输入资金使用期限"
                          addonAfter="月"
                          min={1}
                          max={999}
                          precision={0}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="areaId"
                        label="建设地点"
                        rules={[
                          {
                            required: true,
                            message: '请选择建设地点',
                          },
                        ]}
                      >
                        <Cascader options={area} placeholder="请选择项目所在区域" fieldNames={{ label: 'name', value: 'id', children: 'children' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="address"
                        label="详细建设地点"
                        rules={[
                          { required: true, message: '请输入详细建设地点' },
                          { whitespace: true },
                        ]}
                      >
                        <Input placeholder="请输入详细建设地点" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="startTime"
                        label="拟开工日期"
                        rules={[
                          {
                            required: true,
                            message: '请选择拟开工日期',
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder="请输入拟开工日期"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="endTime"
                        label="拟建成日期"
                        rules={[
                          {
                            required: true,
                            message: '请选择拟建成日期',
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder="请输入拟建成日期"
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="contactPerson"
                        label="项目联系人"
                        rules={[
                          { required: true, message: '请输入项目联系人' },
                          { whitespace: true },
                        ]}
                      >
                        <Input placeholder="请输入项目联系人" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="contactTel"
                        label="联系方式"
                        rules={[
                          { required: true, message: '请输入联系方式' },
                          { whitespace: true },
                        ]}
                      >
                        <Input placeholder="请输入联系方式" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="departmentPosition"
                        label="联系人部门职务"
                        rules={[
                          { required: true, message: '请输入联系人部门职务' },
                          { whitespace: true },
                        ]}
                      >
                        <Input placeholder="请输入联系人部门职务" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="nationProject"
                        label="是否为国家级重大项目"
                        rules={[
                          { required: true, message: '请选择是否为国家级重大项目' },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={0}>是</Radio>
                          <Radio value={1}>否</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="provincialProject"
                        label="是否为省级重大项目"
                        rules={[
                          { required: true, message: '请选择是否为省级重大项目' },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value={0}>是</Radio>
                          <Radio value={1}>否</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="duration"
                        label="项目期限"
                        rules={[
                          {
                            required: true,
                            message: '请输入项目期限',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="请输入项目期限"
                          addonAfter="年"
                          min={0}
                          max={9999}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="incomeSource"
                        label="收入来源"
                        rules={[
                          { required: true, message: '请输入收入来源' },
                          { whitespace: true },
                        ]}
                      >
                        <Input placeholder="请输入收入来源" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="rateReturn"
                        label="项目回报率"
                        rules={[
                          {
                            required: true,
                            message: '请输入项目回报率',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="请输入项目回报率"
                          addonAfter="%"
                          min={0}
                          max={9999}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="paybackPeriod"
                        label="项目回收期"
                        rules={[
                          {
                            required: true,
                            message: '请输入项目回收期',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="请输入项目回收期"
                          addonAfter="年"
                          min={0}
                          max={9999}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="projectProgress"
                        label="项目进展"
                        rules={[
                          { required: true, message: '请输入项目进展' },
                          { whitespace: true },
                        ]}
                      >
                        <Input placeholder="请输入项目进展" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="projectScale"
                        label="主要建设内容及规模"
                        rules={[
                          { required: true, message: '请输入主要建设内容及规模' },
                          { whitespace: true },
                        ]}
                      >
                        <Input.TextArea maxLength={500} showCount placeholder="请输入主要建设内容及规模" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="otherElements"
                        label="可补充其他要素"
                      >
                        <Input.TextArea maxLength={500} showCount placeholder="请输入可补充其他要素" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="form-info__box">
                <div className="form-info__box-title">企业信息</div>
                <div className="form-info__box-con">
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name="enterpriseType"
                        label="企业类型"
                        rules={[
                          {
                            required: true,
                            message: '请选择企业类型',
                          },
                        ]}
                      >
                        <Select placeholder="请选择企业类型">
                          {
                            dict?.enterpriseType?.map((item) => (
                              <Select.Option value={item.val} key={item.val}>
                                {item.name}
                              </Select.Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="capitalRegistered"
                        label="注册资金"
                        rules={[
                          {
                            required: true,
                            message: '请输入注册资金',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="请输入注册资金"
                          addonAfter="万元"
                          min={0}
                          max={99999999}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="liabilityRatio"
                        label="资产负债率"
                        rules={[
                          {
                            required: true,
                            message: '请输入资产负债率',
                          },
                        ]}
                      >
                        <InputNumber
                          placeholder="请输入资产负债率"
                          addonAfter="%"
                          min={0}
                          max={9999}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="ratingCredit"
                        label="信用等级"
                        rules={[
                          {
                            required: true,
                            message: '请选择信用等级',
                          },
                        ]}
                      >
                        <Select placeholder="请选择信用等级">
                          {
                            dict?.ratingCredit?.map((item) => (
                              <Select.Option value={item.val} key={item.val}>
                                {item.name}
                              </Select.Option>
                            ))
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="authorize">
                <Form.Item
                  name="authorize"
                  wrapperCol={{ span: 24 }}
                  valuePropName="checked"
                  rules={[
                    { validator: checkAuthorize },
                  ]}

                >
                  <Checkbox>
                    同意并接受
                    <Button type="link" className="authorize-name" onClick={() => setAuthInfoModal(true)}>《平台信息授权协议》</Button>
                  </Checkbox>
                </Form.Item>
              </div>
              <div className="submit-btn-box">
                <Button type="primary" htmlType="submit" loading={loading || updateLoading}>
                  提交申请
                </Button>
              </div>
            </Form>
          </Spin>
          <Modal title="授权协议查看" open={authInfoModal} width={900} footer={null} onCancel={() => setAuthInfoModal(false)}>
            <div dangerouslySetInnerHTML={{ __html: AuthorizationInfo }} style={{ maxHeight: 600, overflowY: 'scroll' }} />
          </Modal>
        </div>
      </div>

    </div>
  );
}
