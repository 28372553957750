import { Spin, Button, Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import qs from 'query-string';
import { useRequest } from 'ahooks';
import { getProjectFinancingInfo, updateEnd } from '@/services/myApplication';
import './MajorProjectTimeline.less';

const obj = {
  0: 'processing', 1: 'error', 2: 'cancel', 3: 'success', // status 为0正常状态，进行中UI为实心数字； 1 拒绝； 2取消； 3：所有流程完成
};

export default function Timeline() {
  const { search } = useLocation();
  const { id } = qs.parse(search);
  const { data = {}, loading, run } = useRequest(() => getProjectFinancingInfo(id));
  const { run: updateRun } = useRequest(updateEnd, {
    manual: true,
    onSuccess() {
      run();
    },
  });

  function getIcon(status, index) {
    if (status === 0) {
      return index;
    } if (status === 1 || status === 2) {
      return <CloseOutlined />;
    }
    return <CheckOutlined />;
  }

  function confirm() {
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      content: '确认完结当前申请吗？',
      okText: '确认',
      cancelText: '取消',
      onOk() {
        updateRun(id);
      },
    });
  }

  return (
    <Spin spinning={loading}>
      <div>
        <div className="myapplication-detail__title">进度查询</div>
        <div className="myapplication-detail__sub-title">我的申请信息</div>
        <div className="myapplication-detail__grid">
          <div>
            申请金额（万元）：
            {data?.loanMoney}
          </div>
          <div>
            贷款期限：
            {data?.loanTerm}
            个月
          </div>
          <div>
            申请时间：
            {data?.gmtCreate}
          </div>
        </div>
        <div className="myapplication-detail__sub-title">进度信息</div>
        <div className="major-project-timeline">
          {
            data?.item?.map((item, index) => (
              <div className={`timeline-item timeline-item-${index + 1 === data?.item.length && obj?.[item.status]}`}>
                <div className="timeline-icon">
                  {
                    index + 1 === data?.item.length
                      ? (
                        <>
                        {getIcon(item.status, index + 1)}
                        </>
                      ) : <CheckOutlined />
                  }
                </div>
                <div className="timeline-cont">
                  <div className="timeline-title">{item.title}</div>
                  <div>{item.operateReason}</div>
                  <div>{item.time}</div>
                </div>

              </div>
            ))
          }
        </div>
        {
          data?.finish && (
            <div className="major-project__btn-box">
              <Button type="primary" onClick={confirm}>完结</Button>
            </div>
          )
        }
      </div>
    </Spin>
  );
}
