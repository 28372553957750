import { useEffect } from 'react';
import { useRequest } from 'ahooks';
import request from '@/utils/request';

const useKeepAlive = (interval = 1 * 60 * 1000) => {
  function keepTokenAlive() {
    return request({
      url: '/guarantee/application/waiting',
      method: 'GET',
    });
  }

  const { run } = useRequest(
    keepTokenAlive,
    {
      manual: true,
      onError: (error) => console.error('Failed to keep token alive:', error),
      // 这里可以根据需要调整请求策略
    },
  );

  useEffect(() => {
    const timerId = setInterval(() => {
      run(); // 定期运行请求以保持token活跃
    }, interval);

    return () => clearInterval(timerId); // 组件卸载时清理定时器
  }, [run, interval]);

  return null; // 该钩子不需要渲染任何内容
};

export default useKeepAlive;
