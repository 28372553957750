import {
  useState, useEffect, createRef, useRef,
} from 'react';
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Spin,
  Upload,
  Card,
  message,
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import qs from 'query-string';
import moment from 'moment/moment';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { useSelector } from 'react-redux';
import { getArea, getDictType } from '@/services/common';
import { uploadFileUrl } from '@/services/majorFinancing';
import {
  addApply, getfacilityLoanApplicationDetail, updateBackApply,
} from '@/services/facilityLoan';
import { FACILITY_LOAN_TYPE } from '@/utils/mappingDict';
import './index.less';
import ApplicationLoanCard from '../components/ApplicationLoanCard';
import { getFileListVal } from './fileDataUtil';
import { moneyDelete } from '@/utils/utils';

const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
};

export default function Application() {
  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const { search, state: passedState } = useLocation();
  const { backId } = qs.parse(search);
  const { userType } = useSelector((state) => state);
  const user = useSelector((state) => state.userInfo);

  const loanCardRef = useRef();
  const loanFormRef = createRef();
  const [loanFormData, setLoanFormData] = useState({});

  const [authInfoModal, setAuthInfoModal] = useState(false); // 授权书内容
  const [legalPersonFiles, setLegalPersonFiles] = useState([]); // 公司用户-法人执照
  const [creditReportFiles, setCreditReportFiles] = useState([]); // 公司用户-企业信用报告
  const [openingPermitFiles, setOpeningPermitFiles] = useState([]); // 公司用户-开户许可

  const { data: dict = {} } = useRequest(getDictType, { // 筛选项字典
    defaultParams: [FACILITY_LOAN_TYPE],
  });

  const { data: area = [] } = useRequest(getArea, {
    onSuccess(res) {
      const province = res.filter((item) => item.code === passedState.provinceId);
      const city = province[0].children.filter((item) => item.code === passedState.cityId);
      const theArea = city[0].children.filter((item) => item.code === passedState.areaId);
      form.setFieldsValue({
        declarationArea: `${city[0].name} / ${theArea[0].name}`,
        year: passedState.year,
        // 个人用户
        applicantNo: user.idcardNo,
        applicantName: userType === 2 ? user.realName : user.displayName,
        contactTel: user.phone,
        // 公司用户
        creditCode: user.displayCode,
        contactPerson: user.realName,
      });
    },
  }); // 区域

  const { data: reApplyData, loading: applyLoading, run: backRun } = useRequest(getfacilityLoanApplicationDetail, {
    manual: true,
    onSuccess(res) {
      if (res) {
        const legalFiles = [];
        const creditFiles = [];
        const permitFiles = [];
        if (!isEmpty(res?.legalPersonFileVos)) {
          // eslint-disable-next-line array-callback-return
          res.legalPersonFileVos.map((item, index) => {
            legalFiles.push({
              uid: index.toString(),
              name: item.fileName,
              status: 'done',
              url: item.fileUrl,
            });
          });
        }
        if (!isEmpty(res?.creditReportFileVos)) {
          // eslint-disable-next-line array-callback-return
          res.creditReportFileVos.map((item, index) => {
            creditFiles.push({
              uid: index.toString(),
              name: item.fileName,
              status: 'done',
              url: item.fileUrl,
            });
          });
        }
        if (!isEmpty(res?.openingPermitFileVos)) {
          // eslint-disable-next-line array-callback-return
          res.openingPermitFileVos.map((item, index) => {
            permitFiles.push({
              uid: index.toString(),
              name: item.fileName,
              status: 'done',
              url: item.fileUrl,
            });
          });
        }
        setLegalPersonFiles(legalFiles);
        setCreditReportFiles(creditFiles);
        setOpeningPermitFiles(permitFiles);

        const obj = {
          ...res,
          ownFunds: moneyDelete(res.ownFunds),
          financialFunds: moneyDelete(res.financialFunds),
          otherFunds: moneyDelete(res.otherFunds),
          declarationArea: `${res.declareCity} / ${res.declareArea}`,
          legalPersonFileVos: legalFiles,
          creditReportFileVos: creditFiles,
          openingPermitFileVos: permitFiles,
          projectArea: [res.provinceCode, res.cityCode, res.areaCode],
          creditCode: res.applicantNo,
        };
        form.setFieldsValue(obj);
        setLoanFormData(res.facilityApplicationLoanVos);
      }
    },
  });

  const { loading, run } = useRequest(addApply, {
    manual: true,
    onSuccess() {
      Navigate('/result');
    },
  });

  const { loading: updateLoading, run: updateRun } = useRequest(updateBackApply, {
    manual: true,
    onSuccess() {
      Navigate('/result');
    },
  });

  const props = {
    name: 'file',
    accept: '.rar,.zip,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.pdf',
    action: uploadFileUrl,
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        message.error('请上传200M以下文件!');
      }
      return isLt2M;
    },
    // onChange({ file, fileList }) {
    //   if (isEmpty(fileList)) {
    //     form.setFieldsValue({ projectFiles: undefined });
    //   }
    //   setList(fileList);
    //   if (file.status === 'done') {
    //     message.success('上传成功');
    //   } else if (file.status === 'error') {
    //     message.error('上传失败');
    //   }
    // },
  };

  useEffect(() => {
    if (backId) {
      backRun(backId);
    }

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      return '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleInputChange = () => {
    const { ownFunds, financialFunds, otherFunds } = form.getFieldsValue(['ownFunds', 'financialFunds', 'otherFunds']);
    const loanAmount = loanFormRef.current.getFieldValue('loanAmount');
    const investmentAmount = moneyDelete(ownFunds) + moneyDelete(financialFunds) + moneyDelete(otherFunds) + moneyDelete(loanAmount);

    form.setFieldsValue({ investmentAmount });
  };

  // 获取ApplicationLoanCard中的数据
  const getNewLoanListData = () => {
    const specialBondInformationList = [];

    const values = loanFormRef.current.getFieldsValue();
    const arr = loanCardRef.current.claimFormList;
    const dateFormat = 'YYYY-MM-DD';

    arr.forEach((e, index) => {
      const lendingBank = values[`lendingBank_${arr[index].key}`];
      const loanAmount = values[`loanAmount_${arr[index].key}`];
      const loanType = values[`loanType_${arr[index].key}`];
      const loanRate = values[`loanRate_${arr[index].key}`];

      const [start, end] = values[`lendingStart_${arr[index].key}`];
      const lendingStart = start && moment(start).format(dateFormat);
      const lendingEnd = end && moment(end).format(dateFormat);

      let facilityLoanFileVos = values[`facilityLoanFileVos_${arr[index].key}`];
      let loanContractFileVos = values[`loanContractFileVos_${arr[index].key}`];
      let fundsWithdrawalFileVos = values[`fundsWithdrawalFileVos_${arr[index].key}`];
      let loanFundsFileVos = values[`loanFundsFileVos_${arr[index].key}`];
      let bankInterestFileVos = values[`bankInterestFileVos_${arr[index].key}`];
      let otherFileVos = values[`otherFileVos_${arr[index].key}`];

      if (!isEmpty(facilityLoanFileVos)) {
        facilityLoanFileVos = getFileListVal(facilityLoanFileVos);
      }
      if (!isEmpty(loanContractFileVos)) {
        loanContractFileVos = getFileListVal(loanContractFileVos);
      }
      if (!isEmpty(fundsWithdrawalFileVos)) {
        fundsWithdrawalFileVos = getFileListVal(fundsWithdrawalFileVos);
      }
      if (!isEmpty(loanFundsFileVos)) {
        loanFundsFileVos = getFileListVal(loanFundsFileVos);
      }
      if (!isEmpty(bankInterestFileVos)) {
        bankInterestFileVos = getFileListVal(bankInterestFileVos);
      }
      if (!isEmpty(otherFileVos)) {
        otherFileVos = getFileListVal(otherFileVos);
      }

      const { oneData } = e;
      const id = oneData ? oneData.id : null;

      specialBondInformationList.push({
        id,
        lendingBank,
        loanAmount,
        lendingStart,
        lendingEnd,
        loanType,
        loanRate,
        facilityLoanFileVos,
        loanContractFileVos,
        fundsWithdrawalFileVos,
        loanFundsFileVos,
        bankInterestFileVos,
        otherFileVos,
      });
    });

    return specialBondInformationList;
  };

  function onFinish(values) {
    loanFormRef.current.validateFields().then(() => {
      const loans = getNewLoanListData();

      const obj = {
        ...values,
        facilityId: !isNull(passedState) ? passedState.id : reApplyData.facilityId,
        applicantType: userType,
        facilityApplicationLoanVos: loans,
        applicantId: user.id,
        loanAmount: moneyDelete(loanFormRef.current.getFieldValue('loanAmount')),
        id: backId,
      };
      if (backId) {
        const [provC, cityC, areaC] = values.projectArea;
        obj.provinceCode = provC;
        obj.cityCode = cityC;
        obj.areaCode = areaC;
      } else {
        const [provC, cityC, areaC] = values.projectArea;
        obj.provinceId = provC;
        obj.cityId = cityC;
        obj.areaId = areaC;
      }

      // 公司账号文件上传
      if (!isEmpty(obj.legalPersonFileVos)) {
        const { fileList } = obj.legalPersonFileVos;
        obj.legalPersonFileVos = getFileListVal({ fileList: isUndefined(fileList) ? obj.legalPersonFileVos : fileList });
      }
      if (!isEmpty(obj.creditReportFileVos)) {
        const { fileList } = obj.creditReportFileVos;
        obj.creditReportFileVos = getFileListVal({ fileList: isUndefined(fileList) ? obj.creditReportFileVos : fileList });
      }
      if (!isEmpty(obj.openingPermitFileVos)) {
        const { fileList } = obj.openingPermitFileVos;
        obj.openingPermitFileVos = getFileListVal({ fileList: isUndefined(fileList) ? obj.openingPermitFileVos : fileList });
      }

      if (backId) {
        // 退回记录从新申请
        updateRun(obj);
      } else {
        // 新申请
        run(obj);
      }
    });
  }

  function checkFileUploading(_, value) {
    const promise = Promise;

    if (!isUndefined(value)) {
      const { event } = value;
      if (!isUndefined(event) && event.type === 'progress') {
        return promise.reject('请等待文件上传完成');
      }
    }

    return promise.resolve();
  }

  function checkAuthorize(_, value) {
    const promise = Promise;

    const editKeys = loanCardRef.current.editKey;
    const inEditing = !isUndefined(editKeys);
    if (inEditing) {
      message.error('请保存编辑中的贷款信息');
      return promise.reject();
    }

    if (!value) {
      return promise.reject('请勾选同意承诺函');
    }
    return promise.resolve();
  }

  return (
    <div className="major-financing-application">
      <div className="content-width">
        {/* <div className="major-financing-application__title">重大融资申请</div> */}
        <div className="margin-top20">
          <Spin spinning={applyLoading}>
            <Form
              {...formItemLayout}
              form={form}
              name="form"
              className="form"
              onFinish={onFinish}
            >

              <Card title="申报信息" className="form-info__box">
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="projectName"
                      label="项目名称"
                      rules={[
                        {
                          required: true,
                          message: '请输入项目名称',
                        },
                        { pattern: /^\s*\S.*$/, message: '请输入项目名称' },
                      ]}
                    >
                      <Input placeholder="请输入项目名称" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="declarationArea"
                      label="申报区域"
                      rules={[
                        {
                          required: true,
                          message: '请输入申报区域',
                        },
                      ]}
                    >
                      <Input placeholder="请输入申报区域" disabled />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="year"
                      label="申报年度"
                      rules={[
                        {
                          required: true,
                          message: '请输入申报年度',
                        },
                      ]}
                    >
                      <Input placeholder="请输入申报年度" disabled />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="projectProgress"
                      label="项目进展"
                      rules={[
                        {
                          required: true,
                          message: '请输入项目进展',
                        },
                        { pattern: /^\s*\S.*$/, message: '请输入项目进展' },
                      ]}
                    >
                      <Input placeholder="请输入项目进展" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="projectArea"
                      label="项目所在区域"
                      rules={[
                        {
                          required: true,
                          message: '请选择项目所在区域',
                        },
                      ]}
                    >
                      <Cascader options={area} placeholder="请选择项目所在区域" fieldNames={{ label: 'name', value: 'id', children: 'children' }} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="address"
                      label="项目详细地址"
                      rules={[
                        {
                          required: true,
                          message: '请输入项目详细地址',
                        },
                        { pattern: /^\s*\S.*$/, message: '请输入项目详细地址' },
                      ]}
                    >
                      <Input placeholder="请输入项目详细地址" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="ownFunds"
                      label="自有资金"
                      rules={[
                        {
                          required: true,
                          message: '请输入自有资金',
                        },
                        {
                          validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('自有资金必须大于0'))),
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="请输入自有资金"
                        addonAfter="万元"
                        min={0}
                        max={99999999}
                        style={{ width: '100%' }}
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="financialFunds"
                      label="财政资金"
                      rules={[
                        {
                          required: true,
                          message: '请输入财政资金',
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="如果无，请填0"
                        addonAfter="万元"
                        min={0}
                        max={99999999}
                        style={{ width: '100%' }}
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="otherFunds"
                      label="其他资金"
                      rules={[
                        {
                          required: true,
                          message: '请输入其他资金',
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="如果无，请填0"
                        addonAfter="万元"
                        min={0}
                        max={99999999}
                        style={{ width: '100%' }}
                        onChange={handleInputChange}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="investmentAmount"
                      label="总投资金额"
                      rules={[
                        {
                          required: true,
                          message: '请输入总投资金额',
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="请输入总投资金额"
                        addonAfter="万元"
                        style={{ width: '100%' }}
                        disabled
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="projectContent"
                      label="项目建设内容"
                      labelCol={{
                        sm: { span: 4 },
                      }}
                      wrapperCol={{
                        sm: { span: 20 },
                      }}
                      rules={[
                        {
                          required: true,
                          message: '请输入项目建设内容',
                        },
                        { pattern: /^\s*\S.*$/, message: '请输入项目建设内容' },
                      ]}
                    >
                      <Input.TextArea maxLength={500} showCount placeholder="请输入项目建设内容" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="content"
                      label="其他可补充信息"
                      labelCol={{
                        sm: { span: 4 },
                      }}
                      wrapperCol={{
                        sm: { span: 20 },
                      }}
                    >
                      <Input.TextArea maxLength={500} showCount placeholder="请输入其他可补充信息" />
                    </Form.Item>
                  </Col>

                </Row>
              </Card>

              <ApplicationLoanCard
                ref={loanCardRef}
                formRef={loanFormRef}
                loanTypeDict={dict}
                amountChange={handleInputChange}
                initialData={loanFormData}
              />

              {
                userType === 3 && (
                  <Card title="申报主体信息（企业）" className="form-info__box">
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name="applicantName"
                          label="企业名称"
                          rules={[
                            {
                              required: true,
                              message: '请输入企业名称',
                            },
                          ]}
                        >
                          <Input placeholder="请输入企业名称" disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="creditCode"
                          label="统一社会信用代码"
                          rules={[
                            {
                              required: true,
                              message: '请输入统一社会信用代码',
                            },
                          ]}
                        >
                          <InputNumber
                            placeholder="请输入统一社会信用代码"
                            style={{ width: '100%' }}
                            disabled
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="contactPerson"
                          label="联系人"
                          rules={[
                            { required: true, message: '请输入联系人' },
                            { pattern: /^\s*\S.*$/, message: '请输入联系人' },
                          ]}
                        >
                          <Input placeholder="请输入联系人" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="contactTel"
                          label="联系方式"
                          rules={[
                            { required: true, message: '请输入联系方式' },
                            { pattern: /^\s*\S.*$/, message: '请输入联系方式' },
                          ]}
                        >
                          <Input placeholder="请输入联系方式" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="bankDeposit"
                          label="开户银行"
                          rules={[
                            {
                              required: true,
                              message: '贴息资金发放开户银行名称',
                            },
                            { pattern: /^\s*\S.*$/, message: '贴息资金发放开户银行名称' },
                          ]}
                        >
                          <Input placeholder="贴息资金发放开户银行名称" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="openAccount"
                          label="开户账号"
                          rules={[
                            {
                              required: true,
                              message: '贴息资金发放银行卡账号',
                            },
                            { pattern: /^\s*\S.*$/, message: '贴息资金发放银行卡账号' },
                          ]}
                        >
                          <Input placeholder="贴息资金发放银行卡账号" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="legalPersonFileVos"
                          label="法人执照副本及章程扫描件"
                          rules={[
                            {
                              required: true,
                              message: '请上传法人执照副本及章程扫描件',
                            },
                            {
                              validator: checkFileUploading,
                            },
                          ]}
                        >
                          <Upload
                            {...props}
                            fileList={legalPersonFiles}
                            onPreview={() => { }}
                            onChange={
                              ({ file, fileList }) => {
                                setLegalPersonFiles(fileList);
                                if (file.status === 'done') {
                                  message.success('上传成功');
                                } else if (file.status === 'error') {
                                  message.error('上传失败');
                                }
                              }
                            }
                          >
                            <Button icon={<UploadOutlined />}>上传文件</Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="creditReportFileVos"
                          label={(
                            <div>
                              企业信用报告
                              <div style={{ fontSize: 10, color: '#a7a7a7' }}>（或贷款银行出具的按时还本付息情况说明）</div>
                            </div>
                          )}
                          labelCol={{
                            sm: { span: 10 },
                          }}
                          wrapperCol={{
                            sm: { span: 14 },
                          }}
                          rules={[
                            {
                              required: true,
                              message: '请上传企业信用报告',
                            },
                            {
                              validator: checkFileUploading,
                            },
                          ]}
                        >
                          <Upload
                            {...props}
                            fileList={creditReportFiles}
                            onPreview={() => { }}
                            onChange={
                              ({ file, fileList }) => {
                                setCreditReportFiles(fileList);
                                if (file.status === 'done') {
                                  message.success('上传成功');
                                } else if (file.status === 'error') {
                                  message.error('上传失败');
                                }
                              }
                            }
                          >
                            <Button icon={<UploadOutlined />}>上传文件</Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="openingPermitFileVos"
                          label="开户许可证"
                          rules={[
                            {
                              required: true,
                              message: '请上传开户许可证',
                            },
                            {
                              validator: checkFileUploading,
                            },
                          ]}
                        >
                          <Upload
                            {...props}
                            fileList={openingPermitFiles}
                            onPreview={() => { }}
                            onChange={
                              ({ file, fileList }) => {
                                setOpeningPermitFiles(fileList);
                                if (file.status === 'done') {
                                  message.success('上传成功');
                                } else if (file.status === 'error') {
                                  message.error('上传失败');
                                }
                              }
                            }
                          >
                            <Button icon={<UploadOutlined />}>上传文件</Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                )
              }

              {
                userType === 2 && (
                  <Card title="申报主体信息（个人）" className="form-info__box">
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          name="applicantName"
                          label="姓名"
                          rules={[
                            {
                              required: true,
                              message: '请输入姓名',
                            },
                          ]}
                        >
                          <Input placeholder="请输入姓名" disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="applicantNo"
                          label="身份证号"
                          rules={[
                            {
                              required: true,
                              message: '请输入身份证号',
                            },
                          ]}
                        >
                          <Input placeholder="请输入身份证号" disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="contactTel"
                          label="联系方式"
                          rules={[
                            { required: true, message: '请输入联系方式' },
                            { pattern: /^\s*\S.*$/, message: '请输入联系方式' },
                          ]}
                        >
                          <Input placeholder="请输入联系方式" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="bankDeposit"
                          label="开户银行"
                          rules={[
                            {
                              required: true,
                              message: '贴息资金发放开户银行名称',
                            },
                            { pattern: /^\s*\S.*$/, message: '贴息资金发放开户银行名称' },
                          ]}
                        >
                          <Input placeholder="贴息资金发放开户银行名称" />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name="openAccount"
                          label="开户账号"
                          rules={[
                            {
                              required: true,
                              message: '贴息资金发放银行卡账号',
                            },
                            { pattern: /^\s*\S.*$/, message: '贴息资金发放银行卡账号' },
                          ]}
                        >
                          <Input placeholder="贴息资金发放银行卡账号" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                )
              }

              <div className="authorize">
                <Form.Item
                  name="authorize"
                  wrapperCol={{ span: 24 }}
                  valuePropName="checked"
                  rules={[
                    { validator: checkAuthorize },
                  ]}

                >
                  <Checkbox
                    onChange={(e) => {
                      form.setFieldsValue({ authorize: false });
                      if (e.target.checked) {
                        setAuthInfoModal(true);
                      }
                    }}
                  >
                    同意并接受
                    <Button type="link" className="authorize-name" onClick={() => setAuthInfoModal(true)}>《承诺函》</Button>
                  </Checkbox>
                </Form.Item>
              </div>
              <div className="submit-btn-box">
                <Button type="primary" htmlType="submit" loading={loading || updateLoading}>
                  提交申请
                </Button>
              </div>
            </Form>
          </Spin>
          <Modal
            title="承诺函"
            open={authInfoModal}
            footer={
              [
                <Button key="skip" onClick={() => setAuthInfoModal(false)}>
                  取消
                </Button>,
                <Button
                  type="primary"
                  key="confirm"
                  onClick={() => {
                    setAuthInfoModal(false);
                    form.setFieldsValue({ authorize: true });
                  }}
                >
                  确认
                </Button>,
              ]
            }
            onCancel={() => setAuthInfoModal(false)}
          >
            <div>
              {userType === 3 ? '本企业' : '本人'}
              承诺提交的所有材料真实合法，如因提交的账户信息不准确、账户冻结等主观原因导致贴息资金无法成功支付到账，相关责任自行承担。
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}
