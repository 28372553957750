import { object } from 'prop-types';
import { useSelector } from 'react-redux';
import './FormInfo.less';

export default function BasicInfo({ data }) {
  const { userType } = useSelector((state) => state);
  return (
    <div className="basic-info">
      <div className="basic-info__title">基本信息</div>
      <div className="basic-info__grid border-b">
        {
          userType === 3 && (
            <div>
              主体名称：
              {data?.basicName}
            </div>
          )
        }

        <div>
          主体类型：
          {data?.basicTypeName}
        </div>
        {
          userType === 3 ? (
            <>
              <div>
                主体等级：
                {data?.basicLevelName}
              </div>
              <div>
                等级评定时间：
                {data?.levelTime}
              </div>
            </>
          ) : (
            <div>
              姓名：
              {data?.familyName}
            </div>
          )
        }
        <div>
          身份证号：
          {data?.basicCard}
        </div>
        <div>
          联系方式：
          {data?.basicTel}
        </div>
        <div>
          经营区域：
          {`${data?.basicProvicnceName}-${data?.basicCityName}-${data?.basicAreaName}`}
        </div>
        <div>
          详细地址：
          {data?.basicAddress}
        </div>
      </div>
      <div className="basic-info__title">经营信息</div>
      <div className="basic-info__grid">
        <div>
          主营业务类型：
          {data?.businessTypeName}
        </div>
        <div>
          年经营收入：
          {data?.businessAmount}
          万元
        </div>
        <div>
          实际经营年限：
          {data?.businessYear}
          年
        </div>
        {
          data?.plantType && (
            <>
              <div>
                种植品种/养殖品种：
                {data?.plantType}
              </div>
              <div>
                种植面积/养殖数量：
                {data?.plantArea}
                {data?.unitName}
              </div>
            </>
          )
        }
      </div>

    </div>
  );
}

BasicInfo.propTypes = {
  data: object,
};
