import {
  Spin, List, Tag, Button,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useRequest } from 'ahooks';
import {
  bool, object,
} from 'prop-types';
import { isEmpty, isNull } from 'lodash';
import moment from 'moment';
import FilterType from '@/components/FilterType/FilterType';
import FilterTypeChild from '@/components/FilterType/FilterTypeChild';
import EmptyImg from '@/components/EmptyImg/EmptyImg';
import { getFacilityLoanList } from '@/services/industryBankLoan';
import './LoanDeclar.less';

/**
 * 产品列表组件
 * @param {object} data 数据源
 * @param {bool} isShow 是否显示按钮
 * @param {string} type 类型银行产品\保险产品
 * @param {bool} filter 是否显示筛选条件
 * */
export default function LoanDeclar({
  data, filter,
}) {
  const Navigate = useNavigate();
  const { area } = data;
  const [list, setList] = useState({});
  const [val, setVal] = useState({});
  const [childArea, setChildArea] = useState([]);

  const { run, loading } = useRequest(getFacilityLoanList, { // 保险产品
    onSuccess(res) {
      setList(res);
    },
  });

  function getData(e) {
    run({ ...e });
  }

  function onChange(page, size) {
    getData({ ...val, pageNum: page, pageSize: size });
  }

  function filterArea(areaID) {
    const matched = area.filter((v) => v.id === areaID);
    const { children } = matched[0];
    return children;
  }

  function handleFilter(arr, t) {
    let obj = { ...val };
    if (t === 'cityCode' || t === 'areaCode') {
      if (t === 'cityCode') {
        if (arr) {
          const childrenArea = filterArea(arr[0]);
          setChildArea(childrenArea);
          obj = { ...obj, regionVo: { cityCode: arr[0] } };
        } else {
          // 全部城市则不需要参数
          setChildArea([]);
          obj = { ...obj, regionVo: {} };
        }
        setVal(obj);
        getData(obj);
      } else if (t === 'areaCode') {
        if (arr) {
          obj = { ...obj, regionVo: { ...obj.regionVo, areaCode: arr[0] } };
        } else {
          // 全部区县则只需要市级参数
          obj = { ...obj, regionVo: { cityCode: obj.regionVo.cityCode } };
        }
        setVal(obj);
        getData(obj);
      }
    } else {
      obj = { ...val, [t]: arr };
      setVal(obj);
      getData(obj);
    }
  }

  function handleChange(id) {
    Navigate(`/industryBankLoan/detail?id=${id}`);
  }

  return (
    <div className="bank-product">
      <Spin spinning={loading}>
        {
          filter && (
            <>
              <span className="ld-filter-title">筛选条件</span>
              <div className="product-filter">
                {
                  !isEmpty(data?.area) && (
                    <FilterType title="发布区域" titleWidth={88} data={data?.area} radio onChange={(e) => handleFilter(e, 'cityCode')} />
                  )
                }
                {
                  !isEmpty(childArea) && (
                    <FilterTypeChild title="" data={childArea} radio onChange={(e) => handleFilter(e, 'areaCode')} />
                  )
                }
              </div>
            </>
          )
        }
        <span className="ld-list-title">产业化银行贷款贴息申报通知</span>
        {
          isEmpty(list?.items) ? (<EmptyImg height={300} />) : (
            <div className="product-list">
              {/* {
                list?.items?.map((item) => (
                  <CapitalCard data={item} key={item.productId} />
                ))
              }
              <div className="pagination-box">
                <Pagination
                  showQuickJumper
                  defaultCurrent={list?.pageNum}
                  total={list?.totalRows}
                  onChange={onChange}
                />
              </div> */}
              <List
                loading={loading}
                itemLayout="vertical"
                dataSource={list?.items}
                rowKey="id"
                pagination={{
                  onChange,
                  current: list?.pageNum,
                  total: list?.totalRows,
                  showTotal: (total) => `总计： ${total}条`,
                }}
                bordered={false}
                renderItem={(item) => (
                  <List.Item
                    extra={
                      (
                        <Button type="primary" className="ld-product-btn" onClick={() => handleChange(item.id)}>
                          进入申报
                        </Button>
                      )
                    }
                    className="ld-product-item"
                  >
                    <List.Item.Meta
                      title={<span>{item.title}</span>}
                      description={(
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {new DOMParser().parseFromString(item.content, 'text/html').body.textContent}
                        </div>
                      )}
                    />
                    <div style={{ display: 'flex' }}>
                      <Tag color="orange">
                        {item.year}
                        年度
                      </Tag>
                      {/* <Tag color="red">
                        {item.cityId}
                        ·
                        {item.areaId}
                      </Tag> */}
                      <Tag color="blue">
                        申报截止日期：
                        {isNull(item.filingDeadline) ? '-' : moment(item.filingDeadline).format('YYYY-MM-DD')}
                      </Tag>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          )
        }
      </Spin>
    </div>
  );
}

LoanDeclar.propTypes = {
  filter: bool,
  data: object,
};

LoanDeclar.defaultProps = {
  filter: true,
};
