import FormInfo from '@/components/FormInfo/FormInfo';
import './index.less';

export default function Application() {
  return (
    <div className="yhzd-pro-application">
      <div className="content-width">
        <div className="yhzd-pro-application__title">担保融资申请</div>
        <div className="margin-top20">
          <FormInfo type="dbzd" product={{ publishOrgName: '四川省农业融资担保有限公司' }} />
        </div>
      </div>

    </div>
  );
}
