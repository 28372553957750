import request from '@/utils/request';
import { apiBase } from '@/utils/config';

/*
* 个人中心模块
* */

// 获取用户信息
export function getUserInfo() {
  return request({
    url: '/user/info',
    method: 'GET',
  });
}

// 展示该用户我的资料中的 基础信息、经营信息
export function getUserBasicInfo() {
  return request({
    url: '/basic/find/basic/loan',
    method: 'GET',
  });
}

// 获取用户基本信息-经营信息
export function getUserBasic(data) {
  return request({
    url: '/basic/find/basic/loan',
    method: 'GET',
    data,
  });
}

// 安全修改密码
export function updatePass(data) {
  return request({
    url: '/user/password',
    method: 'POST',
    data,
  });
}

// 验证码校验
export function verifyCode(data) {
  return request({
    url: '/common/verify/code',
    method: 'POST',
    data,
  });
}

// 更新头像
export function updateAvatar(data) {
  return request({
    url: '/user/updatePhoto',
    method: 'POST',
    data,
  });
}

// 头像上传
export function uploadImg() {
  return `${apiBase}/common/addfileurl`;
}

// 实名认证 - 获取企业相关信息
export function getEnterprise() {
  return request({
    url: '/user/auth/enterprise',
    method: 'GET',
  });
}

// 企业认证
export function authEnterprise(data) {
  return request({
    url: '/user/auth/enterprise',
    method: 'POST',
    data,
  });
}

// 个人认证
export function authPerson(data) {
  return request({
    url: '/user/auth/person',
    method: 'POST',
    data,
  });
}

// 获取个人相关信息
export function getPerson() {
  return request({
    url: '/user/auth/person',
    method: 'GET',
  });
}
