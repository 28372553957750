import request from '@/utils/request';

// 贷款贴息列表查询
export function getFacilityLoanList(data) {
  return request({
    url: '/facility/loan/user/list',
    method: 'POST',
    data: { ...data, type: 1, status: 1 },
  });
}

// 贷款贴息公示列表查询
export function getFacilityLoanPublicList(data) {
  return request({
    url: '/facility/manage/user/release/publish',
    method: 'POST',
    data: { ...data },
  });
}

// 贷款贴息详细查询
export function getFacilityLoanDetail(id) {
  return request({
    url: '/facility/loan/detail',
    method: 'GET',
    params: { id },
  });
}

// 贷款贴息附件下载
export function attchmentFileDownload(id) {
  return request({
    url: '/facility/loan/download',
    method: 'GET',
    responseType: 'blob',
    params: { id },
  });
}

// 贷款贴息申请
export function addApply(data) {
  return request({
    url: '/facility/application/add',
    method: 'POST',
    data,
  });
}

// 贷款贴息重新申请
export function updateBackApply(data) {
  return request({
    url: '/facility/application/edit',
    method: 'POST',
    data,
  });
}

// 贷款贴息申请详情
export function getfacilityLoanApplicationDetail(id) {
  return request({
    url: `/facility/application/detail?id=${id}&type=1`,
    method: 'GET',
  });
}
