import { Spin, Table } from 'antd';
import { useState } from 'react';
import { useRequest } from 'ahooks';
import {
  bool, object,
} from 'prop-types';
import { isEmpty, isNumber, isUndefined } from 'lodash';
import moment from 'moment';
import FilterType from '@/components/FilterType/FilterType';
import FilterTypeChild from '@/components/FilterType/FilterTypeChild';
import EmptyImg from '@/components/EmptyImg/EmptyImg';
import { getFacilityLoanPublicList } from '@/services/facilityLoan';
import './PublicList.less';

const years = [
  { val: moment('2023').year(), name: 2023 },
];

/**
 * 产品列表组件
 * @param {object} data 数据源
 * @param {bool} isShow 是否显示按钮
 * @param {string} type 类型银行产品\保险产品
 * @param {bool} filter 是否显示筛选条件
 * */
export default function PublicList({
  data, filter,
}) {
  const { area } = data;
  const [list, setList] = useState({});
  const [val, setVal] = useState({});
  const [childArea, setChildArea] = useState([]);

  const { run, loading } = useRequest(getFacilityLoanPublicList, { // 保险产品
    onSuccess(res) {
      setList(res);
    },
  });

  function getData(e) {
    run({ ...e });
  }

  function onChange(page, size) {
    getData({ ...val, pageNum: page, pageSize: size });
  }

  function filterArea(areaID) {
    const matched = area.filter((v) => v.id === areaID);
    const { children } = matched[0];
    return children;
  }

  function handleFilter(arr, t) {
    let obj = { ...val };
    if (t === 'cityCode' || t === 'areaCode') {
      if (t === 'cityCode') {
        if (arr) {
          const childrenArea = filterArea(arr[0]);
          setChildArea(childrenArea);
          obj = { ...obj, regionVo: { cityCode: arr[0] } };
        } else {
          // 全部城市则不需要参数
          setChildArea([]);
          obj = { ...obj, regionVo: {} };
        }
        setVal(obj);
        getData(obj);
      } else if (t === 'areaCode') {
        if (arr) {
          obj = { ...obj, regionVo: { ...obj.regionVo, areaCode: arr[0] } };
        } else {
          // 全部区县则只需要市级参数
          obj = { ...obj, regionVo: { cityCode: obj.regionVo.cityCode } };
        }
        setVal(obj);
        getData(obj);
      }
    } else {
      obj = { ...val, [t]: isUndefined(arr) ? null : arr[0] };
      setVal(obj);
      getData(obj);
    }
  }

  function tableTitle() {
    const { year } = val;

    return (
      <div style={{ fontSize: '20px', fontWeight: 600, textAlign: 'center' }}>
        {isNumber(year) ? `${year}年` : ''}
        现代设施农业建设贷款贴息拟贴息资金情况公示表
      </div>
    );
  }

  const columns = [
    {
      title: '项目名称',
      dataIndex: 'projectName',
      align: 'center',
    },
    {
      title: '申报区域',
      dataIndex: 'cityId',
      render: (text, record) => {
        const str = `${text}.${record.areaId}`;
        return str;
      },
      align: 'center',
    },
    {
      title: '申报主体名称（企业/个人）',
      dataIndex: 'applicantName',
      align: 'center',
    },
    {
      title: '申报年度',
      dataIndex: 'year',
      align: 'center',
    },
    {
      title: '总贴息金额(元)',
      dataIndex: 'discountAmount',
      align: 'center',
    },
    {
      title: '公示单位联系人',
      dataIndex: 'publicationName',
      align: 'center',
    },
    {
      title: '公示单位联系电话',
      dataIndex: 'publicationTel',
      align: 'center',
    },
  ];

  return (
    <div className="bank-product">
      <Spin spinning={loading}>
        <span className="pl-filter-title">筛选条件</span>
        {
          filter && (
            <div className="product-filter">

              <FilterType title="申报年度" titleWidth={88} data={years} radio onChange={(e) => handleFilter(e, 'year')} />
              {
                !isEmpty(data?.area) && (
                  <FilterType title="申报区域" titleWidth={88} data={data?.area} radio onChange={(e) => handleFilter(e, 'cityCode')} />
                )
              }
              {
                !isEmpty(childArea) && (
                  <FilterTypeChild title="" data={childArea} radio onChange={(e) => handleFilter(e, 'areaCode')} />
                )
              }
            </div>
          )
        }
        <span className="pl-list-title">情况公示</span>
        {
          isEmpty(list?.items) ? (<EmptyImg height={300} />) : (
            <div className="product-list">
              <Table
                title={tableTitle}
                columns={columns}
                rowKey="id"
                dataSource={list?.items}
                scroll={{ x: 1000 }}
                pagination={{
                  onChange,
                  current: list?.pageNum,
                  total: list?.totalRows,
                }}
              />
            </div>
          )
        }
      </Spin>
    </div>
  );
}

PublicList.propTypes = {
  filter: bool,
  data: object,
};

PublicList.defaultProps = {
  filter: true,
};
