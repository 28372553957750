import { Tabs } from 'antd';
import { useRequest } from 'ahooks';
import logo from '@/assets/login-logo.png';
import { getPublickey } from '@/services/common';
import Login from './components/Login';
import TelLogin from './components/TelLogin';
import './index.less';

const items = [
  { label: '账号密码登录', key: '1', children: <Login /> },
  { label: '手机号登录', key: '2', children: <TelLogin /> },
];

export default function Index() {
  useRequest(getPublickey, {
    onSuccess(res) {
      sessionStorage.setItem('publicKey', res);
    },
  });
  return (
    <div className="login">
      <div className="login__box">
        <div className="login-bg">
          <img src={logo} alt="" className="login-logo" />
        </div>
        <div className="login__form-box">
          <div className="login__form-title">四川农业信贷直通车</div>
          <Tabs items={items} />
        </div>
      </div>

    </div>
  );
}
