import { Tabs } from 'antd';
import { useRequest } from 'ahooks';
import ProductFilter from '@/components/ProductFilter/ProductFilter';
import PolicyInsurance from '../components/PolicyInsurance';
import FixMenu from '@/components/FixMenu/FixMenu';
import { getArea, getDictType, getInsurance } from '@/services/common';
import { APPLICANT_TYPE } from '@/utils/mappingDict';
import './index.less';

export default function Index() {
  const { data: area = [] } = useRequest(getArea, {
    defaultParams: [true],
  }); // 区域
  const { data: insurance = [] } = useRequest(getInsurance); // 保险

  const { data: dict = {} } = useRequest(getDictType, { // 筛选项字典
    defaultParams: [APPLICANT_TYPE],
  });

  const data = { insurance, area, dict };

  const items = [
    { label: '政策性保险', key: '2', children: <PolicyInsurance /> },
    { label: '其他涉农保险', key: '3', children: <ProductFilter type="bx" data={data} /> },
  ];

  return (
    <div className="bxzn">
      <div className="bxzn__banner">
        保险助农
      </div>
      <div className="content-width">
        <div className="bxzn__title">保险产品</div>
        <Tabs items={items} centered />
      </div>
      <FixMenu />
    </div>
  );
}
