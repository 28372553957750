import { Collapse, Steps } from 'antd';
import { any } from 'prop-types';

function UserLogs({ userLogs }) {
  return (
    <div style={{ marginTop: 20 }}>
      <Collapse defaultActiveKey={['1']}>
        <Collapse.Panel key={1} header="申报进度">
          <Steps current={userLogs?.length} direction="vertical" status="error" size="small">
            {userLogs?.map((item, index) => (
              <Steps.Step
                key={`${index + 1}`}
                title={item.title}
                subTitle={`处理时间：${item.operateTime}`}
                description={item.reasonDetail}
              />
            ))}
          </Steps>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}

export default UserLogs;
UserLogs.propTypes = {
  userLogs: any,
};
