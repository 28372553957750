import { Tabs } from 'antd';
// import { useRequest } from 'ahooks';
// import CommonProblem from '@/components/CommonProblem/CommonProblem';
import FixMenu from '@/components/FixMenu/FixMenu';
// import { getTel } from '@/services/common';
import './index.less';

export default function Index() {
  // const { data } = useRequest(getTel);

  function getInfo() {
    return (
      <div>
        客服电话：(028) 8780 1120(工作日9：00-17：00)
        {/* <span> */}
        {/* {data?.phone?.map((item, index) => ( */}
        {/*   // eslint-disable-next-line react/no-array-index-key */}
        {/*   <span key={index}>{item}</span> */}
        {/* ))} */}
        {/* </span> */}
        {/* {data?.time} */}
        {/* <br /> */}
        {/* 邮箱地址： */}
        {/* <span> */}
        {/*  {data?.email?.map((item, index) => ( */}
        {/*    // eslint-disable-next-line react/no-array-index-key */}
        {/*    <span key={index}>{item}</span> */}
        {/*  ))} */}
        {/* </span> */}
      </div>
    );
  }

  const items = [
    {
      label: '平台介绍',
      key: '1',
      children:
        '信贷直通车平台为深入推进农村金融服务创新，进一步强化对农业经营主体的信贷支撑，通过机制创新、信息共享、数据增信、农担分险，探索形成了“经营主体直报需求、农担公司提供担保、银行信贷支持”的模式，为农业经营主体提供更加便捷有效的信贷服务。',
    },
    { label: '联系方式', key: '2', children: getInfo() },
    // { label: '常见问题', key: '3', children: <CommonProblem /> },
  ];

  return (
    <div className="about">
      <div className="about__banner">
        关于我们
      </div>
      <div className="content-width about__info">
        <Tabs items={items} />
      </div>
      <FixMenu />
    </div>
  );
}
