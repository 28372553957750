import {
  Button, Tooltip,
} from 'antd';
import { object } from 'prop-types';
import success from '@/assets/myApplication/ywc.svg';
import error from '@/assets/myApplication/wtg.svg';
import cancel from '@/assets/myApplication/qx.svg';
import ysq from '@/assets/myApplication/ysq.svg';
import shz from '@/assets/myApplication/shz.svg';
import yqy from '@/assets/myApplication/yqy.svg';
import fkcg from '@/assets/myApplication/fkcg.svg';

const bankObj = {
  1: { name: '银行融资', class: 'tagBlue' },
  3: { name: '担保融资', class: 'tagYellow' },
  2: { name: '保险', class: 'tagMl' },
};

const statusObj = {
  4: fkcg, // 放款成功
  5: error, // 审批拒绝
  9: cancel, // 已取消
  0: ysq, // 已申请
  2: shz, // 审核中
  3: yqy, // 已签约
  10: success, // 还款成功
};

export default function OtherCard(props) {
  const { data, onCancel, onSchedule } = props;
  function getDate(e) {
    const day = e.split(' ');
    return day[0];
  }
  return (
    <div className="myapplication__list-row">
      <div className="myapplication__status">
        <img src={statusObj[data.status]} alt="" width={130} height={35} />
      </div>
      <div className="myapplication__list-row-img">
        <img src={data.url} alt="" width={250} height={75} />
      </div>
      <div className="myapplication__list-row-con">
        <div>
          <div className="myapplication__list-row-name">
            <Tooltip title={data.productName}>
              <span className="myapplication__list-pro-name">{data.productName || '-'}</span>
            </Tooltip>
            <span className={bankObj[data.applicationType]?.class}>{bankObj[data.applicationType]?.name}</span>
          </div>
          <div className="myapplication__list-row-bankname">{data.yh || data.publishOrgName}</div>
        </div>
        {
          data.applicationType === 2 ? (
            <>
              <div className="margin-right20">
                <div>最高保障额度</div>
                <div className="myapplication__list-row-title">
                  {data.insuranceAmount}
                  元
                </div>
              </div>
              <div className="margin-right20">
                <div>保费</div>
                <div className="myapplication__list-row-title">
                  {data.premium}
                  元/
                  {data?.premiumUnit}
                </div>
              </div>
              <div className="margin-right20">
                <div>保险类型</div>
                <div className="myapplication__list-row-title">{data.insuranceType}</div>
              </div>
            </>
          ) : (
            <>
              <div className="margin-right20">
                <div>申请金额</div>
                <div className="myapplication__list-row-title">
                  {data.loanMoney}
                  万元
                </div>
              </div>
              <div className="margin-right20">
                <div>贷款期限</div>
                <div className="myapplication__list-row-title">
                  {data.loanYear}
                  个月
                </div>
              </div>
              <div className="margin-right20">
                <div>资金用途</div>
                <div className="myapplication__list-row-title">{data.loanType}</div>
              </div>
            </>
          )
        }
        <div className="margin-right20">
          <div>申请时间</div>
          <div className="myapplication__list-row-title">{getDate(data.gmtCreate)}</div>
        </div>
      </div>
      <div className="btn-box">
        {
          data.applicationType !== 2 && (
            <>
              <Button type="primary" onClick={onSchedule}>
                进度查询
              </Button>
              {
                data.status < 3 && (
                  <Button type="primary" ghost onClick={onCancel}>
                    取消申请
                  </Button>
                )
              }
            </>
          )
        }
      </div>
    </div>
  );
}

OtherCard.propTypes = {
  data: object,
  onSchedule: () => {},
  onCancel: () => {},
};
