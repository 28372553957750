import { Pagination, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useRequest } from 'ahooks';
import {
  string, bool, object,
} from 'prop-types';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { isEmpty } from 'lodash';
import FilterType from '@/components/FilterType/FilterType';
import ImgFilter from '@/components/ImgFilter/ImgFilter';
import EmptyImg from '@/components/EmptyImg/EmptyImg';
import { getBankProduct, getInsuranceProduct } from '@/services/common';
import BankCard from './BankCard';
import InsuranceCard from './InsuranceCard';
import './ProductFilter.less';

/**
 * 产品列表组件
 * @param {object} data 数据源
 * @param {bool} isShow 是否显示按钮
 * @param {string} type 类型银行产品\保险产品
 * @param {bool} filter 是否显示筛选条件
 * */
export default function ProductFilter({
  data, type, filter,
}) {
  const { search } = useLocation();
  const [list, setList] = useState({});
  const [val, setVal] = useState({});

  const { run, loading } = useRequest(getInsuranceProduct, { // 保险产品
    manual: true,
    onSuccess(res) {
      setList(res);
    },
  });
  const { run: bankRun, loading: bankLoading } = useRequest(getBankProduct, { // 银行产品
    manual: true,
    onSuccess(res) {
      setList(res);
    },
  });

  function getData(e) {
    if (type === 'yh') {
      bankRun(e);
    } else {
      run({ ...e, insuranceType: 3 });
    }
  }

  useEffect(() => {
    let obj = {};
    if (qs.parse(search).id) {
      obj = type === 'yh' ? { publishOrgId: [qs.parse(search).id] } : { insuranceId: [qs.parse(search).id] };
      setVal(obj);
    }
    getData(obj);
  }, [search]);

  function onChange(page, size) {
    getData({ pageNum: page, pageSize: size });
  }

  function handleImgFilter(arr, t) {
    const obj = { ...val, [t]: arr };
    setVal(obj);
    getData(obj);
  }
  function handleFilter(arr, t) {
    const obj = { ...val, [t]: (t === 'areaName' || t === 'applicantType') && arr ? arr[0] : arr };
    setVal(obj);
    getData(obj);
  }
  return (
    <div className="bank-product">
      <Spin spinning={loading || bankLoading}>
        {
                filter && (
                  type === 'yh' ? (
                    <div className="product-filter">
                      <ImgFilter title="银行选择" data={data?.bank} onChange={(e) => handleImgFilter(e, 'publishOrgId')} />
                      <FilterType title="适用对象" data={data?.dict?.applicantType} radio onChange={(e) => handleFilter(e, 'applicantType')} />
                      <FilterType title="贷款额度" data={data?.dict?.loanAmount} onChange={(e) => handleFilter(e, 'loanAmount')} />
                      <FilterType title="贷款期限" data={data?.dict?.gmtStatus} onChange={(e) => handleFilter(e, 'gmtStatus')} />
                    </div>
                  ) : (
                    <div className="product-filter">
                      <ImgFilter title="保险公司" data={data?.insurance} onChange={(e) => handleImgFilter(e, 'insuranceId')} />
                      {
                          !isEmpty(data?.area) && (
                          <FilterType title="区域" data={data?.area} radio onChange={(e) => handleFilter(e, 'areaName')} />
                          )
                      }
                    </div>
                  )

                )
            }
        {
                    isEmpty(list?.items) ? (<EmptyImg height={300} />) : (
                      <div className="product-list">
                        {
                                type === 'bx'
                                  ? (
                                    list?.items?.map((item) => (
                                      <InsuranceCard data={item} key={item.productId} type={type} areaCode={val?.areaName} />
                                    ))
                                  ) : (
                                    list?.items?.map((item) => (
                                      <BankCard data={item} key={item.productId} />
                                    ))
                                  )
                            }
                        <div className="pagination-box">
                          <Pagination
                            showQuickJumper
                            defaultCurrent={list?.pageNum}
                            total={list?.totalRows}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                    )
                }
      </Spin>
    </div>
  );
}

ProductFilter.propTypes = {
  type: string,
  filter: bool,
  data: object,
};

ProductFilter.defaultProps = {
  type: 'yh',
  filter: true,
};
