import JSEncrypt from 'jsencrypt/bin/jsencrypt.min';

// 加密
export default function setEncrypt(msg) {
  // 公钥
  const publicKey = sessionStorage.getItem('publicKey');
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(msg);
}
