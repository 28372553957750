import {
  Spin, Col, Form, Input, Row, Button, message, Cascader,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../index.less';
import { useRequest } from 'ahooks';
import { getPerson, authPerson } from '@/services/user';

const formItemLayout = {
  labelCol: {
    sm: { span: 4 },
  },
  wrapperCol: {
    sm: { span: 18 },
  },
};
/*
* 个人认证
* */
export default function Personal() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const area = useSelector((state) => state.area);

  const { loading } = useRequest(getPerson, {
    onSuccess(res) {
      form.setFieldValue('phone', res?.phone);
    },
  });

  const { run, loading: authLoading } = useRequest((val) => authPerson(val), {
    manual: true,
    onSuccess() {
      dispatch({ type: 'STATUS', payload: '' });
      message.success('认证成功');
      Navigate('/user');
    },
  });

  function onFinish(values) {
    const val = {
      ...values,
      areaCode: values?.provinceCode?.[2],
      cityCode: values?.provinceCode?.[1],
      provinceCode: values?.provinceCode?.[0],
    };
    run(val);
  }

  return (
    <div className="authentication">
      <div className="content-width">
        <div className="authentication-title">认证资料</div>
        <Spin spinning={loading}>
          <Form
            {...formItemLayout}
            form={form}
            name="form"
            className="form"
            onFinish={onFinish}
          >
            <div className="authentication-con">
              <div>
                <div className="authentication-sub-title">个人基本信息</div>
                <Row className="authentication-border-b">
                  <Col span={12}>
                    <Form.Item
                      name="realName"
                      label="姓名"
                      rules={[
                        {
                          required: true,
                          message: '请输入姓名',
                        },
                      ]}
                    >
                      <Input placeholder="请输入姓名" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="idCard"
                      label="身份证号码"
                      rules={[
                        {
                          required: true,
                          message: '请输入身份证号码',
                        },
                        {
                          pattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
                          message: '请输入正确的身份证号码',
                        },
                      ]}
                    >
                      <Input placeholder="请输入身份证号码" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="phone"
                      label="手机号码"
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="详细地址"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          maxLength: 50,
                          message: '请输入50位以内字符',
                        },
                      ]}
                    >
                      <div className="flex">
                        <Form.Item
                          name="provinceCode"
                          rules={[
                            {
                              required: true,
                              message: '请选择区域地址',
                            }]}
                        >
                          <Cascader
                            placeholder="请选择省市区"
                            options={area}
                            changeOnSelect
                            allowClear={false}
                            fieldNames={{ label: 'name', value: 'id', children: 'children' }}
                            style={{ width: 210 }}
                          />
                        </Form.Item>
                        <Form.Item
                          name="adddressDetail"
                          rules={[
                            {
                              required: true,
                              message: '请输入详细地址',
                            }]}
                        >
                          <Input placeholder="请输入详细地址" style={{ width: 262 }} />
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="authentication-footer">
              <div>
                <Button type="primary" htmlType="submit" loading={authLoading}>提交信息</Button>
              </div>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
}
