/* eslint-disable */
/* 金额处理 */
export default function getMoney(num = 0) {
  const AmountUnitList = ['万元', '亿元', '兆', '京', '垓', '杼']; // 金额单位数组
  let strNum = num.toString(); // 将数字金额转为字符串
  let AmountUnit = ''; // 金额单位
  // 循环遍历单位数组
  AmountUnitList.find((item) => {
    let newNum = '';
    // 判断金额是否包含小数点
    if (strNum.indexOf('.') !== -1) {
      // 若有则将小数点前的字符截取出来
      newNum = strNum.substring(0, strNum.indexOf('.'));
    } else {
      // 没有则直接等于原金额
      newNum = strNum;
    }
    // 判断一下经过小数点截取后的金额字符长度是否小于5
    if (newNum.length < 5) {
      // 若小于5则接收当前单位，并跳出迭代
      AmountUnit = item;
      return true;
    }
    // 若不小于5则将经过小数点截取处理过后的字符除以10000后作为下一轮迭代的初始金额重新判断(每一个单位之间相距4位数，故除以10000)
    strNum = (newNum * 1 / 10000).toString();
  });
  const money = { num: 0, unit: '' };
  // 保留2位小数
  money.num = num ? strNum.length >= 5 ? (strNum * 1).toFixed(2) : strNum : 0;
  // 接收单位
  money.unit = AmountUnit;
  return money;
}
