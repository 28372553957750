/**
 * @name: SubjectBasicInfo
 * @description：申报主体基本信息
 * @author: jiangh
 */
import moment from 'moment';
import { any } from 'prop-types';

function SubjectBasicInfo({ subjectBasicInfo }) {
  return (
    <>
      <div className="myapplication-detail__sub-tiny-title">申报主体基本信息</div>
      <div className="manage-detail_basic" style={{ marginBottom: 20 }}>
        <div className="myapplication-detail__grid">
          <div>
            单位名称/姓名：
            {subjectBasicInfo.unitName}
          </div>
          <div>
            统一社会信用代码/身份证号码：
            {subjectBasicInfo.identityCode}
          </div>
          <div>
            申报主体类型：
            {subjectBasicInfo.unitForm}
          </div>
          <div>
            注册地点/户籍所在地：
            {subjectBasicInfo.registrationLocationForShow}
          </div>
          <div>
            注册时间：
            {subjectBasicInfo.registrationTime && moment(subjectBasicInfo.registrationTime).format('YYYY-MM-DD')}
          </div>
          <div>
            注册资本（万元）：
            {subjectBasicInfo.registeredCapital}
          </div>
          <div>
            产业类型：
            {subjectBasicInfo.industrySector}
          </div>
          <div>
            所属市（州）：
            {subjectBasicInfo.cityOrStateForShow}
          </div>
          <div>
            经营范围：
            {subjectBasicInfo.businessScope}
          </div>
          <div>
            主要产品：
            {subjectBasicInfo.mainProducts}
          </div>
          <div>
            法人代表：
            {subjectBasicInfo.legalRepresentative}
          </div>
          <div>
            联系人：
            {subjectBasicInfo.contactPerson}
          </div>
          <div>
            联系电话：
            {subjectBasicInfo.contactPhone}
          </div>
          <div>
            联系地址：
            {subjectBasicInfo.contactAddress}
          </div>
          <div>
            开户银行：
            {subjectBasicInfo.bankName}
          </div>
          <div>
            银行卡账号：
            {subjectBasicInfo.bankAccountNumber}
          </div>
          <div>
            传 真：
            {subjectBasicInfo.faxNumber}
          </div>
          <div>
            邮政编码：
            {subjectBasicInfo.postalCode}
          </div>
          <div className="colmun-2">
            备注：
            {subjectBasicInfo.remark}
          </div>
        </div>
      </div>
    </>
  );
}

export default SubjectBasicInfo;

SubjectBasicInfo.propTypes = {
  subjectBasicInfo: any,
};
