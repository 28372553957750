import { Form, Input, Button } from 'antd';
import { func, bool } from 'prop-types';
import { useState } from 'react';
import { useRequest } from 'ahooks';
import CodeInput from '@/components/CodeInput/CodeInput';
import CustomFooter from '@/components/CustomFooter/CustomFooter';
import { getSmsCode } from '@/services/login';

export default function ForgetPass({
  onOk, onCancel, confirmLoading,
}) {
  const [form] = Form.useForm();
  const [isCurrentTel, setIsCurrentTel] = useState(true);
  const [phone, setPhone] = useState('');

  const { run, loading } = useRequest(getSmsCode, { // 校验验证码
    manual: true,
    onSuccess() {
      form.resetFields();
      setIsCurrentTel(false);
    },
  });

  function onFinish() {
    form.validateFields().then((values) => {
      if (isCurrentTel) {
        run({ code: values.code });
      } else {
        onOk(values);
      }
    });
  }

  function compareToFirstPassword(rule, value, callback) {
    if (value !== form.getFieldValue('newPass')) {
      callback('新密码不一致');
    } else if (value === undefined) {
      callback(rule.message);
    } else {
      callback();
    }
  }

  function compareOldPhone(rule, value, callback) {
    if (value === undefined) {
      callback('请输入新手机号');
    } else {
      setPhone(value);
      callback();
    }
  }

  return (
    <div className="update-tel-form">
      <Form
        form={form}
        name="form"
      >
        {
          isCurrentTel ? (
            <>
              <Form.Item
                name="account"
                rules={[
                  {
                    required: true,
                  },
                  { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号码格式' },
                  { validator: compareOldPhone },
                ]}
              >
                <Input placeholder="请输入注册手机号码" />
              </Form.Item>
              <Form.Item>
                <CodeInput phone={phone} />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label="新密码"
                name="newPass"
                rules={[
                  { required: true, message: '请输入新密码' },
                  {
                    // eslint-disable-next-line max-len
                    pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{8,16}$/,
                    message: '密码必须为8~16位非空字符,并包含大写字母、小写字母、数字、特殊字符中的3种',
                  },
                ]}
              >
                <Input.Password placeholder="请输入新密码" />
              </Form.Item>
              <Form.Item
                label="新密码确认"
                name="newPassRepeat"
                rules={[
                  { required: true, message: '请再次输入新密码' },
                  { validator: compareToFirstPassword },
                ]}
              >
                <Input.Password placeholder="请再次输入新密码" />
              </Form.Item>
            </>

          )
        }
      </Form>
      {
        isCurrentTel ? (
          <div className="modal-footer-btn">
            <Button type="primary" onClick={onFinish} loading={loading}>下一步</Button>
          </div>
        ) : (
          <CustomFooter onOk={onFinish} onCancel={onCancel} confirmLoading={confirmLoading} />
        )
      }
    </div>
  );
}

ForgetPass.propTypes = {
  onOk: func,
  onCancel: func,
  confirmLoading: bool,
};
