// import { useState } from 'react';
import {
  Spin, Table,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useRequest } from 'ahooks';
import { isNull } from 'lodash';
import { getCreditGuaranteeApplicationDetail, attchmentFileDownload } from '@/services/creditGuarantee';
import './MajorProjectTimeline.less';
import AttachmentDownload from '@/components/AttachmentDownload/AttachmentDownload';
import { downloadFile } from '@/utils/utils';
import { getDictType } from '@/services/common';
import { COMPANY_FUND_TYPE, GUARANTEE_LEVEL } from '@/utils/mappingDict';

const obj = {
  0: 'processing', 2: 'processing', 3: 'success', 4: 'success', 5: 'error', 9: 'cancel', // status 为0正常状态，进行中UI为实心数字； 1 拒绝； 2取消； 3：所有流程完成
};

export default function CreditGuaranteeTimeline() {
  const { search } = useLocation();
  const { id } = qs.parse(search);

  const { data: dict = {} } = useRequest(getDictType, { // 筛选项字典
    defaultParams: [[GUARANTEE_LEVEL, COMPANY_FUND_TYPE].join(',')],
  });

  const { data = {}, loading } = useRequest(() => getCreditGuaranteeApplicationDetail(id), {});

  const { loading: downloadLoading, run: downloadRun } = useRequest(attchmentFileDownload, {
    manual: true,
    onSuccess: ({ data: fileData, filename }) => {
      downloadFile(fileData, filename);
    },
  });

  function getIcon(status, index) {
    if (status === 0 || status === 2) {
      return index;
    } if (status === 5 || status === 9) {
      return <CloseOutlined />;
    }
    return <CheckOutlined />;
  }

  const columns = [
    {
      title: '担保对象',
      dataIndex: 'guaranteeObject',
    },
    {
      title: '分类',
      dataIndex: 'guaranteeSort',
    },
    {
      title: '担保合同号',
      dataIndex: 'guaranteeIou',
    },
    {
      title: '担保类别',
      dataIndex: 'guaranteeType',
    },
    {
      title: '担保起止时间',
      dataIndex: 'guaranteeStart',
      render: (text, record) => {
        const { guaranteeEnd } = record;
        return `${text}~${guaranteeEnd}`;
      },
    },
    {
      title: '协作金融机构名称',
      dataIndex: 'collaboratingFinancial',
    },
    {
      title: '担保责任额（万元）',
      dataIndex: 'guaranteeLiabilityQuota',
    },
    {
      title: '当年新发生额（万元）',
      dataIndex: 'newAmount',
    },
    {
      title: '证明材料',
      dataIndex: 'guaranteeBonusLoanFileVos',
      render: (file) => {
        const { id: fileID, fileName } = file ?? {};
        return <AttachmentDownload key={fileID} attachmentId={fileID} attachmentName={fileName} downloadFunc={() => { downloadRun(fileID, true); }} downloadLoading={downloadLoading} />;
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      <div>
        <div className="myapplication-detail__title">进度查询</div>
        <div className="myapplication-detail__sub-title">基本信息</div>
        <div className="myapplication-detail__grid">
          <div>
            农业担保公司名称：
            {data?.guaranteeName}
          </div>
          <div>
            级别：
            {dict?.guaranteeLevel?.find((e) => e.val === `${data?.guaranteeLevel}`)?.name ?? '-'}
          </div>
          <div>
            注册地点：
            {data?.cityCode}
            -
            {data?.areaCode}
          </div>
          <div>
            申报年度：
            {data?.year}
          </div>
          <div>
            注册登记时间：
            {data?.registrationTime}
          </div>
          <div>
            设立方式：
            {dict?.establishmentType?.find((e) => e.val === `${data?.establishmentType}`)?.name ?? '-'}
          </div>
          <div>
            从业人员：
            {data?.practitioner}
          </div>
        </div>

        <div className="myapplication-detail__sub-tiny-title">注册资本金情况</div>
        <div className="myapplication-detail__grid">

          <div>
            国有资本（万元）：
            {data?.stateCapitalQuota}
          </div>
          <div>
            其中粮食适度规模经营补助资金（万元）：
            {data?.scaleGrainQuota}
          </div>
          <div>
            其他资本（万元）：
            {data?.otherCapitalQuota}
          </div>
          <div>
            合计（万元）：
            {data?.totalQuota}
          </div>
        </div>

        <div className="myapplication-detail__sub-tiny-title">贷款担保情况</div>
        <div className="myapplication-detail__grid">
          <div>
            2023年贷款担保新发生额（万元）：
            {data?.guaranteeQuota}
          </div>
          <div>
            其中农业贷款担保新发生额（万元）：
            {data?.agriculturalGuaranteeQuota}
          </div>
          <div>
            2023年底在保余额（万元）：
            {data?.warrantyQuota}
          </div>
          <div>
            其中农业贷款担保在保余额（万元）：
            {data?.agriculturalWarrantyQuota}
          </div>
          <div>
            2022年担保代偿额（万元）：
            {data?.guaranteeCompensationQuota}
          </div>
          <div />
        </div>
        <div style={{ marginTop: 10, wordWrap: 'break-word', wordBreak: 'break-word' }}>
          备注：
          {data?.remark}
        </div>

        <div className="myapplication-detail__sub-title">2023年新增担保信息</div>
        <Table
          dataSource={data?.guaranteeBonusLoanVos}
          columns={columns}
          rowKey="guaranteeIou"
          pagination={{
            showSizeChanger: true, // 允许用户改变每页显示条数
            showQuickJumper: true, // 允许用户快速跳转至某页
          }}
        />

        <div className="myapplication-detail__grid" style={{ marginTop: 20, gridTemplateColumns: 'none' }}>
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <span style={{ width: 240 }}>营业执照（法人代表证、设立批文）：</span>
            {data?.licenseFileVos?.map((file) => (
              <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <span style={{ width: 240 }}>2023年公司贷款担保发生总额，其中农业贷款担保发生额度：</span>
            {data?.agriculturalFileVos?.map((file) => (
              <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <span style={{ width: 240 }}>2023年担保业务台账，并加盖公章：</span>
            {data?.openingPermitFileVos?.map((file) => (
              <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <span style={{ width: 240 }}>2023年农业信贷担保机构财务报告：</span>
            {data?.report23FileVos?.map((file) => (
              <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <span style={{ width: 240 }}>2022年农业信贷担保机构财务报告：</span>
            {data?.report22FileVos?.map((file) => (
              <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <span style={{ width: 240 }}>承诺书：</span>
            {data?.commitmentFileVos?.map((file) => (
              <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'left' }}>
            <span style={{ width: 240 }}>其他材料：</span>
            {data?.otherFileVos?.map((file) => (
              <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
            ))}
          </div>
        </div>

        <div className="myapplication-detail__sub-title">进度信息</div>
        <div className="major-project-timeline">
          {
            data?.userLogs?.map((item, index) => (
              <div className={`timeline-item timeline-item-${index + 1 === data?.userLogs.length && obj?.[item.status]}`}>
                <div className="timeline-icon">
                  {
                    index + 1 === data?.userLogs.length
                      ? (
                        <>
                          {getIcon(item.status, index + 1)}
                        </>
                      ) : <CheckOutlined />
                  }
                </div>
                <div className="timeline-cont">
                  <div className="timeline-title">{item.title}</div>
                  <div>{item.operateTime}</div>
                  <div>
                    {!isNull(item.reasonDetail) ? '不通过原因：' : null}
                    {item.reasonDetail}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Spin>
  );
}
