import request from '@/utils/request';

/*
* 首页 模块
* */

// 统计数据显示（根据定位显示）
export function getStatistical(areaName) {
  return request({
    url: '/basic/query/statistical',
    method: 'GET',
    params: { areaName },
  });
}

// 查询合作的农担公司
export function getGuarantee() {
  return request({
    url: '/insurance/info/cooperation/guarantee',
    method: 'GET',
  });
}

// 银行id查询银行介绍信息
export function getBankDetail(id) {
  return request({
    url: `/bank/financing/bankinfo/${id}`,
    method: 'GET',
  });
}

// 查询保险介绍信息
export function getInsuranceDetail(id) {
  return request({
    url: `/insurance/info/query/insuranceinfo/${id}`,
    method: 'GET',
  });
}
