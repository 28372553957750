import { useEffect, useState } from 'react';
import {
  Form, DatePicker, InputNumber, Select,
  Row, Col, Input, Upload, Button,
  message,
} from 'antd';
import { isNull, isUndefined } from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import {
  bool, number, func, any,
} from 'prop-types';
import {
  uploadFileUrl,
} from '@/services/majorFinancing';
import { getFileList } from '../Application/fileDataUtil';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

export default function FormList({
  initialData, formKey, disable, loanType, amountChanged,
}) {
  const [facilityLoanFiles, setFacilityLoanFiles] = useState([]);
  const [loanContractFiles, setLoanContractFiles] = useState([]);
  const [fundsWithdrawalFiles, setFundsWithdrawalFiles] = useState([]);
  const [loanFundsFiles, setLoanFundsFiles] = useState([]);
  const [bankInterestFiles, setBankInterestFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);

  const props = {
    name: 'file',
    accept: '.rar,.zip,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.pdf',
    action: uploadFileUrl,
    headers: {
      authorization: 'authorization-text',
    },
    disabled: disable,
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        message.error('请上传200M以下文件!');
      }
      return isLt2M;
    },
    // onChange({ file, fileList }) {
    //   setList(fileList);
    //   if (file.status === 'done') {
    //     message.success('上传成功');
    //   } else if (file.status === 'error') {
    //     message.error('上传失败');
    //   }
    // },
  };

  useEffect(() => {
    const { oneData } = initialData;
    if (oneData) {
      const {
        facilityLoanFileVos,
        loanContractFileVos,
        fundsWithdrawalFileVos,
        loanFundsFileVos,
        bankInterestFileVos,
        otherFileVos,
      } = oneData;
      setFacilityLoanFiles(getFileList(facilityLoanFileVos));
      setLoanContractFiles(getFileList(loanContractFileVos));
      setFundsWithdrawalFiles(getFileList(fundsWithdrawalFileVos));
      setLoanFundsFiles(getFileList(loanFundsFileVos));
      setBankInterestFiles(getFileList(bankInterestFileVos));
      setOtherFiles(getFileList(otherFileVos));
    }
  }, [initialData]);

  function checkFileUploading(_, value) {
    const promise = Promise;

    if (!isUndefined(value) && !isNull(value)) {
      const { event } = value;
      if (!isUndefined(event) && event.type === 'progress') {
        return promise.reject('请等待文件上传完成');
      }
    }

    return promise.resolve();
  }

  return (
    <Row>
      <Col span={12}>
        <Form.Item
          label="贷款银行"
          name={`lendingBank_${formKey}`}
          rules={[
            { required: true, message: '请输入贷款银行!' },
            { pattern: /^\s*\S.*$/, message: '请输入贷款银行!' },
          ]}
        >
          <Input disabled={disable} placeholder="请输入贷款银行" style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="贷款期间" name={`lendingStart_${formKey}`} rules={[{ required: true, message: '请输入贷款期间!' }]}>
          <RangePicker disabled={disable} format={dateFormat} style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="贷款金额"
          name={`loanAmount_${formKey}`}
          rules={[
            { required: true, message: '请输入贷款金额!' },
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('贷款金额必须大于0'))),
            },
          ]}
        >
          <InputNumber
            min={0}
            max={999999999999999}
            placeholder="请输入贷款金额"
            disabled={disable}
            addonAfter="万元"
            style={{ width: '100%' }}
            onChange={amountChanged}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="贷款利率" name={`loanRate_${formKey}`} rules={[{ required: true, message: '请输入贷款利率!' }]}>
          <InputNumber
            min={0}
            max={999999999999999}
            placeholder="请输入贷款利率"
            disabled={disable}
            addonAfter="%"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="贷款类型" name={`loanType_${formKey}`} rules={[{ required: true, message: '请选择贷款类型!' }]}>
          <Select placeholder="请选择贷款类型" disabled={disable} style={{ width: '100%' }}>
            {
              loanType.facilityLoanType?.map((item) => (
                <Select.Option value={item.val} key={item.val}>
                  {item.name}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          name={`facilityLoanFileVos_${formKey}`}
          label={(
            <div>
              贷款已获得其他各级财政资金贴息情况
              <div style={{ fontSize: 10, color: '#a7a7a7' }}>（包括进入公示期尚未收到贴息资金的）</div>
            </div>
          )}
          rules={[
            {
              required: true,
              message: '请上传贷款已获得其他各级财政资金贴息情况',
            },
            {
              validator: checkFileUploading,
            },
          ]}
        >
          <Upload
            {...props}
            fileList={facilityLoanFiles}
            onPreview={() => { }}
            onChange={
              ({ file, fileList }) => {
                setFacilityLoanFiles(fileList);
                if (file.status === 'done') {
                  message.success('上传成功');
                } else if (file.status === 'error') {
                  message.error('上传失败');
                }
              }
            }
          >
            <Button icon={<UploadOutlined />}>上传文件</Button>
          </Upload>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          name={`loanContractFileVos_${formKey}`}
          label="贷款合同扫描件"
          rules={[
            {
              required: true,
              message: '请上传贷款合同扫描件',
            },
            {
              validator: checkFileUploading,
            },
          ]}
        >
          <Upload
            {...props}
            fileList={loanContractFiles}
            onPreview={() => { }}
            onChange={
              ({ file, fileList }) => {
                setLoanContractFiles(fileList);
                if (file.status === 'done') {
                  message.success('上传成功');
                } else if (file.status === 'error') {
                  message.error('上传失败');
                }
              }
            }
          >
            <Button icon={<UploadOutlined />}>上传文件</Button>
          </Upload>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          name={`fundsWithdrawalFileVos_${formKey}`}
          label="贷款资金提款清单扫描件"
          rules={[
            {
              required: true,
              message: '请上传贷款资金提款清单扫描件',
            },
            {
              validator: checkFileUploading,
            },
          ]}
        >
          <Upload
            {...props}
            fileList={fundsWithdrawalFiles}
            onPreview={() => { }}
            onChange={
              ({ file, fileList }) => {
                setFundsWithdrawalFiles(fileList);
                if (file.status === 'done') {
                  message.success('上传成功');
                } else if (file.status === 'error') {
                  message.error('上传失败');
                }
              }
            }
          >
            <Button icon={<UploadOutlined />}>上传文件</Button>
          </Upload>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          name={`loanFundsFileVos_${formKey}`}
          label="贷款资金使用情况说明"
          rules={[
            {
              required: true,
              message: '请上传贷款资金使用情况说明',
            },
            {
              validator: checkFileUploading,
            },
          ]}
        >
          <Upload
            {...props}
            fileList={loanFundsFiles}
            onPreview={() => { }}
            onChange={
              ({ file, fileList }) => {
                setLoanFundsFiles(fileList);
                if (file.status === 'done') {
                  message.success('上传成功');
                } else if (file.status === 'error') {
                  message.error('上传失败');
                }
              }
            }
          >
            <Button icon={<UploadOutlined />}>上传文件</Button>
          </Upload>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          name={`bankInterestFileVos_${formKey}`}
          label="支付银行利息汇总表及利息结算清单"
          rules={[
            {
              required: true,
              message: '请上传支付银行利息汇总表及利息结算清单',
            },
            {
              validator: checkFileUploading,
            },
          ]}
        >
          <Upload
            {...props}
            fileList={bankInterestFiles}
            onPreview={() => { }}
            onChange={
              ({ file, fileList }) => {
                setBankInterestFiles(fileList);
                if (file.status === 'done') {
                  message.success('上传成功');
                } else if (file.status === 'error') {
                  message.error('上传失败');
                }
              }
            }
          >
            <Button icon={<UploadOutlined />}>上传文件</Button>
          </Upload>
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          name={`otherFileVos_${formKey}`}
          label="其他材料"
          rules={[
            {
              validator: checkFileUploading,
            },
          ]}
        >
          <Upload
            {...props}
            fileList={otherFiles}
            onPreview={() => { }}
            onChange={
              ({ file, fileList }) => {
                setOtherFiles(fileList);
                if (file.status === 'done') {
                  message.success('上传成功');
                } else if (file.status === 'error') {
                  message.error('上传失败');
                }
              }
            }
          >
            <Button icon={<UploadOutlined />}>上传文件</Button>
          </Upload>
        </Form.Item>
      </Col>
    </Row>
  );
}

FormList.propTypes = {
  initialData: any,
  formKey: number,
  disable: bool,
  loanType: any,
  amountChanged: func,
};

FormList.defaultProps = {
  initialData: {},
  formKey: 1,
  disable: false,
  loanType: {},
  amountChanged: () => { },
};
