import {
  Card, Collapse, Row, Col, Form, Input, Select, Cascader, InputNumber, Tooltip,
} from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { any } from 'prop-types';
import { useEffect } from 'react';

const { Panel } = Collapse;
function ProjectBasicInfo({ formRef, area }) {
  const selfFundingWatch = Form.useWatch('projectBasicInfo', formRef);
  /**
   * 计算贷款总金额
   */
  function computeTotalAmount() {
    const projectbaseInfo = formRef.getFieldValue('projectBasicInfo');
    const totalAmount = (+projectbaseInfo.selfFunding || 0) + (+projectbaseInfo.otherFunding || 0) + (+projectbaseInfo.loanAmount || 0);
    formRef.setFieldsValue({
      projectBasicInfo: {
        ...projectbaseInfo,
        totalAmount: totalAmount.toFixed(4),
      },
    });
  }
  useEffect(() => {
    computeTotalAmount();
  }, [selfFundingWatch]);
  return (
    <Card title="项目信息">
      <Collapse defaultActiveKey={['1']}>
        <Panel key="1" header="项目基本信息">
          <Row>
            <Col span={12}>
              <Form.Item
                label="项目名称"
                name={['projectBasicInfo', 'projectName']}
                required
                rules={[
                  {
                    required: true,
                    message: '请输入项目名称',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="项目单位"
                name={['projectBasicInfo', 'projectUnit']}
                required
                rules={[
                  {
                    required: true,
                    message: '请输入项目单位',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="申报年度"
                name={['projectBasicInfo', 'declarationYear']}
                required

              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="项目建设性质"
                name={['projectBasicInfo', 'projectNature']}
                required
                rules={[
                  {
                    required: true,
                    message: '请输入项目建设性质',
                  },
                ]}
              >
                <Select placeholder="请输入项目建设性质">
                  <Select.Option value="新建">新建</Select.Option>
                  <Select.Option value="扩建">扩建</Select.Option>
                  <Select.Option value="改建">改建</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={['projectBasicInfo', 'projectRegion']}
                style={{ display: 'none' }}
              />
              <Form.Item
                name={['projectBasicInfo', 'projectRegion_i']}
                label="项目建设区域"
                rules={[
                  {
                    required: true,
                    message: '请选择项目建设区域',
                  },
                ]}
              >
                <Cascader
                  options={area}
                  placeholder="请选择项目建设区域"
                  fieldNames={{ label: 'name', value: 'id', children: 'children' }}
                  onChange={
                    (value) => {
                      // 赋值给后端字段
                      formRef.setFieldsValue({
                        projectBasicInfo: {
                          projectRegion: value.join(','),
                        },
                      });
                    }
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="项目建设周期"
                name={['projectBasicInfo', 'projectConstructionPeriod']}
                rules={[
                  {
                    required: true,
                    message: '请输入项目建设周期',
                  },
                ]}
              >
                <InputNumber addonAfter="年" placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                required
                label="项目建设地址"
                name={['projectBasicInfo', 'projectConstructionAddress']}
                labelCol={{
                  sm: { span: 4 },
                }}
                wrapperCol={{
                  sm: { span: 20 },
                }}
                rules={[
                  {
                    required: true,
                    message: '请输入项目建设地址',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="自筹资金"
                name={['projectBasicInfo', 'selfFunding']}
                rules={[
                  {
                    required: true,
                    message: '请输入自筹资金',
                  },
                ]}
              >
                <InputNumber addonAfter="万元" placeholder="如果无，请填写0" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="其他资金"
                name={['projectBasicInfo', 'otherFunding']}
                rules={[
                  {
                    required: true,
                    message: '请输入其他资金',
                  },
                ]}
              >
                <InputNumber addonAfter="万元" placeholder="如果无，请填写0" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="贷款金额"
                name={['projectBasicInfo', 'loanAmount']}
                rules={[
                  {
                    required: true,
                    message: '请输入贷款金额',
                  },
                  {
                    validator: (_, value) => {
                      if (value <= 0) {
                        return Promise.reject(new Error('贷款金额必须大于0'));
                      }
                      return Promise.resolve();
                    },
                  },

                ]}
              >
                <InputNumber
                  addonAfter="万元"
                  placeholder="请输入贷款金额"
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="合计金额"
                name={['projectBasicInfo', 'totalAmount']}
                rules={[
                  {
                    required: true,
                    message: '请输入合计金额',
                  },
                ]}
              >
                <InputNumber
                  disabled
                  addonAfter={(
                    <>
                      万元
                      <Tooltip
                        title="合计金额 = 自筹资金 + 银行贷款 + 其他资金"
                        overlayInnerStyle={{ width: 300 }}
                      >
                        <QuestionCircleOutlined />
                        计算说明
                      </Tooltip>
                    </>
                  )}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="达产后年销售收入"
                name={['projectBasicInfo', 'annualSalesRevenue']}
                rules={[
                  {
                    required: true,
                    message: '请输入达产后年销售收入',
                  },
                ]}
              >
                <InputNumber addonAfter="万元" placeholder="请输入" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="达产后年创利税"
                name={['projectBasicInfo', 'annualProfitTaxAfterProduction']}
                rules={[
                  {
                    required: true,
                    message: '请输入达产后年创利税',
                  },
                ]}
              >
                <InputNumber addonAfter="万元" placeholder="请输入" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="带动农户户数"
                name={['projectBasicInfo', 'numberofHouseholdsBenefited']}
                rules={[
                  {
                    required: true,
                    message: '请输入带动农户户数',
                  },
                ]}
              >
                <InputNumber addonAfter="户" placeholder="请输入" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="其中：脱贫户户数"
                name={['projectBasicInfo', 'impoverishedHouseholdsBenefited']}
                rules={[
                  {
                    required: true,
                    message: '请输入其中：脱贫户户数',
                  },
                ]}
              >
                <InputNumber addonAfter="户" placeholder="请输入" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="带动农户人数"
                name={['projectBasicInfo', 'householdAffected']}
                rules={[
                  {
                    required: true,
                    message: '请输入带动农户人数',
                  },
                ]}
              >
                <InputNumber addonAfter="人" placeholder="请输入" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="其中：脱贫户人数"
                name={['projectBasicInfo', 'impoverishedHouseholdAffected']}
                rules={[
                  {
                    required: true,
                    message: '请输入其中：脱贫户人数',
                  },
                ]}
              >
                <InputNumber addonAfter="人" placeholder="请输入" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="带动农户户均增收"
                name={['projectBasicInfo', 'avgIncomePerHousehold']}
                rules={[
                  {
                    required: true,
                    message: '请输入带动农户户均增收',
                  },
                ]}
              >
                <InputNumber addonAfter="元" placeholder="请输入" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                required
                label="带动农户总增收"
                name={['projectBasicInfo', 'totalIncomeIncrease']}
                rules={[
                  {
                    required: true,
                    message: '请输入带动农户总增收',
                  },
                ]}
              >
                <InputNumber addonAfter="万元" placeholder="请输入" style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                required
                label="主要建设内容和规模"
                name={['projectBasicInfo', 'constructionContentAndScale']}
                labelCol={{
                  sm: { span: 4 },
                }}
                wrapperCol={{
                  sm: { span: 20 },
                }}
                rules={[
                  {
                    required: true,
                    message: '请输入主要建设内容和规模',
                  },
                ]}
              >
                <Input.TextArea maxLength={200} showCount placeholder="请输入主要建设内容和规模" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                required
                name={['projectBasicInfo', 'businessModel']}
                label={(
                  <div style={{ lineHeight: '18px', textAlign: 'center' }}>
                    经营模式
                    <br />
                    （与农民的利益联结方式）
                  </div>
                )}
                labelCol={{
                  sm: { span: 4 },
                }}
                wrapperCol={{
                  sm: { span: 20 },
                }}
                rules={[
                  {
                    required: true,
                    message: '请输入经营模式',
                  },
                ]}
              >
                <Input.TextArea maxLength={200} showCount placeholder="请输入" />
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </Card>
  );
}
ProjectBasicInfo.propTypes = {
  formRef: any,
  area: any,

};
export default ProjectBasicInfo;
