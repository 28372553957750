import request from '@/utils/request';

/*
* 银行直贷 模块
* */

// 银行产品信息
export function getBankDetail(id) {
  return request({
    url: `/bank/query/financing/bank/${id}`,
    method: 'GET',
  });
}
