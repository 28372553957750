import './ScrollTable.less';
import { array } from 'prop-types';
import { useEffect, useRef } from 'react';

export default function ScrollTable({ data }) {
  const animationDivRef = useRef(null);
  const animationFunc = () => {
    animationDivRef.current?.style.setProperty('transform', 'translateY(0)');
  };
  useEffect(() => {
    if (data.length > 5) {
      animationDivRef.current?.style.setProperty('animation-duration', `${data.length * 2}s`);
      animationDivRef.current?.addEventListener('animationiteration', animationFunc);
    }
    return () => {
      animationDivRef.current?.removeEventListener('animationiteration', animationFunc);
    };
  }, [data]);
  return (
    <div className="scroll-table">
      <div className="animation-carrier" ref={animationDivRef}>
        {
          data?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="scroll-table__row">
              <div>{item.projectName}</div>
              <div>{item.areaName}</div>
              <div>
                {item.amount}
                万元
              </div>
            </div>
          ))
        }
        {
          data.length > 5 && data?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="scroll-table__row">
              <div>{item.projectName}</div>
              <div>{item.areaName}</div>
              <div>
                {item.amount}
                万元
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

ScrollTable.propTypes = {
  data: array,
};

ScrollTable.defaultProps = {
  data: [],
};
