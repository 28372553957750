function replaceHostname() {
  const { protocol, host } = window.location;
  const env = process.env.NODE_ENV;
  if (env === 'development') {
    return process.env.REACT_APP_BASE_URL;
  }
  return `${protocol}//${host}/scexpress`;
}

// 后端接口地址的根路径
export const apiBase = replaceHostname();
