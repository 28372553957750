import { useState } from 'react';
import { Spin } from 'antd';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useRequest } from 'ahooks';
import { getfacilityLoanApplicationDetail } from '@/services/industryBankLoan';
import '../MajorProjectTimeline.less';
import ProjectBasicInfo from './componments/ProjectBasicInfo';
import SubjectBasicInfo from './componments/SubjectBasicInfo';
import AnnualInformation from './componments/AnnualInformation';
import LoanDetailsVos from './componments/LoanDetailsVos';
import Documentation from './componments/Documentation';
import UserLogs from './componments/UserLogs';

export default function IndustryBankLoanTimeline() {
  const { search } = useLocation();
  const { id } = qs.parse(search);

  // 项目信息
  const [projectBasicInfo, setProjectBasicInfo] = useState({});
  // 贷款信息
  const [loanDetailsVos, setLoanDetailsVos] = useState([]);
  const [subjectBasicInfo, setSubjectBasicInfo] = useState({});
  const [annualInformation, setAnnualInformation] = useState([]);
  const [documentation, setDocumentation] = useState({});
  const [userLogs, setUserLogs] = useState([]);
  const { loading } = useRequest(() => getfacilityLoanApplicationDetail(id), {
    onSuccess: (res) => {
      setProjectBasicInfo(res?.projectBasicInfo);
      setLoanDetailsVos(res?.loanDetailsVos);
      setSubjectBasicInfo(res?.subjectBasicInfo);
      setAnnualInformation(res?.annualInformation);
      setDocumentation(res?.documentation);
      setUserLogs(res?.userLogs);
    },
  });

  return (
    <Spin spinning={loading}>
      <ProjectBasicInfo projectBasicInfo={projectBasicInfo} />
       <SubjectBasicInfo subjectBasicInfo={subjectBasicInfo} />
       <AnnualInformation annualInformation={annualInformation} />
       <LoanDetailsVos loanDetailsVos={loanDetailsVos} subjectBasicInfo={subjectBasicInfo} />
       <Documentation documentation={documentation} />
       <UserLogs userLogs={userLogs} />
    </Spin>
  );
}
