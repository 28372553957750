/**
 * @name: LoanDetailsVos
 * @description：贷款信息
 * @author: jiangh
 */
import moment from 'moment/moment';
import { Collapse, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { any } from 'prop-types';
import { useRequest } from 'ahooks';
import { loanFileDownload, loanFileDownloadWithURL } from '@/services/industryBankLoan';
import { downloadFile } from '@/utils/utils';
import LoanFileDownload from '@/components/AttachmentDownload/loanFileDownload';

function LoanDetailsVos({ loanDetailsVos, subjectBasicInfo }) {
  const dataFormat = 'YYYY-MM-DD';

  /**
   * 获取贷款总金额
   */
  function getLoanAmountAll() {
    const loanAmountAll = loanDetailsVos?.reduce((pre, cur) => {
      if (cur.loanAmount) {
        return pre + Number(cur.loanAmount);
      }
      return pre;
    }, 0);
    return (loanAmountAll)?.toFixed(4);
  }

  const { loading: downloadLoading, run: downloadRun } = useRequest(loanFileDownload, {
    manual: true,
    onSuccess: ({ data: fileData, filename }) => {
      downloadFile(fileData, filename);
    },
  });

  const { loading: downloadUrlLoading, run: downloadUrlRun } = useRequest(loanFileDownloadWithURL, {
    manual: true,
    onSuccess: ({ data, fileName }) => {
      downloadFile(data, fileName);
    },
  });

  // const financialDocumentsListMap = (loan) => {
  //   const { financialDocuments, financialDocumentsFileName } = loan;
  //   // MARK 由于入力端没有限制 支付银行利息汇总表及利息结算清单 的上传数量
  //   // 且已有用户已经上传了多个文件，所以此处按照可以上传的文件格式，按照文件结尾进行分割
  //   const fileTypes = ['.rar', '.zip', '.doc', '.docx', '.xls', '.xlsx', '.jpg', '.jpeg', '.png', '.pdf'];

  //   const mappedFile = [];
  //   let urlIndex = 0;
  //   let nameIndex = 0;

  //   while (urlIndex < financialDocuments.length && nameIndex < financialDocumentsFileName.length) {
  //     let nextUrlIndex = -1;
  //     let nextNameIndex = -1;

  //     // eslint-disable-next-line no-restricted-syntax
  //     for (const type of fileTypes) {
  //       const urlPos = financialDocuments.indexOf(type, urlIndex);
  //       const namePos = financialDocumentsFileName.indexOf(type, nameIndex);

  //       if (urlPos !== -1 && (nextUrlIndex === -1 || urlPos < nextUrlIndex)) {
  //         nextUrlIndex = urlPos + type.length;
  //       }
  //       if (namePos !== -1 && (nextNameIndex === -1 || namePos < nextNameIndex)) {
  //         nextNameIndex = namePos + type.length;
  //       }
  //     }

  //     const oneUrl = financialDocuments.substring(urlIndex, nextUrlIndex);
  //     const oneName = financialDocumentsFileName.substring(nameIndex, nextNameIndex);

  //     mappedFile.push({ oneUrl, oneName });

  //     urlIndex = nextUrlIndex;
  //     nameIndex = nextNameIndex;
  //   }

  //   return mappedFile;
  // };

  return (
    <>
      <div className="myapplication-detail__sub-tiny-title">贷款信息</div>
      <div style={{ marginBottom: 20 }}>
        申请贴息贷款金额：
        {getLoanAmountAll()}
        万元
      </div>
      {loanDetailsVos.map((Loan, index) => (
        <Collapse defaultActiveKey={`k-${index + 1}`} key={`k-${index + 1}`}>
          <Collapse.Panel key={`k-${index + 1}`} header={`贷款信息${index + 1}`}>
            <div
              className="myapplication-detail__grid"
              style={{ marginBottom: 0, borderBottom: 'none', paddingBottom: 0 }}
            >
              <div>
                贷款银行：
                {Loan.loanBank}
              </div>
              <div>
                贷款期间：
                {moment(Loan.loanDateRangeStartTime).format(dataFormat)}
                ~
                {moment(Loan.loanDateRangeEndTime).format(dataFormat)}
              </div>
              <div>
                贷款金额（万元）：
                {Loan.loanAmount}
              </div>
              <div>
                贷款利率（%）：
                {Loan.loanInterestRate}
              </div>
              <div>
                贷款种类：
                {Loan.loanTypeForShow}
                {`${Loan.capitalUseForShow ? ` - ${Loan.capitalUseForShow}` : ''}`}
              </div>
              {
                Loan.loanType === '2' && (
                  <div className="column-2">
                    贷款是否用于粮食生产及初加工：
                    {Loan.loanForFoodProduction === true ? '是' : '否'}
                  </div>
                )
              }
              {
                subjectBasicInfo.releaseFlag !== 0 && (
                  <>
                    <div>
                      实际贷款时间：
                      {Loan.actualLoanTime}
                      {Loan.actualLoanTimeUnitForShow}
                    </div>
                    <div>
                      折算贷款额（万元）：
                      {Loan.loanConvertAmount}
                    </div>
                  </>
                )
              }

              <div className="column-2">
                贷款使用主要内容：
                {Loan.mainPurposeOfLoan}
              </div>
              {
                subjectBasicInfo.releaseFlag !== 0 && (
                  <>
                    <div>
                      LPR（%）：
                      {Loan.lpr}
                    </div>
                    <div>
                      贴息率（%）：
                      {Loan.interestSubsidyRate}
                    </div>
                    <div className="column-2">
                      贴息额（万元）：
                      {Loan.discountAmount}
                      <Tooltip
                        overlayInnerStyle={{
                          width: 600,
                        }}
                        title={(
                          <div>
                            <code>
                              <p>贴息额计算：</p>
                              <p>
                                1.固定资产贷款：在年度中国人民银行发布的5年期以上贷款市场报价利率(LPR)基础上，扣减150个基点（BP）作为贴息利率。中国人民银行发布的2023年4月中长期贷款LPR为4.3%。
                              </p>
                              <p>贴息额=折算贷款额×2.8%。</p>
                              <p>
                                2.流动资金贷款：在年度中国人民银行发布的1年期贷款市场报价利率(LPR)基础上，扣减150个基点（BP）作为贴息利率。同时，为突出支持粮食生产，对粮食生产及初加工类的流动资金银行贷款在1年期贷款市场报价利率(LPR)基础上扣减100个基点（BP）。中国人民银行发布的2023年4月短期贷款LPR为3.65%。
                              </p>
                              <p>贴息额（粮食类）=折算贷款额×2.65%；</p>
                              <p>贴息额（其他类）=折算贷款额×2.15%；</p>
                              <div style={{ paddingLeft: 130, marginBottom: 2, paddingBottom: 2 }}>
                                实际贷款额×实际贷款时间(月)
                              </div>
                              <div>折算贷款额= ---------------------------------</div>
                              <div style={{ paddingLeft: 200, marginBottom: 2, paddingBottom: 2 }}>12</div>
                              <div style={{ paddingLeft: 130, marginBottom: 2, paddingBottom: 2 }}>
                                实际贷款额×实际贷款时间(天)
                              </div>
                              <div>或折算贷款额= ---------------------------------</div>
                              <div style={{ paddingLeft: 200, marginBottom: 2, paddingBottom: 2 }}>365</div>
                              贴息限额：对单个项目（或主体）固定资产贷款最高贴息金额不超过200万元，对单个项目（或主体）粮食生产和农业产业化流动资金贷款贴息不超过100万元。曾获得过省级财政农业产业化贷款贴息或在贴息时限内获得其他财政贴息的项目不得再申报。
                            </code>
                          </div>
                        )}
                      >
                        <QuestionCircleOutlined />
                        {' '}
                        计算说明
                      </Tooltip>
                    </div>
                  </>
                )
              }

              <div className="manage-detail-box column-2">
                <div className="manage-detail-li">
                  <div className="manage-detail-li-title w-large">银行贷款汇总表及银行贷款合同:</div>
                  <div className="manage-detail-li-content">
                    <LoanFileDownload key={Loan.id} attachmentId={Loan.id} filetype={1} attachmentName={Loan.tableContractFileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
                  </div>
                </div>
                <div className="manage-detail-li">
                  <div className="manage-detail-li-title w-large">支付银行利息汇总表及利息结算清单:</div>
                  <div className="manage-detail-li-content">
                    {Loan.financialDocumentList.map(({ url, name }) => (
                      <LoanFileDownload
                        key={url}
                        attachmentId={url}
                        filetype={name}
                        attachmentName={name}
                        downloadFunc={downloadUrlRun}
                        downloadLoading={downloadUrlLoading}
                      />
                    ))}
                  </div>
                </div>
                <div className="manage-detail-li">
                  <div className="manage-detail-li-title w-large">银行贷款到帐凭证及支出明细账:</div>
                  <div className="manage-detail-li-content">
                    <LoanFileDownload key={Loan.id} attachmentId={Loan.id} filetype={3} attachmentName={Loan.expenseDetailFileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
                  </div>
                </div>
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>
      ))}
    </>
  );
}

export default LoanDetailsVos;
LoanDetailsVos.propTypes = {
  loanDetailsVos: any,
  subjectBasicInfo: any,
};
