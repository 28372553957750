import { useState } from 'react';
import { indexOf } from 'lodash';
import { array, string, func } from 'prop-types';
import './ImgFilter.less';

export default function ImgFilter({ title, data, onChange }) {
  const [select, setSelect] = useState([0]);

  function handleChange(e) {
    let temp = [...select];
    const current = indexOf(select, e);
    if (current === -1) {
      temp = temp.filter((item) => item !== 0);
      temp.push(e);
    } else {
      temp = temp.filter((item) => item !== e);
    }
    setSelect(temp);
    onChange(temp);
  }

  return (
    <div className="img-filter">
      <div className="img-filter__row">
        <div className="img-filter__title">{title}</div>
        <div className="img-filter__box">
          {
              data?.map((item) => (
                <div className={`img-filter__row-item ${indexOf(select, item.orgId) !== -1 && 'img-filter__active'}`} key={item.orgId} onClick={() => handleChange(item.orgId)}>
                  <img src={item.publicUrl} alt="" width={166} height={46} />
                </div>
              ))
          }
        </div>
      </div>
    </div>
  );
}

ImgFilter.propTypes = {
  data: array,
  title: string,
  onChange: func,
};
