import { Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import ProductFilter from '@/components/ProductFilter/ProductFilter';
import FormInfo from '@/components/FormInfo/FormInfo';
import FixMenu from '@/components/FixMenu/FixMenu';
import { getBank, getDictType } from '@/services/common';
import { APPLICANT_TYPE, GMT_STATUS, LOAN_AMOUNT } from '@/utils/mappingDict';

import './index.less';

const dictParams = [APPLICANT_TYPE, GMT_STATUS, LOAN_AMOUNT].join(',');

export default function Index() {
  const Navigate = useNavigate();
  const status = useSelector((state) => state.status);
  const { data: bank = [] } = useRequest(getBank);
  const { data: dict = {} } = useRequest(getDictType, { // 筛选项字典
    defaultParams: [dictParams],
  });

  const onChange = (key) => {
    if (key === '2' && status) {
      Navigate('/authentication');
    }
  };

  const items = [
    { label: '融资产品', key: '1', children: <ProductFilter data={{ bank, dict }} /> },
    {
      label: '指定银行融资',
      key: '2',
      children:
  <div>
    <div className="yhzd__gbrz-text">
      指定银行融资是针对具有资金需求的企业或者个人，直接录入您的融资需求，一键直达申请银行。无需选择具体的融资产品，银行专员快速响应，满足您多元化的融资需求。
      如果您有需要，请直接填写如下信息，进行申请。
    </div>
    <FormInfo type="gbrz" />
  </div>,
    },
  ];

  return (
    <div className="yhzd">
      <div className="yhzd-banner">银行直贷</div>
      <div className="content-width">
        <div className="yhzd__product-title" />
        <Tabs items={items} onChange={onChange} />
      </div>
      <FixMenu />
    </div>
  );
}
