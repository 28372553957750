import axios from 'axios';
import { message } from 'antd';
import queryString from 'query-string';
import { getToken } from './token';
import { apiBase } from '@/utils/config';

const TIMEOUT = '请求超时';
const NO_RESPONSE = '服务器无响应';

// create an axios instance
const service = axios.create({
  withCredentials: true, // send cookies when cross-domain requests
  baseURL: apiBase,
  timeout: 30000, // request timeout
  paramsSerializer(params) {
    return queryString.stringify(params, { arrayFormat: 'brackets' });
  },
});

// request interceptor
service.interceptors.request.use((config) => {
  const token = getToken();
  if (token) {
    // 让每个请求携带 token
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = token;
  }

  return config;
});

function getErrorMessage(data) {
  const msg = data.message || data.msg || NO_RESPONSE;
  return msg.indexOf('timeout') > -1 ? TIMEOUT : msg;
}

function handleError(response) {
  if (response?.code !== 200) {
    message.error({
      content: response.message,
    });
  }
  return Promise.reject(response);
}

// response inte(response)
service.interceptors.response.use(
  (response) => {
    const { data, headers, config } = response;
    if (response.headers['content-type'].includes('application/octet-stream') && typeof data === 'object') {
      // 如果是下载文件，则返回文件流和文件名
      const filename = headers['content-disposition']?.split('filename=')[1];
      return Promise.resolve({ data, filename: decodeURI(filename) });
    }
    if (config.responseType === 'blob' && config.fileName) {
      // 如果不是通过接口请求返回blob，则是直接通过URL下载文件
      return Promise.resolve({ data, fileName: decodeURI(config.fileName) });
    }
    if (data.code === '200') {
      return Promise.resolve(data.data);
    }
    data.message = getErrorMessage(data);
    return handleError({ ...data, ...response });
  },
  (error) => {
    // 断网、后端服务停止时，error.response 为空
    if (!error.response) {
      return handleError({ ...error, message: NO_RESPONSE });
    }

    const { response } = error;
    // eslint-disable-next-line prefer-const
    let { data = {}, status } = response;

    if (status === 401) {
      window.location.hash = '/login';
      // eslint-disable-next-line consistent-return
      return;
    }

    // data 可能是字符串，如 404 的时候
    if (typeof data === 'object') {
      data.message = getErrorMessage(data);
    } else {
      data = { message: error.message };
    }

    return handleError({ ...error, ...response, ...data });
  },
);

export default service;
