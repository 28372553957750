import {
  Spin, Col, Form, Input, Row, Select, Button,
} from 'antd';
import { useState } from 'react';
import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CodeInput from '@/components/CodeInput/CodeInput';
import { getEnterprise, authEnterprise } from '@/services/user';
import { getDictType } from '@/services/common';
import { USE_POSITION_TYPE, AGRICULTURE_CATEGORY } from '@/utils/mappingDict';
import '../index.less';

const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 14 },
  },
};
const params = [AGRICULTURE_CATEGORY, USE_POSITION_TYPE].join(',');

/*
* 企业认证
* */
export default function Enterprise() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [phone, setPhone] = useState('');

  const { data: dict = {} } = useRequest(getDictType, {
    defaultParams: [params],
  });

  const { data, loading } = useRequest(getEnterprise, {
    onSuccess(res) {
      const { operatorLoginInfoItem, legalPersonAuthInfoItem } = res;
      const obj = {
        jbphone: operatorLoginInfoItem?.phone,
        name: legalPersonAuthInfoItem?.name,
      };
      form.setFieldsValue(obj);
    },
  });

  const { run, loading: authLoading } = useRequest((val) => authEnterprise(val), {
    manual: true,
    onSuccess() {
      dispatch({ type: 'STATUS', payload: '' });
      Navigate('/user');
    },
  });

  function onFinish(values) {
    const obj = {
      legalPersonAuthInfoItem: {
        idCardNo: values.idCardNo,
        name: values.name,
        phone: values.phone,
        smsCode: values.code,
      },
      operatorLoginInfoItem: {
        idCardNo: values.jbidCardNo,
        identity: values.identity,
        name: values.jbname,
        phone: values.jbphone,
      },
      agricultureCategory: values?.agricultureCategory,
    };
    run(obj);
  }

  function checkPhone(rule, value) {
    const promise = Promise;
    setPhone(value);
    return promise.resolve();
  }

  const { enterpriseBasicInfoItem } = data || {};
  return (
    <div className="authentication">
      <div className="content-width">
        <div className="authentication-title">认证资料</div>
        <Spin spinning={loading}>
          <Form
            {...formItemLayout}
            form={form}
            name="form"
            className="form"
            onFinish={onFinish}
            initialValues={{ is: 1 }}
          >
            <div className="authentication-con">
              <div>
                <div className="authentication-sub-title">用户登录信息</div>
                <Row className="authentication-border-b">
                  <Col span={12}>
                    <Form.Item
                      name="jbname"
                      label="经办人姓名"
                      rules={[
                        {
                          required: true,
                          message: '请输入经办人姓名',
                        },
                      ]}
                    >
                      <Input placeholder="请输入经办人姓名" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="jbidCardNo"
                      label="经办人身份证号"
                      rules={[
                        {
                          required: true,
                          message: '请输入经办人身份证号',
                        },
                        {
                          pattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
                          message: '请输入正确的身份证号码',
                        },
                      ]}
                    >
                      <Input placeholder="请输入经办人身份证号" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="jbphone"
                      label="经办人手机号"
                      rules={[
                        {
                          required: true,
                          message: '请输入经办人手机号',
                        },
                        {
                          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                          message: '请输入正确的手机号',
                        },
                      ]}
                    >
                      <Input placeholder="请输入经办人手机号" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="identity"
                      label="经办人身份"
                      rules={[
                        {
                          required: true,
                          message: '请选择经办人身份',
                        },
                      ]}
                    >
                      <Select placeholder="请选择经办人身份">
                        {
                          dict?.[USE_POSITION_TYPE]?.map((item) => (
                            <Select.Option value={item.val} key={item.val}>{item.name}</Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="agricultureCategory"
                      label="企业产业区分"
                      rules={[
                        {
                          required: true,
                          message: '请选择企业产业区分',
                        },
                      ]}
                    >
                      <Select placeholder="请选择企业产业区分">
                        {
                          dict?.[AGRICULTURE_CATEGORY]?.map((item) => (
                            <Select.Option value={item.val} key={item.val}>{item.name}</Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  {/* <Col span={12}> */}
                  {/*  <Form.Item */}
                  {/*    name="is" */}
                  {/*    label="是否为新市民企业" */}
                  {/*  > */}
                  {/*    <Radio.Group> */}
                  {/*      <Radio value={1}>是</Radio> */}
                  {/*      <Radio value={2}>否</Radio> */}
                  {/*    </Radio.Group> */}
                  {/*  </Form.Item> */}
                  {/* </Col> */}
                </Row>
              </div>
              <div className="authentication-border-b">
                <div className="authentication-sub-title">企业基本信息</div>
                <div className="authentication-enterprise">
                  <div className="authentication-grid">
                    <div>
                      统一社会信用代码：
                      {enterpriseBasicInfoItem?.creditCode}
                    </div>
                    <div>
                      企业名称：
                      {enterpriseBasicInfoItem?.name}
                    </div>
                    <div>
                      企业类型：
                      {enterpriseBasicInfoItem?.enterpriseType}
                    </div>
                    <div>
                      注册资金（
                        {enterpriseBasicInfoItem?.registeredCapitalUnit}
                        ）：
                        {enterpriseBasicInfoItem?.registeredCapital}
                    </div>
                    <div>
                      注册时间：
                      {enterpriseBasicInfoItem?.registeredDate}
                    </div>
                    <div>
                      营业期限：
                      {enterpriseBasicInfoItem?.operationTerm}
                    </div>
                    <div>
                      所属行业：
                      {enterpriseBasicInfoItem?.industry}
                    </div>
                    <div>
                      注册地址：
                      {enterpriseBasicInfoItem?.addressDetail}
                    </div>
                  </div>
                  <div>
                    经营地址：
                    {enterpriseBasicInfoItem?.businessAddressDetail}
                  </div>
                  <div>
                    经营范围：
                    {enterpriseBasicInfoItem?.businessScope}
                  </div>
                </div>
              </div>
              <div>
                <div className="authentication-sub-title">企业认证信息</div>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="法人姓名"
                      rules={[
                        {
                          required: true,
                          message: '请输入法人姓名',
                        },
                      ]}
                    >
                      <Input placeholder="请输入法人姓名" disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="idCardNo"
                      label="法人身份证号"
                      rules={[
                        {
                          required: true,
                          message: '请输入法人身份证号',
                        },
                        {
                          pattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
                          message: '请输入正确的身份证号码',
                        },
                      ]}
                    >
                      <Input placeholder="请输入法人身份证号" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="phone"
                      label="法人手机号"
                      rules={[
                        {
                          required: true,
                          message: '请输入法人手机号',
                        },
                        {
                          pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
                          message: '请输入正确的手机号',
                        },
                        { validator: checkPhone },
                      ]}
                    >
                      <Input placeholder="请输入法人手机号" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="验证码"
                    >
                      <CodeInput phone={phone} isApply type={4} />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="authentication-text">
                  *请仔细填写并核对上述企业法人信息，点击“提交信息”，系统自动核验；认证成功后，即可开始享受平台服务。
                </div>
              </div>
            </div>
            <div className="authentication-footer">
              <div>
                <Button type="primary" htmlType="submit" loading={authLoading}>提交信息</Button>
              </div>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
}
