import request from '@/utils/request';

// 担保奖补列表查询
export function getCreditGuaranteeList(data) {
  return request({
    url: '/guarantee/bonus/user/list',
    method: 'POST',
    data: { ...data, type: 1, status: 1 },
  });
}

// 担保奖补公示列表查询
export function getCreditGuaranteePublicList(data) {
  return request({
    url: '/guarantee/bonus/manage/user/release/publish',
    method: 'POST',
    data: { ...data },
  });
}

// 担保奖补详细查询
export function getCreditGuaranteeDetail(id) {
  return request({
    url: '/guarantee/bonus/detail',
    method: 'GET',
    params: { id },
  });
}

// 担保奖补附件下载
// 担保奖补表格内附件下载
export function attchmentFileDownload(id, inTable = false) {
  return request({
    url: inTable ? '/guarantee/bonus/loan/download' : '/guarantee/bonus/download',
    method: 'GET',
    responseType: 'blob',
    timeout: 2000000,
    params: { id },
  });
}

// 担保奖补申请
export function addApply(data) {
  return request({
    url: '/guarantee/application/add',
    method: 'POST',
    data,
  });
}

// 担保奖补重新申请
export function updateBackApply(data) {
  return request({
    url: '/guarantee/application/edit',
    method: 'POST',
    data,
  });
}

// 担保奖补申请详情
export function getCreditGuaranteeApplicationDetail(id) {
  return request({
    url: '/guarantee/application/detail',
    method: 'GET',
    params: { id, type: 1 },
  });
}

// 担保奖补模板下载
export function guaranteeTemplateDownload() {
  return request({
    url: '/guarantee/application/download/template',
    method: 'GET',
    responseType: 'blob',
    timeout: 2000000,
  });
}

// 临时保存担保奖补已填写信息
export function saveTemporarilyData(data) {
  return request({
    url: '/guarantee/application/add/temporarily',
    method: 'POST',
    data,
  });
}

// 临时保存担保奖补已填写信息
export function getTemporarilyData() {
  return request({
    url: '/guarantee/application/detail/temporarily',
    method: 'GET',
  });
}

// 删除担保奖补已填写信息
export function deleteTemporarilyData() {
  return request({
    url: '/guarantee/application/update/temporarily',
    method: 'GET',
  });
}
