/**
 * @name: ProjectBasicInfo
 * @description：项目基本信息
 * @author: jiangh
 */
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { any } from 'prop-types';
import '../../MajorProjectTimeline.less';

function ProjectBasicInfo({ projectBasicInfo }) {
  return (
    <div>
      <div className="myapplication-detail__sub-tiny-title">项目信息</div>
      <div className="myapplication-detail__grid">
        <div>
          项目名称：
          {projectBasicInfo.projectName}
        </div>
        <div>
          项目单位：
          {projectBasicInfo.projectUnit}
        </div>
        <div>
          申报年度：
          {projectBasicInfo.declarationYear}
        </div>
        <div>
          项目建设性质：
          {projectBasicInfo.projectNature}
        </div>
        <div>
          项目建设区域：
          {projectBasicInfo.projectRegionForShow}
        </div>
        <div>
          项目建设周期（年）：
          {projectBasicInfo.projectConstructionPeriod}
        </div>
        <div className="column-2">
          项目建设地址：
          {projectBasicInfo.projectConstructionAddress}
        </div>
        <div>
          自筹资金（万元）：
          {projectBasicInfo.selfFunding}
        </div>
        <div>
          其他资金（万元）：
          {projectBasicInfo.otherFunding}
        </div>
        <div>
          贷款金额(万元)：
          {projectBasicInfo.loanAmount}
        </div>
        <div>
          合计金额（万元）：
          {projectBasicInfo.totalAmount}
          &nbsp;&nbsp;
          <Tooltip
            title="合计金额 = 自筹资金 + 银行贷款 + 其他资金"
            overlayInnerStyle={{
              width: 300,
            }}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
        <div>
          达产后年销售收入(万元)：
          {projectBasicInfo.annualSalesRevenue}
        </div>
        <div>
          达产后年创利税(万元)：
          {projectBasicInfo.annualProfitTaxAfterProduction}
        </div>
        <div>
          带动农户户数（户）：
          {projectBasicInfo.numberofHouseholdsBenefited}
        </div>
        <div>
          其中：脱贫户户数：
          {projectBasicInfo.impoverishedHouseholdsBenefited}
        </div>
        <div>
          带动农户人数（人）：
          {projectBasicInfo.householdAffected}
        </div>
        <div>
          其中：脱贫户人数：
          {projectBasicInfo.impoverishedHouseholdAffected}
        </div>
        <div>
          带动农户户均增收(元)：
          {projectBasicInfo.avgIncomePerHousehold}
        </div>
        <div>
          带动农户总增收(万元)：
          {projectBasicInfo.totalIncomeIncrease}
        </div>
        <div className="column-2">
          主要建设内容和规模：
          {projectBasicInfo.constructionContentAndScale}
        </div>
        <div className="column-2">
          <div>经营模式 （与农民的利益联结方式）:</div>
          <div>{projectBasicInfo.businessModel}</div>
        </div>
      </div>
    </div>
  );
}
export default ProjectBasicInfo;

ProjectBasicInfo.propTypes = {
  projectBasicInfo: any,
};
