import {
  Button, Tooltip,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { object } from 'prop-types';
import ysq from '@/assets/myApplication/status_ysq.png';
import sptg from '@/assets/myApplication/status_sptg.png';
import yxy from '@/assets/myApplication/status_yxy.png';
import blz from '@/assets/myApplication/status_blz.png';
import fkcg from '@/assets/myApplication/status_fkcg.png';
import spwtg from '@/assets/myApplication/status_spwtg.png';
import cancel from '@/assets/myApplication/status_qx.png';
import success from '@/assets/myApplication/status_success.png';
import zz from '@/assets/myApplication/status_zz.png';
import th from '@/assets/myApplication/status_th.png';
import majorMinancing from '@/assets/myApplication/major-financing.png';

const statusObj = {
  0: { name: '已申请', class: '#3E7EFF', icon: ysq },
  1: { name: '审批通过', class: '#3E7EFF', icon: sptg },
  2: { name: '已响应', class: '#3E7EFF', icon: yxy },
  3: { name: '办理中', class: '#3E7EFF', icon: blz },
  4: { name: '放款成功', class: '#12A366', icon: fkcg },
  5: { name: '审批未通过', class: '#E54545', icon: spwtg },
  9: { name: '已取消', class: '#999999', icon: cancel },
  10: { name: '完结', class: '#12A366', icon: success },
  11: { name: '终止', class: '#E54545', icon: zz },
  12: { name: '退回', class: '#999999', icon: th },
  23: { name: '已授信', class: '#12A366', icon: success },
  25: { name: '已拒绝', class: '#E54545', icon: spwtg },
};

export default function MajorProjectCard(props) {
  const Navigate = useNavigate();
  const { data, onCancel, onSchedule } = props;
  function getDate(e) {
    const day = e.split(' ');
    return day[0];
  }
  return (
    <div className="myapplication__list-row">
      <div className="myapplication__status">
        <img src={statusObj[data.status]?.icon} alt="" width={130} height={35} />
        <span style={{ color: statusObj[data.status]?.class }} className="myapplication__status-text">{statusObj[data.status]?.name}</span>
      </div>
      <div className="myapplication__list-row-img">
        <img src={majorMinancing} alt="" width={250} height={75} />
      </div>
      <div className="myapplication__list-row-con">
        <div>
          <div className="myapplication__list-row-name">
            <Tooltip title={data.productName}>
              <span className="myapplication__list-pro-name">{data.productName || '-'}</span>
            </Tooltip>
            <span className="tagOrg">重大项目融资</span>
          </div>
        </div>
        <div className="margin-right20">
          <div>申请金额</div>
          <div className="myapplication__list-row-title">
            {data.loanMoney}
            万元
          </div>
        </div>
        <div className="margin-right20">
          <div>贷款期限</div>
          <div className="myapplication__list-row-title">
            {data.loanYear}
            个月
          </div>
        </div>
        <div className="margin-right20">
          <div>申请时间</div>
          <div className="myapplication__list-row-title">{getDate(data.gmtCreate)}</div>
        </div>
      </div>
      <div className="btn-box">
        <Button type="primary" onClick={onSchedule}>
          进度查询
        </Button>
        {
          data?.status === 0 && (
            <Button type="primary" ghost onClick={onCancel}>
              取消申请
            </Button>
          )
        }
        {
          data?.status === 12 && (
            <Button
              type="primary"
              ghost
              onClick={() => {
                Navigate(`/majorFinancing/application?backId=${data?.applicationId}`);
              }}
            >
              重新申请
            </Button>
          )
        }
      </div>
    </div>
  );
}

MajorProjectCard.propTypes = {
  data: object,
  onSchedule: () => {},
  onCancel: () => {},
};
