import BankApplication from '@/pages/bank/Application';
import GuaranteeApplication from '@/pages/guarantee/Application';
import User from '@/pages/user';
import MyApplication from '@/pages/myApplication/List';
import MyApplicationDetail from '@/pages/myApplication/Detail';
import Authentication from '@/pages/authentication';
import MajorFinancingApplication from '@/pages/majorFinancing/Application';

export const rootRouter = [
  { path: '/bank/application', element: (<BankApplication />), requiresAuth: true },
  { path: '/guarantee/application', element: (<GuaranteeApplication />), requiresAuth: true },
  { path: '/user', element: (<User />) },
  { path: '/myApplication', element: (<MyApplication />), requiresAuth: true },
  { path: '/myApplication/detail', element: (<MyApplicationDetail />), requiresAuth: true },
  { path: '/authentication', element: (<Authentication />) },
  { path: '/majorFinancing/application', element: (<MajorFinancingApplication />) },
];
