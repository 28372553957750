import { useEffect } from 'react';
import { Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { useSelector } from 'react-redux';
import heat from '@/assets/heat.png';
import ScrollTable from '@/components/ScrollTable/ScrollTable';
import { getMajorFinancingList } from '@/services/majorFinancing';
import { getToken } from '@/utils/token';
import './index.less';

export default function Index() {
  const Navigate = useNavigate();
  const { status, userType } = useSelector((state) => state);

  const { data, loading } = useRequest(getMajorFinancingList);

  useEffect(() => {
    if (userType === 2) {
      Navigate('/home');
    }
  }, []);
  function handleChange() {
    const token = getToken();
    if (!token) {
      Navigate('/login');
    } else if (status) {
      Navigate('/authentication');
    } else {
      Navigate('/majorFinancing/application');
    }
  }
  return (
    <div className="major-financing">
      <div className="major-financing__banner">
        重大项目融资
      </div>
      <Spin spinning={loading}>
        <div className="scroll-box">
          <div className="content-width scroll-box__grid">
            <div>
              <div className="scroll-box__title-box">
                <img src={heat} width={24} alt="" />
                <div className="scroll-box__title">最新申请动态</div>
              </div>
              <ScrollTable data={data?.dynamicVos} />
            </div>
            <div className="scroll-box__last">
              <div className="scroll-box__title-box">
                <img src={heat} width={24} alt="" />
                <div className="scroll-box__title">最新授信动态</div>
              </div>
              <ScrollTable data={data?.dynamicVoList} />
            </div>
          </div>
        </div>
      </Spin>
      <div className="major-financing__btn">
        <Button type="primary" onClick={() => handleChange()}>立即申请</Button>
      </div>
    </div>
  );
}
