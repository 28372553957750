import request from '@/utils/request';

// 招商项目查询
export function getCapitalList(data) {
  return request({
    url: '/social/capital/web/find',
    method: 'POST',
    data: { ...data },
  });
}

// 招商项目详细查询
export function getCapitalDetail(id) {
  return request({
    url: '/social/capital/web/id',
    method: 'GET',
    params: { id },
  });
}
