import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import susses from '@/assets/susses.png';
import './index.less';

export default function Index() {
  const Navigate = useNavigate();

  function handleChange() {
    Navigate('/home');
  }

  return (
    <div className="result">
      <div className="border" />
      <div className="result__con">
        <div>
          <div className="result__con-status">
            <div className="">
              <img src={susses} alt="" width={70} height={70} />
            </div>
          </div>
          <div>您的申请已提交，您可以在“我的申请”查看最新进度情况</div>
          <Button type="primary" style={{ marginTop: 20 }} onClick={() => handleChange()}>
            确定
          </Button>
        </div>
      </div>
    </div>
  );
}
