import {
  Button, Form, Checkbox, Modal, Spin, message,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { object, string } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useRequest } from 'ahooks';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  getArea,
  getDictType,
  getLoanBank,
  updateUserBasic,
  addGuarantee,
  applyVerifyCode,
  addBank,
  addBroadcast,
  getBasicType,
} from '@/services/common';
import { getUserBasic } from '@/services/user';
import { getActionBusinessInfo } from '@/redux/action';
import UpdateBasicForm from '@/components/UpdateBasicForm/UpdateBasicForm';
import CodeInput from '@/components/CodeInput/CodeInput';
import CustomFooter from '@/components/CustomFooter/CustomFooter';
import {
  BASIC_LEVEL, BUSINESS_TYPE, LOAN_TYPE, ORIENTATIO_TYPE, BUSINESS_UNIT,
} from '@/utils/mappingDict';
import FinancingNeeds from './FinancingNeeds';
import BasicInfoForm from './BasicInfoForm';
import BusinessForm from './BusinessForm';
import BasicInfo from './BasicInfo';
import AuthorizationInfo from './AuthorizationInfo';
import './FormInfo.less';

const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
};

const dictParams = [LOAN_TYPE, BUSINESS_TYPE, BASIC_LEVEL, ORIENTATIO_TYPE, BUSINESS_UNIT].join(',');
const typeObj = {
  yhzd: 1,
  gbrz: 2,
  dbzd: 3,
};

export default function FormInfo({ type, product }) {
  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const userInfo = useSelector((state) => state.userInfo); // 用户信息
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [authOpen, setAuthOpen] = useState(false);
  const [authInfoModal, setAuthInfoModal] = useState(false); // 授权书内容
  const [formData, setFormData] = useState({}); // 提交form表单数据
  const [applyLoading, setApplyLoading] = useState(false); // 申请loading

  const { data, loading, run } = useRequest(getUserBasic, {
    onSuccess(res) {
      form.setFieldsValue({
        ...res?.applicationUserBasicInfoVo,
        basicProvicnce: res?.applicationUserBasicInfoVo?.basicProvicnce && [
          res?.applicationUserBasicInfoVo.basicProvicnce,
          res?.applicationUserBasicInfoVo?.basicCity,
          res?.applicationUserBasicInfoVo?.basicArea,
        ],
      });
    },
  }); // 获取经营基本信息
  const { data: area = [] } = useRequest(getArea); // 区域
  const { data: dict = {} } = useRequest(getDictType, { // 筛选项字典
    defaultParams: [dictParams],
  });
  const { data: basicType = [] } = useRequest(getBasicType); // 获取主体类型
  const { data: loanBank = [] } = useRequest(getLoanBank, { // 意向银行
    defaultParams: [1],
  });

  const { run: basicRun, loading: basicLoading } = useRequest(updateUserBasic, { // 更新用户经营信息
    manual: true,
    onSuccess() {
      setIsModalOpen(false);
      run();
      getActionBusinessInfo();
      message.success('更新成功');
    },
  });

  const { run: bankRun } = useRequest(addBank, { // 银行申请
    manual: true,
    onSuccess() {
      setApplyLoading(false);
      setAuthOpen(false);
      getActionBusinessInfo();
      Navigate('/result');
    },
    onError() {
      setApplyLoading(false);
    },
  });

  const { run: broadcastRun } = useRequest(addBroadcast, { // 广播融资申请
    manual: true,
    onSuccess() {
      setApplyLoading(false);
      setAuthOpen(false);
      getActionBusinessInfo();
      Navigate('/result');
    },
    onError() {
      setApplyLoading(false);
    },
  });

  const { run: guaranteeRun } = useRequest(addGuarantee, { // 担保申请
    manual: true,
    onSuccess() {
      setApplyLoading(false);
      setAuthOpen(false);
      getActionBusinessInfo();
      Navigate('/result');
    },
    onError() {
      setApplyLoading(false);
    },
  });

  const { run: verifyRun } = useRequest(applyVerifyCode, { // 验证码校验
    manual: true,
    onSuccess() {
      switch (type) {
        case 'yhzd':
          bankRun(formData);
          break;
        case 'dbzd':
          guaranteeRun(formData);
          break;
        case 'gbrz':
          broadcastRun(formData);
          break;
        default:
          break;
      }
    },
    onError() {
      setApplyLoading(false);
    },
  });

  function handleAuth() {
    setAuthOpen(true);
  }

  function onFinish(values) {
    let val = {
      blankListProducts: {
        productId: product?.productId,
        productName: product?.productName,
        publishOrgId: product?.publishOrgId,
        publishOrgName: product?.publishOrgName,
      },
    };
    if (isEmpty(data?.applicationUserBusinessInfoVo)) {
      val = {
        ...val,
        applicationRequestVo: {
          loanAmount: values.loanAmount,
          loanInfo: values.loanInfo,
          loanRate: values.loanRate,
          loanStatus: values.loanStatus,
          loanType: values.loanType,
          loanYear: values.loanYear,
          orgId: values?.orgId,
          orgName: values.orgName,
          orientation: values.orientation,
        },
        applicationUserBasicInfoVo: {
          basicAddress: values.basicAddress,
          basicArea: values.basicProvicnce[2],
          basicCard: values.basicCard,
          basicCity: values.basicProvicnce[1],
          basicLevel: values.basicLevel,
          basicName: values.basicName,
          basicNo: values.basicNo,
          basicProvicnce: values.basicProvicnce[0],
          basicTel: values.basicTel,
          basicType: values.basicType,
          familyName: values.familyName,
          levelTime: moment(values.levelTime).format('YYYY-MM-DD'),
        },
        applicationUserBusinessInfoVo: {
          businessAmount: values.businessAmount,
          businessType: values.businessType,
          businessYear: values.businessYear,
          plantArea: values.plantArea,
          plantType: values.plantType,
          unitId: values?.unitId,
        },
      };
    } else {
      val = {
        ...val,
        applicationRequestVo: values,
        applicationUserBasicInfoVo: data?.applicationUserBasicInfoVo,
        applicationUserBusinessInfoVo: data?.applicationUserBusinessInfoVo,
      };
    }
    setFormData(val);
    handleAuth();
  }
  function handleOpen() {
    setIsModalOpen(true);
  }

  function submitForm(val) {
    basicRun(val);
  }

  function handleAuthOk() {
    form.validateFields().then((values) => {
      // 校验手机号-验证码
      setApplyLoading(true);
      verifyRun({ account: userInfo?.phone, code: values.code, type: typeObj[type] });
    });
  }

  function handleAuthCancel() {
    setAuthOpen(false);
    form.setFieldsValue({ code: '' });
  }
  function checkAuthorize(_, value) {
    const promise = Promise;
    if (!value) {
      return promise.reject('请勾选同意协议');
    }
    return promise.resolve();
  }

  return (
    <div className="form-info">
      <Spin spinning={loading}>
      <Form
        {...formItemLayout}
        form={form}
        name="form"
        className="form"
        onFinish={onFinish}
      >
        <div className="form-info__box">
          <div className="form-info__box-title">融资需求信息</div>
          <div className="form-info__box-con">
            <FinancingNeeds data={{ ...dict, loanBank, product }} isHide={type === 'yhzd' && !isEmpty(product)} type={type} form={form} />
          </div>
        </div>
        {
                    isEmpty(data?.applicationUserBusinessInfoVo)
                      ? (
                        <div className="form-info__box border-none">
                          <div className="form-info__box-con">
                            <div className="form-info__title">基本信息</div>
                            <BasicInfoForm options={area} dict={{ ...dict, basicType }} />
                            <div className="form-info__title">经营信息</div>
                            <BusinessForm dict={dict} />
                          </div>
                        </div>
                      )
                      : (
                        <div className="form-info__box border-none">
                          <div className="form-info__box-edit" onClick={() => handleOpen()}>
                            <EditOutlined />
                            修改信息
                          </div>
                          <BasicInfo data={{ ...data?.applicationUserBasicInfoVo, ...data?.applicationUserBusinessInfoVo }} />
                        </div>
                      )
}
        <div className="authorize">
          <Form.Item
            name="authorize"
            wrapperCol={{ span: 24 }}
            valuePropName="checked"
            rules={[
              { validator: checkAuthorize },
            ]}

          >
            <Checkbox>
              同意并接受
              <Button type="link" className="authorize-name" onClick={() => setAuthInfoModal(true)}>《平台信息授权协议》</Button>
            </Checkbox>
          </Form.Item>
        </div>
        {
                    type ? (
                      <div className="submit-btn-box">
                        <Button type="primary" ghost className="submit-btn__back" onClick={() => Navigate(-1)}>
                          返回
                        </Button>
                        <Button type="primary" htmlType="submit" className="submit-btn__main">
                          立即申请
                        </Button>
                      </div>
                    ) : (
                      <div className="submit-btn-box">
                        <Button type="primary" htmlType="submit">
                          立即申请
                        </Button>
                      </div>
                    )
                }
      </Form>
      </Spin>
      <Modal
        title="完善信息"
        destroyOnClose
        footer={null}
        open={isModalOpen}
        width={700}
        onCancel={() => setIsModalOpen(false)}
      >
        <UpdateBasicForm
          onCancel={() => setIsModalOpen(false)}
          onOk={submitForm}
          data={{ ...data?.applicationUserBasicInfoVo, ...data?.applicationUserBusinessInfoVo }}
          options={area}
          dict={{ ...dict, basicType }}
          confirmLoading={basicLoading}
        />
      </Modal>
      <Modal title="短信认证" className="auth-modal" destroyOnClose maskClosable width={545} open={authOpen} onCancel={handleAuthCancel} footer={null}>
        <div>
          <div style={{ marginBottom: 15 }}>
            验证码已发送至手机号
            {userInfo?.phone}
            ，
            <br />
            请输入收到的短信验证码以核验身份
          </div>
          <Form form={form} name="code">
            <Form.Item>
              <CodeInput phone={userInfo?.phone} isApply type={typeObj[type]} />
            </Form.Item>
          </Form>
          <CustomFooter onOk={handleAuthOk} onCancel={handleAuthCancel} confirmLoading={applyLoading} />
        </div>
      </Modal>
      <Modal title="授权协议查看" open={authInfoModal} width={900} footer={null} onCancel={() => setAuthInfoModal(false)}>
        <div dangerouslySetInnerHTML={{ __html: AuthorizationInfo }} style={{ maxHeight: 600, overflowY: 'scroll' }} />
      </Modal>
    </div>
  );
}

FormInfo.propTypes = {
  product: object,
  type: string,
};

FormInfo.defaultProps = {
  product: {},
};
