import { useEffect } from 'react';
import { useRequest } from 'ahooks';
import CapitalFilter from '../components/CapitalFilter';
import FixMenu from '@/components/FixMenu/FixMenu';
import { getAreaWithName, getDictType } from '@/services/common';
import { CAPITAL_PROJECT_TYPE, CAPITAL_AMOUNT_TYPE } from '@/utils/mappingDict';
import './index.less';

const dictParams = [CAPITAL_PROJECT_TYPE, CAPITAL_AMOUNT_TYPE].join(',');

export default function Index() {
  const { data: area = [] } = useRequest(getAreaWithName, {
    defaultParams: ['510000'],
  }); // 固定获取四川省内区域

  const { data: dict = {} } = useRequest(getDictType, { // 筛选项字典
    defaultParams: [dictParams],
  });

  const data = { area, dict };

  useEffect(() => {
  }, []);

  return (
    <div className="bxzn">
      <div className="bxzn__banner">招商项目</div>
      <div className="content-width">
        <div className="zsyz__title">
          四川是农业大省，农业资源得天独厚，素有“天府之国”的美誉。
          耕地面积达1亿亩、居全国第6，是全国13个粮食主产省和3大育种核心区之一，是生猪养殖第一大省、国家唯一的优质商品猪战略保障基地和5大牧区之一。
          省委省政府历来重视农业农村优先发展、高质量发展，四川农业农村发展机遇前所未有。
          习近平总书记嘱咐四川要擦亮农业“金字招牌”，建设新时代更高水平的“天府粮仓”，为四川农业农村投资兴业提供了千载难逢的机遇。
          打造天府粮仓、建设农业强省，四川农业产业发展、乡村建设合作可期，为此，我厅收集、发布全省农业种养殖、农产品加工、智慧农业等方面项目，真诚欢迎广大企业家朋友咨询查阅、实地考察。
        </div>
        <CapitalFilter data={data} />
      </div>
      <FixMenu />
    </div>
  );
}
