import {
  Button, Form, Input, message, Select,
} from 'antd';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';
import { login } from '@/services/login';
import { getSmsCodeApply } from '@/services/common';
import setEncrypt from '@/utils/encrypt';
import { setToken } from '@/utils/token';
import '../index.less';

let timer = null;

export default function TelLogin() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const [time, setTime] = useState(0);
  const [type, setType] = useState('2'); // 用户类型

  const { run: codeRun } = useRequest((val) => getSmsCodeApply({ ...val, type: 4 }), {
    manual: true,
    onSuccess() {
      message.success('已发送，请注意查收');
    },
    onError() {
      setTime(0);
    },
  }); // 获取验证码

  const { run } = useRequest((val) => login({ ...val, loginMode: 1 }), {
    manual: true,
    onSuccess(res) {
      setToken(res?.token);
      dispatch({ type: 'USER_TYPE', payload: res?.userType });
      dispatch({ type: 'STATUS', payload: res?.materialStatus });
      if (res?.materialStatus) {
        Navigate('/authentication');
      } else if (state?.url) {
        Navigate(state?.url);
      } else {
        Navigate(-1);
      }
    },
  }); // 登录

  function onFinish(val) {
    const obj = {
      ...val,
      creditCode: val?.creditCode ? setEncrypt(val?.creditCode) : undefined,
      password: setEncrypt(val?.password),
      accountNo: type === '2' ? setEncrypt(val.phone) : setEncrypt(val.creditCode),
      loginMode: 1,
    };
    run(obj);
  }

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    timer && clearInterval(timer);
    return () => timer && clearInterval(timer);
  }, []);

  useEffect(() => {
    if (time === 60) {
      // eslint-disable-next-line no-shadow,no-param-reassign
      timer = setInterval(() => setTime((time) => --time), 1000);
    } else if (time === 0) {
      clearInterval(timer);
    }
  }, [time]);

  function getCode() {
    const phone = form.getFieldValue('phone');
    if (phone) {
      codeRun({ phone });
      setTime(60);
    } else {
      message.error('请输入手机号码');
    }
  }

  function handleChange(e) {
    setType(e);
  }

  function checkCode(_, value) {
    const promise = Promise;
    if (value && value.length !== 6) {
      return promise.reject('请输入6位数验证码');
    }
    return promise.resolve();
  }

  return (
    <Form
      name="loginForm"
      form={form}
      onFinish={onFinish}
      autoComplete="off"
      initialValues={{
        userType: '2',
      }}
    >
      <Form.Item
        name="userType"
        rules={[
          {
            required: true,
            message: '请选择用户类型',
          },
        ]}
      >
        <Select onChange={handleChange}>
          <Select.Option value="2">个人</Select.Option>
          <Select.Option value="3">企业</Select.Option>
        </Select>
      </Form.Item>
      {
        type === '3' && (
          <Form.Item
            name="creditCode"
            rules={[
              {
                required: true,
                message: '请输入统一社会信用代码',
              },
            ]}
          >
            <Input placeholder="统一社会信用代码" />
          </Form.Item>
        )
      }
      <Form.Item
        name="phone"
        rules={[
          {
            required: true,
            message: '请输入手机号码',
          },
          { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号码格式' },
        ]}
      >
        <Input placeholder="请输入手机号码" />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: '请输入验证码',
          },
          { validator: checkCode },
        ]}
      >
        <div className="code-box">
          <Input placeholder="请输入验证码" />
          <Button type="primary" disabled={time} onClick={() => getCode()} className="auth-modal__sms-btn">{time ? `${time}s` : '获取验证码'}</Button>
        </div>

      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="margin-top20">
          登录
        </Button>
      </Form.Item>
    </Form>
  );
}
