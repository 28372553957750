import {
  useState, useEffect,
} from 'react';
import {
  Button, Cascader, Checkbox, Col, Form, Input, InputNumber, Modal, Row,
  Spin, Upload, Card, message, DatePicker, Select, Space, Table, Popconfirm,
} from 'antd';
import { UploadOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import qs from 'query-string';
import moment from 'moment/moment';
import {
  debounce,
  isEmpty, isNull, isUndefined,
} from 'lodash';
import { useSelector } from 'react-redux';
import { getArea, getDictType } from '@/services/common';
import { uploadFileUrl } from '@/services/majorFinancing';
import {
  addApply, deleteTemporarilyData, getCreditGuaranteeApplicationDetail, getTemporarilyData, guaranteeTemplateDownload, saveTemporarilyData, updateBackApply,
} from '@/services/creditGuarantee';
import './index.less';
import { getFileListVal } from './fileDataUtil';
import { downloadFile, moneyDelete } from '@/utils/utils';
import ExcelUpload from '@/components/ExcelUploadParse/ExcelUploadParse';
import { COMPANY_FUND_TYPE, GUARANTEE_LEVEL } from '@/utils/mappingDict';
import useKeepAlive from '@/hooks/useKeepAlive';

const formItemLayout = {
  labelCol: {
    sm: { span: 8 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
};

const REQUIRE_STATUS = true;

export default function Application() {
  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const { search, state: passedState } = useLocation();
  const { backId } = qs.parse(search);
  const user = useSelector((state) => state.userInfo);

  const [excelData, setExcelData] = useState([]);

  const [authInfoModal, setAuthInfoModal] = useState(false); // 授权书内容

  const [licenseFiles, setLicenseFiles] = useState([]); // 营业执照
  const [agriculturalFiles, setAgriculturalFiles] = useState([]); // 2023年公司贷款担保发生总额，其中农业贷款担保发生额度
  const [openingPermitFiles, setOpeningPermitFiles] = useState([]); // 2023年担保业务台账，并加盖公章
  const [report23Files, setReport23Files] = useState([]); // 2023年农业信贷担保机构财务报告
  const [report22Files, setReport22Files] = useState([]); // 2022年农业信贷担保机构财务报告
  const [commitmentFiles, setCommitmentFiles] = useState([]); // 承诺书
  const [otherFiles, setOtherFiles] = useState([]); // 承诺书

  const { run: saveTmpRun } = useRequest(saveTemporarilyData, {
    manual: true,
  });

  const { run: getTmpRun } = useRequest(getTemporarilyData, {
    manual: true,
    onSuccess(res) {
      const { temporarily } = res;
      if (temporarily) {
        let tmpData = JSON.parse(temporarily);
        tmpData = {
          ...tmpData,
          registrationTime: moment(tmpData.registrationTime),
        };
        form.setFieldsValue(tmpData);

        // 表格数据赋值
        const { guaranteeBonusLoanVos } = tmpData;
        setExcelData(guaranteeBonusLoanVos);
      }
    },
  });

  const { run: delTmpRun } = useRequest(deleteTemporarilyData, {
    manual: true,
  });

  const { data: reApplyData, loading: applyLoading, run: backRun } = useRequest(getCreditGuaranteeApplicationDetail, {
    manual: true,
    onSuccess(res) {
      if (res) {
        const legalFiles = [];
        const creditFiles = [];
        const permitFiles = [];
        if (!isEmpty(res?.licenseFileVos)) {
          // eslint-disable-next-line array-callback-return
          res.licenseFileVos.map((item, index) => {
            legalFiles.push({
              uid: index.toString(),
              name: item.fileName,
              status: 'done',
              url: item.fileUrl,
            });
          });
        }
        if (!isEmpty(res?.agriculturalFileVos)) {
          // eslint-disable-next-line array-callback-return
          res.agriculturalFileVos.map((item, index) => {
            creditFiles.push({
              uid: index.toString(),
              name: item.fileName,
              status: 'done',
              url: item.fileUrl,
            });
          });
        }
        if (!isEmpty(res?.openingPermitFileVos)) {
          // eslint-disable-next-line array-callback-return
          res.openingPermitFileVos.map((item, index) => {
            permitFiles.push({
              uid: index.toString(),
              name: item.fileName,
              status: 'done',
              url: item.fileUrl,
            });
          });
        }
        setLicenseFiles(legalFiles);
        setAgriculturalFiles(creditFiles);
        setOpeningPermitFiles(permitFiles);

        const report23File = [];
        const report22File = [];
        const commitmentFile = [];
        const otherFile = [];
        if (!isEmpty(res?.report23FileVos)) {
          // eslint-disable-next-line array-callback-return
          res.report23FileVos.map((item, index) => {
            report23File.push({
              uid: index.toString(),
              name: item.fileName,
              status: 'done',
              url: item.fileUrl,
            });
          });
        }
        if (!isEmpty(res?.report22FileVos)) {
          // eslint-disable-next-line array-callback-return
          res.report22FileVos.map((item, index) => {
            report22File.push({
              uid: index.toString(),
              name: item.fileName,
              status: 'done',
              url: item.fileUrl,
            });
          });
        }
        if (!isEmpty(res?.commitmentFileVos)) {
          // eslint-disable-next-line array-callback-return
          res.commitmentFileVos.map((item, index) => {
            commitmentFile.push({
              uid: index.toString(),
              name: item.fileName,
              status: 'done',
              url: item.fileUrl,
            });
          });
        }
        if (!isEmpty(res?.otherFileVos)) {
          // eslint-disable-next-line array-callback-return
          res.otherFileVos.map((item, index) => {
            otherFile.push({
              uid: index.toString(),
              name: item.fileName,
              status: 'done',
              url: item.fileUrl,
            });
          });
        }
        setReport23Files(report23File);
        setReport22Files(report22File);
        setCommitmentFiles(commitmentFile);
        setOtherFiles(otherFile);

        const obj = {
          ...res,
          guaranteeLevel: res.guaranteeLevel.toString(),
          establishmentType: res.establishmentType.toString(),
          stateCapitalQuota: moneyDelete(res.stateCapitalQuota),
          scaleGrainQuota: moneyDelete(res.scaleGrainQuota),
          otherCapitalQuota: moneyDelete(res.otherCapitalQuota),
          licenseFileVos: legalFiles,
          agriculturalFileVos: creditFiles,
          openingPermitFileVos: permitFiles,
          report23FileVos: report23File,
          report22FileVos: report22File,
          commitmentFileVos: commitmentFile,
          otherFileVos: otherFile,
          projectArea: [res.provinceCode, res.cityCode, res.areaCode],
          projectAreaId: [res.provinceId, res.cityId, res.areaId],
          creditCode: res.applicantNo,
          registrationTime: res.registrationTime && moment(res.registrationTime, 'YYYY-MM-DD'),
        };

        form.setFieldsValue(obj);
        setExcelData(res?.guaranteeBonusLoanVos);
      }
    },
  });

  const { data: dict = {} } = useRequest(getDictType, { // 筛选项字典
    defaultParams: [[GUARANTEE_LEVEL, COMPANY_FUND_TYPE].join(',')],
    onSuccess() {
      if (backId) {
        backRun(backId);
      }
    },
  });

  const { data: area = [] } = useRequest(getArea, {
    onSuccess() {
      form.setFieldsValue({
        year: passedState.year,
        guaranteeName: user.displayName, // 只有公司用户可以进行担保奖补
      });
      if (!user.displayName) {
        message.error('未获取到公司名称，请刷新页面或重新登录');
      }
    },
  }); // 区域

  const { loading, run } = useRequest(addApply, {
    manual: true,
    onSuccess() {
      delTmpRun();
      Navigate('/result');
    },
  });

  const { loading: updateLoading, run: updateRun } = useRequest(updateBackApply, {
    manual: true,
    onSuccess() {
      delTmpRun();
      Navigate('/result');
    },
  });

  const { loading: templateLoading, run: downloadTemplate } = useRequest(guaranteeTemplateDownload, {
    manual: true,
    onSuccess: ({ data: fileData, filename }) => {
      downloadFile(fileData, filename);
    },
  });

  // 保证token不过期
  useKeepAlive();

  const props = {
    name: 'file',
    accept: '.rar,.zip,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.pdf',
    action: uploadFileUrl,
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        message.error('请上传200M以下文件!');
      }
      return isLt2M;
    },
    // onChange({ file, fileList }) {
    //   if (isEmpty(fileList)) {
    //     form.setFieldsValue({ projectFiles: undefined });
    //   }
    //   setList(fileList);
    //   if (file.status === 'done') {
    //     message.success('上传成功');
    //   } else if (file.status === 'error') {
    //     message.error('上传失败');
    //   }
    // },
  };

  useEffect(() => {
    getTmpRun();

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      return '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleInputChange = () => {
    const { stateCapitalQuota, otherCapitalQuota } = form.getFieldsValue(['stateCapitalQuota', 'otherCapitalQuota']);
    const totalQuota = moneyDelete(stateCapitalQuota) + moneyDelete(otherCapitalQuota);

    form.setFieldsValue({ totalQuota });
  };

  const onFinish = debounce((values) => {
    // 优先检查表格数据
    if (excelData.length === 0) {
      message.error('请上传2023年新增担保信息');
      return;
    }
    if (excelData.length > 0) {
      let isAllProveFileUploaded = true;
      excelData.forEach((item) => {
        if (!item.guaranteeBonusLoanFileVos) {
          isAllProveFileUploaded = false;
        }
      });

      if (!isAllProveFileUploaded) {
        message.error('请为每条担保信息上传证明材料');
        return;
      }
    }

    const obj = {
      ...values,
      guaranteeBonusId: !isNull(passedState) ? passedState.id : reApplyData.guaranteeBonusId,
      applicantId: user.id,
      guaranteeName: user.displayName, // 只有公司用户可以进行担保奖补
      id: backId,
      registrationTime: values.registrationTime && moment(values.registrationTime).format('YYYY-MM-DD'),
    };

    if (backId) {
      const [provC, cityC, areaC] = values.projectAreaId;
      obj.provinceCode = provC.toString();
      obj.cityCode = cityC.toString();
      obj.areaCode = areaC.toString();
      // const [provI, cityI, areaI] = values.projectArea;
      // obj.provinceId = provI;
      // obj.cityId = cityI;
      // obj.areaId = areaI;
    } else {
      const [provC, cityC, areaC] = values.projectArea;
      obj.provinceId = provC;
      obj.cityId = cityC;
      obj.areaId = areaC;
    }

    // 公司账号文件上传
    if (!isEmpty(obj.licenseFileVos)) {
      const { fileList } = obj.licenseFileVos;
      obj.licenseFileVos = getFileListVal({ fileList: isUndefined(fileList) ? obj.licenseFileVos : fileList });
    }
    if (!isEmpty(obj.agriculturalFileVos)) {
      const { fileList } = obj.agriculturalFileVos;
      obj.agriculturalFileVos = getFileListVal({ fileList: isUndefined(fileList) ? obj.agriculturalFileVos : fileList });
    }
    if (!isEmpty(obj.openingPermitFileVos)) {
      const { fileList } = obj.openingPermitFileVos;
      obj.openingPermitFileVos = getFileListVal({ fileList: isUndefined(fileList) ? obj.openingPermitFileVos : fileList });
    }
    if (!isEmpty(obj.report23FileVos)) {
      const { fileList } = obj.report23FileVos;
      obj.report23FileVos = getFileListVal({ fileList: isUndefined(fileList) ? obj.report23FileVos : fileList });
    }
    if (!isEmpty(obj.report22FileVos)) {
      const { fileList } = obj.report22FileVos;
      obj.report22FileVos = getFileListVal({ fileList: isUndefined(fileList) ? obj.report22FileVos : fileList });
    }
    if (!isEmpty(obj.commitmentFileVos)) {
      const { fileList } = obj.commitmentFileVos;
      obj.commitmentFileVos = getFileListVal({ fileList: isUndefined(fileList) ? obj.commitmentFileVos : fileList });
    }
    if (!isEmpty(obj.otherFileVos)) {
      const { fileList } = obj.otherFileVos;
      obj.otherFileVos = getFileListVal({ fileList: isUndefined(fileList) ? obj.otherFileVos : fileList });
    }

    obj.guaranteeBonusLoanVos = excelData.map((item) => {
      const { fileList, fileUrl } = item.guaranteeBonusLoanFileVos;
      const mappedList = getFileListVal({ fileList: fileList || [item.guaranteeBonusLoanFileVos] });

      // 如果backId存在，且fileUrl不存在，则说明用户删除重新上传过，此时需要加上fileList
      const parseList = backId && !fileUrl ? { fileList: [item.guaranteeBonusLoanFileVos] } : [item.guaranteeBonusLoanFileVos];
      const backMappedList = getFileListVal(parseList);

      return {
        ...item,
        guaranteeBonusLoanFileVos: backId ? backMappedList[0] : mappedList[0],
      };
    });

    if (backId) {
      // 退回记录从新申请
      updateRun(obj);
    } else {
      // 新申请
      run(obj);
    }
  }, 1000);

  // MARK 获取表单临时数据
  const saveTempInputedData = (excel = []) => {
    const values = form.getFieldsValue();

    const objStr = JSON.stringify({
      ...values,
      guaranteeBonusLoanVos: excel,
    });
    const saveParams = {
      applicantId: user.id,
      guaranteeName: user.displayName,
      id: backId,
      temporarily: objStr,
    };
    saveTmpRun(saveParams);
  };

  const validateDuration = (duration) => {
    const regex = /^(\d{4}-\d{2}-\d{2}).*(\d{4}-\d{2}-\d{2})$/;
    const match = duration.match(regex);
    if (match) {
      const startDate = match[1];
      const endDate = match[2];
      const isStartDateValid = !Number.isNaN(new Date(startDate).getTime());
      const isEndDateValid = !Number.isNaN(new Date(endDate).getTime());
      return [isStartDateValid && isEndDateValid, startDate, endDate];
    }
    return [false, '', ''];
  };
  /**
   * 解析excel数据
   * @param {object} data
   */
  const handleExcelDataParsed = (data) => {
    const requiredFields = [
      '担保对象',
      '分类\n（粮食生产经营或现代农业发展信贷）',
      '担保合同号',
      '担保类别\n（担保、联保或再担保）',
      '担保起止时间\n(yyyy-mm-dd~yyyy-mm-dd)',
      '协作金融机构名称',
      '担保责任额（万元）',
      '当年新发生额（万元）',
    ];
    // 只读取excel中完整的数据
    const filteredData = data.filter((item) => requiredFields.every((field) => field in item && item[field] !== ''));

    const mappedData = filteredData.map((item) => ({
      guaranteeObject: item[requiredFields[0]],
      guaranteeSort: item[requiredFields[1]],
      guaranteeIou: item[requiredFields[2]],
      guaranteeType: item[requiredFields[3]],
      duration: item[requiredFields[4]],
      collaboratingFinancial: item[requiredFields[5]],
      guaranteeLiabilityQuota: item[requiredFields[6]],
      newAmount: item[requiredFields[7]],
      guaranteeBonusLoanFileVos: undefined,
    })) // 筛选掉excel中的空行
      .filter((item) => item.guaranteeLiabilityQuota !== '' && item.newAmount !== '');

    let isAllValidate = true;
    for (let i = 0; i < mappedData.length; i++) {
      const item = mappedData[i];
      const result = validateDuration(item.duration);
      const [matched, startTime, endTime] = result;
      isAllValidate = isAllValidate && matched;
      item.guaranteeStart = startTime;
      item.guaranteeEnd = endTime;
      item.id = i;
    }

    if (isAllValidate) {
      setExcelData(mappedData);
      message.success('解析完成');
      saveTempInputedData(mappedData);
    } else {
      message.error('文件解析失败，请检查格式是否正确');
    }
  };

  const handleProveFileUpload = (fileVo, record) => {
    const { file } = fileVo;
    if (file.status === 'done') {
      const newData = excelData.map((item) => {
        if (item.id === record.id) {
          return { ...item, guaranteeBonusLoanFileVos: file };
        }
        return item;
      });
      setExcelData(newData);
      message.success('证明材料上传成功!');
      saveTempInputedData(newData);
    } else if (file.status === 'error') {
      message.error('上传失败');
    }
  };

  const handleProveFileRemove = (record) => {
    const newData = excelData.map((item) => {
      if (item.guaranteeIou === record.guaranteeIou) {
        return { ...item, guaranteeBonusLoanFileVos: undefined };
      }
      return item;
    });
    setExcelData(newData);
    message.info('证明材料已删除!');

    saveTempInputedData(newData);
  };

  function checkFileUploading(_, value) {
    const promise = Promise;

    if (!isUndefined(value)) {
      const { event } = value;
      if (!isUndefined(event) && event.type === 'progress') {
        return promise.reject('请等待文件上传完成');
      }
    }

    return promise.resolve();
  }

  function getValueFromEvent(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  function checkAuthorize(_, value) {
    const promise = Promise;

    if (!value) {
      return promise.reject('请勾选同意承诺函');
    }
    return promise.resolve();
  }

  const columns = [
    {
      title: '担保对象',
      dataIndex: 'guaranteeObject',
    },
    {
      title: '分类',
      dataIndex: 'guaranteeSort',
    },
    {
      title: '担保合同号',
      dataIndex: 'guaranteeIou',
    },
    {
      title: '担保类别',
      dataIndex: 'guaranteeType',
    },
    {
      title: '担保起止时间',
      dataIndex: 'guaranteeStart',
      render: (text, record) => {
        const { guaranteeEnd } = record;
        return `${text}~${guaranteeEnd}`;
      },
    },
    {
      title: '协作金融机构名称',
      dataIndex: 'collaboratingFinancial',
    },
    {
      title: '担保责任额（万元）',
      dataIndex: 'guaranteeLiabilityQuota',
    },
    {
      title: '当年新发生额（万元）',
      dataIndex: 'newAmount',
    },
    {
      title: '证明材料',
      dataIndex: 'guaranteeBonusLoanFileVos',
      width: 150,
      render: (text, record) => {
        const { webConfirm } = record;

        let displayName = '';
        if (text) {
          const { name, fileName } = text;
          displayName = name ?? fileName;
        }
        return (
          <Space>
            {text ? (
              <Popconfirm
                title="确定要删除吗？"
                onConfirm={() => handleProveFileRemove(record)}
                // 重新申请时，非补充资料的数据，无法修改上传文件
                disabled={backId && webConfirm !== 3}
              >
                <div
                  style={{
                    color: (backId && webConfirm !== 3) ? 'black' : '#107CEE', marginRight: 10, cursor: 'pointer', wordWrap: 'break-word', wordBreak: 'break-word',
                  }}
                >
                  <Space>
                    <DeleteOutlined />
                    {displayName}
                  </Space>
                </div>
              </Popconfirm>
            ) : (
              <Upload
                {...props}
                onChange={(fileVo) => handleProveFileUpload(fileVo, record)}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>上传</Button>
              </Upload>
            )}
          </Space>
        );
      },
    },
  ];

  return (
    <div className="major-financing-application">
      <div className="content-width">
        {/* <div className="major-financing-application__title">重大融资申请</div> */}
        <div className="margin-top20">
          <Spin spinning={applyLoading}>
            <Form
              {...formItemLayout}
              form={form}
              name="form"
              className="form"
              onFinish={onFinish}
            >

              <Card title="基本信息" className="form-info__box">
                <Row>
                  <Col span={12}>
                    <Row>
                      <Col span={18}>
                        <Form.Item
                          name="guaranteeName"
                          label="农业担保公司名称"
                          labelCol={{ span: 10 }}
                          wrapperCol={{ span: 14 }}
                          rules={[
                            {
                              required: REQUIRE_STATUS,
                              message: '请输入农业担保公司名称',
                            },
                          ]}
                        >
                          <Input placeholder="请输入农业担保公司名称" disabled />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          name="guaranteeLevel"
                          labelCol={{ span: 0 }}
                          wrapperCol={{ span: 24 }}
                          rules={[
                            {
                              required: REQUIRE_STATUS,
                              message: '请选择级别',
                            },
                          ]}
                        >
                          <Select placeholder="请选择级别" style={{ width: '100%' }} disabled={backId}>
                            {
                              dict?.guaranteeLevel?.map((item) => (
                                <Select.Option value={item.val} key={item.val}>
                                  {item.name}
                                </Select.Option>
                              ))
                            }
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="projectArea"
                      label="注册地点"
                      rules={[
                        {
                          required: REQUIRE_STATUS,
                          message: '请选择注册地点',
                        },
                      ]}
                    >
                      <Cascader
                        options={area}
                        placeholder="请选择注册地点"
                        fieldNames={{ label: 'name', value: 'id', children: 'children' }}
                        onChange={
                          (value) => {
                            // 赋值给后端字段
                            form.setFieldsValue({ projectAreaId: value });
                          }
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Form.Item name="projectAreaId" />

                  <Col span={12}>
                    <Form.Item
                      name="year"
                      label="申报年度"
                      rules={[
                        {
                          required: REQUIRE_STATUS,
                          message: '请输入申报年度',
                        },
                      ]}
                    >
                      <Input placeholder="请输入申报年度" disabled />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="registrationTime"
                      label="注册登记时间"
                      rules={[
                        {
                          required: REQUIRE_STATUS,
                          message: '请选择注册登记时间',
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="请选择注册登记时间"
                        style={{ width: '100%' }}
                        disabledDate={
                          (current) => current && current > moment().startOf('day')
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="establishmentType"
                      label="设立方式"
                      rules={[
                        {
                          required: REQUIRE_STATUS,
                          message: '请选择设立方式',
                        },
                      ]}
                    >
                      <Select placeholder="请选择设立方式" style={{ width: '100%' }}>
                        {
                          dict?.establishmentType?.map((item) => (
                            <Select.Option value={item.val} key={item.val}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      name="practitioner"
                      label="从业人员"
                      rules={[
                        {
                          required: REQUIRE_STATUS,
                          message: '请输入从业人员',
                        },
                        {
                          validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('从业人员必须大于0'))),
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="请输入从业人员"
                        addonAfter="人"
                        min={0}
                        max={99999999}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Card title="注册资本金情况" style={{ marginBottom: 24 }}>
                      <Row>

                        <Col span={12}>
                          <Form.Item
                            name="stateCapitalQuota"
                            label="国有资本"
                            rules={[
                              {
                                required: REQUIRE_STATUS,
                                message: '请输入国有资本',
                              },
                              // {
                              //   validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('国有资本必须大于0'))),
                              // },
                            ]}
                          >
                            <InputNumber
                              placeholder="请输入国有资本"
                              addonAfter="万元"
                              min={0}
                              max={99999999}
                              style={{ width: '100%' }}
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            name="scaleGrainQuota"
                            label="其中粮食适度规模经营补助资金"
                            labelCol={{
                              sm: { span: 10 },
                            }}
                            wrapperCol={{
                              sm: { span: 14 },
                            }}
                            rules={[
                              {
                                required: REQUIRE_STATUS,
                                message: '请输入其中粮食适度规模经营补助资金',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="如果无，请填0"
                              addonAfter="万元"
                              min={0}
                              max={99999999}
                              style={{ width: '100%' }}
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            name="otherCapitalQuota"
                            label="其他资本"
                            rules={[
                              {
                                required: REQUIRE_STATUS,
                                message: '请输入其他资本',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="如果无，请填0"
                              addonAfter="万元"
                              min={0}
                              max={99999999}
                              style={{ width: '100%' }}
                              onChange={handleInputChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            name="totalQuota"
                            label="合计"
                            rules={[
                              {
                                required: REQUIRE_STATUS,
                                message: '请输入合计金额',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="请输入合计金额"
                              addonAfter="万元"
                              style={{ width: '100%' }}
                              disabled
                            />
                          </Form.Item>
                        </Col>

                      </Row>
                    </Card>
                  </Col>

                  <Col span={24}>
                    <Card title="贷款担保情况" style={{ marginBottom: 24 }}>
                      <Row>
                        <Col span={12}>
                          <Form.Item
                            name="guaranteeQuota"
                            label="2023年贷款担保新发生额"
                            rules={[
                              {
                                required: REQUIRE_STATUS,
                                message: '请输入2023年贷款担保新发生额',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="请输入2023年贷款担保新发生额"
                              addonAfter="万元"
                              min={0}
                              max={99999999}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            name="agriculturalGuaranteeQuota"
                            label="其中农业贷款担保新发生额"
                            labelCol={{
                              sm: { span: 10 },
                            }}
                            wrapperCol={{
                              sm: { span: 14 },
                            }}
                            rules={[
                              {
                                required: REQUIRE_STATUS,
                                message: '请输入其中农业贷款担保新发生额',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="请输入其中农业贷款担保新发生额"
                              addonAfter="万元"
                              min={0}
                              max={99999999}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            name="warrantyQuota"
                            label="2023年底在保余额"
                            rules={[
                              {
                                required: REQUIRE_STATUS,
                                message: '请输入2023年底在保余额',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="请输入2023年底在保余额"
                              addonAfter="万元"
                              min={0}
                              max={99999999}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            name="agriculturalWarrantyQuota"
                            label="其中农业贷款担保在保余额"
                            labelCol={{
                              sm: { span: 10 },
                            }}
                            wrapperCol={{
                              sm: { span: 14 },
                            }}
                            rules={[
                              {
                                required: REQUIRE_STATUS,
                                message: '请输入其中农业贷款担保在保余额',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="请输入其中农业贷款担保在保余额"
                              addonAfter="万元"
                              min={0}
                              max={99999999}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            name="guaranteeCompensationQuota"
                            label="2022年担保代偿额"
                            rules={[
                              {
                                required: REQUIRE_STATUS,
                                message: '请输入2022年担保代偿额',
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="请输入2022年担保代偿额"
                              addonAfter="万元"
                              min={0}
                              max={99999999}
                              style={{ width: '100%' }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      name="remark"
                      label="备注"
                      labelCol={{
                        sm: { span: 4 },
                      }}
                      wrapperCol={{
                        sm: { span: 20 },
                      }}
                    >
                      <Input.TextArea maxLength={200} showCount placeholder="请输入备注" />
                    </Form.Item>
                  </Col>

                </Row>
              </Card>

              <Card
                title="2023年新增担保信息"
                className="form-info__box"
                extra={
                  (
                    <Space>
                      <Button
                        key="download"
                        icon={<DownloadOutlined />}
                        type="primary"
                        ghost
                        loading={templateLoading}
                        onClick={downloadTemplate}
                      >
                        模版下载
                      </Button>
                      <ExcelUpload onDataParsed={handleExcelDataParsed} disabled={backId} />
                    </Space>
                  )
                }
              >
                <h4>
                  <span style={{ color: 'red' }}>*</span>
                  每笔担保贷款的合同、到账凭证、保费收取凭证扫描在一起作为证明材料上传
                </h4>
                <Table
                  dataSource={excelData}
                  columns={columns}
                  rowKey="id"
                  pagination={{
                    showSizeChanger: true, // 允许用户改变每页显示条数
                    showQuickJumper: true, // 允许用户快速跳转至某页
                  }}
                />
              </Card>

              <Card title="其他资料" className="form-info__box">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="licenseFileVos"
                      label="营业执照（法人代表证、设立批文）"
                      valuePropName="fileList"
                      getValueFromEvent={getValueFromEvent}
                      rules={[
                        {
                          required: REQUIRE_STATUS,
                          message: '请上传营业执照（法人代表证、设立批文）',
                        },
                        {
                          validator: checkFileUploading,
                        },
                      ]}
                    >
                      <Upload
                        {...props}
                        fileList={licenseFiles}
                        onPreview={() => { }}
                        onChange={
                          ({ file, fileList }) => {
                            setLicenseFiles(fileList);
                            if (file.status === 'done') {
                              message.success('上传成功');
                              saveTempInputedData(excelData);
                            } else if (file.status === 'error') {
                              message.error('上传失败');
                            }
                          }
                        }
                      >
                        <Button icon={<UploadOutlined />}>上传文件</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="agriculturalFileVos"
                      label="2023年公司贷款担保发生总额，其中农业贷款担保发生额度"
                      valuePropName="fileList"
                      getValueFromEvent={getValueFromEvent}
                      rules={[
                        {
                          required: REQUIRE_STATUS,
                          message: '2023年公司贷款担保发生总额，其中农业贷款担保发生额度',
                        },
                        {
                          validator: checkFileUploading,
                        },
                      ]}
                    >
                      <Upload
                        {...props}
                        fileList={agriculturalFiles}
                        onPreview={() => { }}
                        onChange={
                          ({ file, fileList }) => {
                            setAgriculturalFiles(fileList);
                            if (file.status === 'done') {
                              message.success('上传成功');
                              saveTempInputedData(excelData);
                            } else if (file.status === 'error') {
                              message.error('上传失败');
                            }
                          }
                        }
                      >
                        <Button icon={<UploadOutlined />}>上传文件</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="openingPermitFileVos"
                      label="2023年担保业务台账，并加盖公章"
                      valuePropName="fileList"
                      getValueFromEvent={getValueFromEvent}
                      rules={[
                        {
                          required: REQUIRE_STATUS,
                          message: '请上传2023年担保业务台账，并加盖公章',
                        },
                        {
                          validator: checkFileUploading,
                        },
                      ]}
                    >
                      <Upload
                        {...props}
                        fileList={openingPermitFiles}
                        onPreview={() => { }}
                        onChange={
                          ({ file, fileList }) => {
                            setOpeningPermitFiles(fileList);
                            if (file.status === 'done') {
                              message.success('上传成功');
                              saveTempInputedData(excelData);
                            } else if (file.status === 'error') {
                              message.error('上传失败');
                            }
                          }
                        }
                      >
                        <Button icon={<UploadOutlined />}>上传文件</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="report23FileVos"
                      label="2023年农业信贷担保机构财务报告"
                      valuePropName="fileList"
                      getValueFromEvent={getValueFromEvent}
                      rules={[
                        {
                          required: REQUIRE_STATUS,
                          message: '请上传2023年农业信贷担保机构财务报告',
                        },
                        {
                          validator: checkFileUploading,
                        },
                      ]}
                    >
                      <Upload
                        {...props}
                        fileList={report23Files}
                        onPreview={() => { }}
                        onChange={
                          ({ file, fileList }) => {
                            setReport23Files(fileList);
                            if (file.status === 'done') {
                              message.success('上传成功');
                              saveTempInputedData(excelData);
                            } else if (file.status === 'error') {
                              message.error('上传失败');
                            }
                          }
                        }
                      >
                        <Button icon={<UploadOutlined />}>上传文件</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="report22FileVos"
                      label="2022年农业信贷担保机构财务报告"
                      valuePropName="fileList"
                      getValueFromEvent={getValueFromEvent}
                      rules={[
                        {
                          required: REQUIRE_STATUS,
                          message: '请上传2022年农业信贷担保机构财务报告',
                        },
                        {
                          validator: checkFileUploading,
                        },
                      ]}
                    >
                      <Upload
                        {...props}
                        fileList={report22Files}
                        onPreview={() => { }}
                        onChange={
                          ({ file, fileList }) => {
                            setReport22Files(fileList);
                            if (file.status === 'done') {
                              message.success('上传成功');
                              saveTempInputedData(excelData);
                            } else if (file.status === 'error') {
                              message.error('上传失败');
                            }
                          }
                        }
                      >
                        <Button icon={<UploadOutlined />}>上传文件</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="commitmentFileVos"
                      label={(
                        <div>
                          承诺书
                          <Button
                            type="link"
                            icon={<DownloadOutlined />}
                            onClick={
                              () => {
                                const a = document.createElement('a');
                                a.href = './TLP/tlp3_creditGuarantee.docx';
                                a.download = '承诺书.docx';
                                a.click();
                              }
                            }
                          >
                            模板下载
                          </Button>
                        </div>
                      )}
                      valuePropName="fileList"
                      getValueFromEvent={getValueFromEvent}
                      rules={[
                        {
                          required: REQUIRE_STATUS,
                          message: '请上传承诺书',
                        },
                        {
                          validator: checkFileUploading,
                        },
                      ]}
                    >
                      <Upload
                        {...props}
                        fileList={commitmentFiles}
                        onPreview={() => { }}
                        onChange={
                          ({ file, fileList }) => {
                            setCommitmentFiles(fileList);
                            if (file.status === 'done') {
                              message.success('上传成功');
                              saveTempInputedData(excelData);
                            } else if (file.status === 'error') {
                              message.error('上传失败');
                            }
                          }
                        }
                      >
                        <Button icon={<UploadOutlined />}>上传文件</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="otherFileVos"
                      label="其他必要材料"
                    >
                      <Upload
                        {...props}
                        fileList={otherFiles}
                        onPreview={() => { }}
                        onChange={
                          ({ file, fileList }) => {
                            setOtherFiles(fileList);
                            if (file.status === 'done') {
                              message.success('上传成功');
                              saveTempInputedData(excelData);
                            } else if (file.status === 'error') {
                              message.error('上传失败');
                            }
                          }
                        }
                      >
                        <Button icon={<UploadOutlined />}>上传文件</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>

              <div className="authorize">
                <Form.Item
                  name="authorize"
                  wrapperCol={{ span: 24 }}
                  valuePropName="checked"
                  rules={[
                    { validator: checkAuthorize },
                  ]}

                >
                  <Checkbox
                    onChange={(e) => {
                      form.setFieldsValue({ authorize: false });
                      if (e.target.checked) {
                        setAuthInfoModal(true);
                      }
                    }}
                  >
                    同意并接受
                    <Button type="link" className="authorize-name" onClick={() => setAuthInfoModal(true)}>《承诺函》</Button>
                  </Checkbox>
                </Form.Item>
              </div>
              <div className="submit-btn-box">
                <Button type="primary" htmlType="submit" loading={loading || updateLoading}>
                  提交申请
                </Button>
              </div>
            </Form>
          </Spin>
          <Modal
            title="承诺函"
            open={authInfoModal}
            footer={
              [
                <Button key="skip" onClick={() => setAuthInfoModal(false)}>
                  取消
                </Button>,
                <Button
                  type="primary"
                  key="confirm"
                  onClick={() => {
                    setAuthInfoModal(false);
                    form.setFieldsValue({ authorize: true });
                  }}
                >
                  确认
                </Button>,
              ]
            }
            onCancel={() => setAuthInfoModal(false)}
          >
            <div>
              本机构承诺在申报2024年农业信贷担保财政奖补时提交的所有材料真实、合法、有效，若发现材料存在虚假、瞒报等情况，本人（企业、合作社）自愿放弃本次申报，退还获得的财政补贴资金，并承担有关部门追责。
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}
