import { bool, object } from 'prop-types';
import { Button, Tooltip, Rate } from 'antd';
import { FireFilled } from '@ant-design/icons';
import './BankCard.less';

/**
* 银行产品card
* @param {object} data 数据源
* @param {bool} isShow 是否显示按钮
* */

export default function BankCard({ data, isShow }) {
  function handleChange(id) {
    window.open(`#/bank/detail?id=${id}`, '_blank');
  }

  return (
    <div className="bank-product-card">
      <div className="bank-product-card__top">
        <div className="bank-product-card__name">
          <div className="bank-product-card__img">
            <img src={data?.url} alt="" height={30} />
          </div>
          <div>{data?.productName}</div>
        </div>
        {
          !isShow
          && (
          <Button type="primary" className="bank-product-btn" onClick={() => handleChange(data.productId)}>
            立即申请
          </Button>
          )
        }

      </div>
      <div className="bank-product-card__info">
        <div>
          <div className="bank-product-card__label">参考年利率</div>
          <div className="bank-product-card__num-title loanRate-ellipsis">
            <Tooltip title={data?.loanRate}>
              <span>{Number(data?.loanRate) ? `${data?.loanRate}%` : data?.loanRate || '-'}</span>
            </Tooltip>
          </div>
        </div>
        <div>
          <div className="bank-product-card__label">贷款额度</div>
          <div className="bank-product-card__num-title">
            {data?.loanAmount}
            <span className="bank-product-card__sub-title">万元</span>
          </div>
        </div>
        <div>
          <div className="bank-product-card__label">贷款期限</div>
          <div className="bank-product-card__num-title">
            {data?.loanService}
            <span className="bank-product-card__sub-title">个月</span>
          </div>
        </div>
        <div>
          <div className="bank-product-card__label">适用对象</div>
          <div className="bank-product-card__bold-title">{data?.applicantType}</div>
        </div>

        <div>
          <div className="bank-product-card__label">受理区域</div>
          <div className="bank-product-card__bold-title loanRate-ellipsis">
            <Tooltip title={data?.productRegion}>
              <span>{data?.productRegion}</span>
            </Tooltip>
          </div>
        </div>
        <div>
          <div className="bank-product-card__label">累计放款</div>
          <div className="bank-product-card__num-title">
            {data?.bankLoanMoney}
            <span className="bank-product-card__sub-title">笔</span>
          </div>
        </div>
        <div className="bank-product-card__label">
          <div>产品热度</div>
          <div>
            <Rate defaultValue={data?.heat} character={<FireFilled />} disabled />
          </div>
        </div>
      </div>
    </div>
  );
}

BankCard.propTypes = {
  data: object,
  isShow: bool,
};
