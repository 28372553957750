import { Empty } from 'antd';
import { number } from 'prop-types';
import emptyImg from '@/assets/empty.png';

export default function EmptyImg({ height }) {
  return (
    <div style={{ height }} className="empty-img">
      <Empty image={emptyImg} />
    </div>
  );
}

EmptyImg.propTypes = {
  height: number,
};
