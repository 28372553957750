import AppLayout from '@/pages/layout/index';
import Home from '@/pages/home';
import Bank from '@/pages/bank/List';
import Guarantee from '@/pages/guarantee/Detail';
import Insurance from '@/pages/insurance/List';
import Policy from '@/pages/policy/List';
import About from '@/pages/about';
import Login from '@/pages/login';
import BankDetail from '@/pages/bank/Detail';
import BankApplication from '@/pages/bank/Application';
import InsuranceDetail from '@/pages/insurance/Detail';
import GuaranteeApplication from '@/pages/guarantee/Application';
import PolicyDetail from '@/pages/policy/Detail';
import Result from '@/pages/result';
import Register from '@/pages/register';
import User from '@/pages/user';
import MyApplication from '@/pages/myApplication/List';
import MyApplicationDetail from '@/pages/myApplication/Detail';
import Mechanism from '@/pages/home/Mechanism';
import Authentication from '@/pages/authentication';
import MajorFinancing from '@/pages/majorFinancing/Main';
import MajorFinancingApplication from '@/pages/majorFinancing/Application';
import Capital from '@/pages/capital/List';
import CapitalDetail from '@/pages/capital/Detail';
import FacilityLoan from '@/pages/facilityLoan';
import FacilityLoanDetail from '@/pages/facilityLoan/Detail';
import FacilityLoanApplication from '@/pages/facilityLoan/Application';

import IndustryBankLoan from '@/pages/industryBankLoan';
import IndustryBankLoanDetail from '@/pages/industryBankLoan/Detail';
import IndustryBankLoanApplication from '@/pages/industryBankLoan/Application';

import CreditGuarantee from '@/pages/creditGuarantee';
import CreditGuaranteeDetail from '@/pages/creditGuarantee/Detail';
import CreditGuaranteeApplication from '@/pages/creditGuarantee/Application';

export const routers = [
  {
    path: '/',
    element: <AppLayout />,
    children: [
      { path: '/home', element: (<Home />) },
      { path: '/bank', element: (<Bank />) },
      { path: '/guarantee', element: (<Guarantee />) },
      { path: '/insurance', element: (<Insurance />) },
      { path: '/policy', element: (<Policy />) },
      { path: '/about', element: (<About />) },
      { path: '/result', element: (<Result />) },
      { path: '/bank/detail', element: (<BankDetail />) },
      { path: '/bank/application', element: (<BankApplication />) },
      { path: '/insurance/detail', element: (<InsuranceDetail />) },
      { path: '/guarantee/application', element: (<GuaranteeApplication />) },
      { path: '/policy/detail', element: (<PolicyDetail />) },
      { path: '/user', element: (<User />) },
      { path: '/myApplication', element: (<MyApplication />) },
      { path: '/myApplication/detail', element: (<MyApplicationDetail />) },
      { path: '/home/mechanism', element: (<Mechanism />) },
      { path: '/authentication', element: (<Authentication />) },
      { path: '/majorFinancing', element: (<MajorFinancing />) },
      { path: '/majorFinancing/application', element: (<MajorFinancingApplication />) },
      { path: '/capital', element: (<Capital />) },
      { path: '/capital/detail', element: (<CapitalDetail />) },
      { path: '/facilityLoan', element: (<FacilityLoan />) },
      { path: '/facilityLoan/detail', element: (<FacilityLoanDetail />) },
      { path: '/facilityLoan/application', element: (<FacilityLoanApplication />) },

      { path: '/industryBankLoan', element: (<IndustryBankLoan />) },
      { path: '/IndustryBankLoan/detail', element: (<IndustryBankLoanDetail />) },
      { path: '/industryBankLoan/application', element: (<IndustryBankLoanApplication />) },

      { path: '/creditGuarantee', element: (<CreditGuarantee />) },
      { path: '/creditGuarantee/detail', element: (<CreditGuaranteeDetail />) },
      { path: '/creditGuarantee/application', element: (<CreditGuaranteeApplication />) },
    ],
  },
  {
    path: '/login',
    element: (<Login />),
  },
  { path: '/register', element: (<Register />) },
];
