import { Button, Spin, Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import qs from 'query-string';
import { getBankDetail } from '@/services/bank';
import { getProductNum } from '@/services/common';
import productIcon from '@/assets/bank/product_icon.png';
import applyIcon from '@/assets/bank/apply_icon.png';
import handleIcon from '@/assets/bank/handle_icon.png';

import './index.less';

export default function Detail() {
  const Navigate = useNavigate();
  const { search } = useLocation();
  const productId = qs.parse(search).id;

  const { data, loading } = useRequest(() => getBankDetail(productId));
  useRequest(() => getProductNum({ productId, productType: 1, platformType: 1 }));

  function handleChange() {
    Navigate('/bank/application', { state: data });
  }

  return (
    <div className="yhzd-product-info">
      <Spin spinning={loading}>
        <div className="content-width yhzd-product-info__box">
          <div className="yhzd-product-info__top">
            <div>
              <div>
                <img src={data?.url} alt="" className="yhzd-product-info__logo" />
              </div>
              <div className="yhzd-product-info__title">{data?.productName}</div>
              <div className="yhzd-product-info__bank-name">{data?.publishOrgName}</div>
            </div>
            <div className="yhzd-product-info__detail">
              <div>
                <div className="yhzd-product-info__label">参考年利率</div>
                <div className="yhzd-product-info__num-title loanRate-ellipsis">
                  <Tooltip title={data?.loanRate}>
                    <span>{Number(data?.loanRate) ? `${data?.loanRate}%` : data?.loanRate || '-'}</span>
                  </Tooltip>
                </div>
              </div>
              <div>
                <div className="yhzd-product-info__label">贷款额度</div>
                <div className="yhzd-product-info__num-title">
                  {data?.loanAmount}
                  <span className="yhzd-product-info__sub-title">万元</span>
                </div>
              </div>
              <div>
                <div className="yhzd-product-info__label">贷款期限</div>
                <div className="yhzd-product-info__num-title">
                  {data?.loanService}
                  <span className="yhzd-product-info__sub-title">个月</span>
                </div>
              </div>
              <div>
                <div className="yhzd-product-info__label">适用对象</div>
                <div className="yhzd-product-info__bold-title">{data?.applicantType}</div>
              </div>
              <div>
                <div className="yhzd-product-info__label">受理区域</div>
                <div className="yhzd-product-info__bold-title loanRate-ellipsis">
                  <Tooltip title={data?.productRegion}>
                    <span>{data?.productRegion}</span>
                  </Tooltip>
                </div>
              </div>
              <div>
                <div className="yhzd-product-info__label">累计放款</div>
                <div className="yhzd-product-info__num-title">
                  {data?.bankLoanMoney}
                  <span className="yhzd-product-info__sub-title">笔</span>
                </div>
              </div>
              <div>
                <div className="yhzd-product-info__label">还款方式</div>
                <div className="yhzd-product-info__bold-title loanRate-ellipsis">
                  <Tooltip title={data?.loanModel}>
                    <span>{data?.loanModel || '--'}</span>
                  </Tooltip>
                </div>
              </div>
              <div>
                <div className="yhzd-product-info__label">担保类型</div>
                <div className="yhzd-product-info__bold-title loanRate-ellipsis">
                  <Tooltip title={data?.guaranteeModesName}>
                    <span>{data?.guaranteeModesName || '--'}</span>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="yhzd-product-info__con">
            <div>
              <div className="yhzd-product-info__con-title">
                <img src={productIcon} alt="" />
                <div>产品介绍</div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: data?.loanInfo }} className="yhzd-product-info__rich-text" />
            </div>
            <div>
              <div className="yhzd-product-info__con-title">
                <img src={applyIcon} alt="" />
                <div>申请条件</div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: data?.applyCondition }} className="yhzd-product-info__rich-text" />
            </div>
            <div>
              <div className="yhzd-product-info__con-title">
                <img src={handleIcon} alt="" />
                <div>办理材料</div>
              </div>
              <div dangerouslySetInnerHTML={{ __html: data?.handMaterial }} className="yhzd-product-info__rich-text" />
            </div>

          </div>
          <div className="yhzd-product-info__btn">
            <Button type="primary" onClick={() => handleChange()}>立即申请</Button>
          </div>
        </div>
      </Spin>
    </div>
  );
}
