import { Pagination, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { isEmpty } from 'lodash';
import FixMenu from '@/components/FixMenu/FixMenu';
import { policyType } from '@/utils/mappingDict';
import EmptyImg from '@/components/EmptyImg/EmptyImg';
import { getPolicy } from '@/services/policy';

import './index.less';

export default function Index() {
  const Navigate = useNavigate();

  const { data, loading, run } = useRequest((pageNum) => getPolicy({ pageNum }));

  const onPageChange = (page) => {
    run(page);
  };

  function handleChange(id) {
    Navigate(`/policy/detail?id=${id}`);
  }

  function getData(date, type) {
    const year = date?.split('-');
    if (type === 'day') {
      return `${year?.[2]}`;
    }
    return `${year?.[0]}-${year?.[1]}`;
  }

  return (
    <div className="zczx">
      <div className="zczx__banner">
        政策发布
      </div>
      <Spin spinning={loading}>
        {
                    isEmpty(data?.items) ? (
                      <EmptyImg height={300} />
                    ) : (
                      <div className="content-width zczx__con">
                        {
                                data?.items?.map((item) => (
                                  <div key={item.id} className="zczx__con-li" onClick={() => handleChange(item.id)}>
                                    <div className="zczx__con-li-time">
                                      <div>{getData(item.gmtPublish, 'day')}</div>
                                      <div>{getData(item.gmtPublish)}</div>
                                    </div>
                                    <div className="zczx__con-r">
                                      <div className="zczx__con-li-title">
                                        <span>{item?.title}</span>
                                        <span className={policyType[item.module]?.class}>{policyType[item.module]?.name}</span>
                                      </div>
                                      <div className="zczx__con-li-text">{item.content.replace(/<\/?[^>]*>|[ ]|[&nbsp;]/g, '')}</div>
                                    </div>
                                  </div>
                                ))
                            }
                        <div className="pagination-box">
                          <Pagination showQuickJumper defaultCurrent={data?.pageNum} total={data?.totalRows} onChange={onPageChange} />
                        </div>
                      </div>
                    )
                }

      </Spin>
      <FixMenu />
    </div>
  );
}
