import {
  Button, Tooltip,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { object } from 'prop-types';
import ysq from '@/assets/myApplication/status_ysq.png';
import sptg from '@/assets/myApplication/status_sptg.png';
import blz from '@/assets/myApplication/status_blz.png';
import fkcg from '@/assets/myApplication/status_fkcg.png';
import spwtg from '@/assets/myApplication/status_spwtg.png';
import cancel from '@/assets/myApplication/status_qx.png';
import th from '@/assets/myApplication/status_th.png';
import creditGuarantee from '@/assets/myApplication/credit-guarantee.png';
import {
  getUnitConversionWithRatio,
  moneyDelete,
} from '@/utils/utils';

const statusObj = {
  0: { name: '已提交申请', class: '#3E7EFF', icon: ysq },
  2: { name: '审核中', class: '#3E7EFF', icon: blz },
  3: { name: '已公示', class: '#3E7EFF', icon: sptg },
  4: { name: '审核通过', class: '#12A366', icon: fkcg },
  5: { name: '审核不通过', class: '#E54545', icon: spwtg },
  9: { name: '已取消', class: '#999999', icon: cancel },
  10: { name: '待补充资料', class: '#999999', icon: th },
};

export default function CreditGuaranteeCard(props) {
  const Navigate = useNavigate();
  const { data, onCancel, onSchedule } = props;
  function getDate(e) {
    const day = e.split(' ');
    return day[0];
  }
  return (
    <div className="myapplication__list-row">
      <div className="myapplication__status">
        <img src={statusObj[data.status]?.icon} alt="" width={130} height={35} />
        <span style={{ color: statusObj[data.status]?.class }} className="myapplication__status-text">{statusObj[data.status]?.name}</span>
      </div>
      <div className="myapplication__list-row-img">
        <img src={creditGuarantee} alt="" width={250} height={75} />
      </div>
      <div className="myapplication__list-row-con">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="myapplication__list-row-name" style={{ alignItems: 'center' }}>
            <Tooltip title={data.productName}>
              <span className="myapplication__list-pro-name">{data.productName || '-'}</span>
            </Tooltip>
            <span className="tagYellow">担保奖补</span>
          </div>
        </div>
        <div className="margin-right20">
          <div>申报年度</div>
          <div className="myapplication__list-row-title">
            {data.year}
            年
          </div>
        </div>
        <div className="margin-right20">
          <div>贷款担保增量</div>
          <div className="myapplication__list-row-title">
            {data.status !== 3 ? '-' : `${getUnitConversionWithRatio(moneyDelete(data.examineLiability), 10000)?.num}`}
            {data.status !== 3 ? '' : `${getUnitConversionWithRatio(moneyDelete(data.examineLiability), 10000)?.unit}元`}
          </div>
        </div>
        <div className="margin-right20">
          <div>奖补资金</div>
          <div className="myapplication__list-row-title">
            {moneyDelete(data.examineGrant) === 0 || data.status !== 3 ? '-' : `${getUnitConversionWithRatio(moneyDelete(data.examineGrant), 10000)?.num}`}
            {moneyDelete(data.examineGrant) === 0 || data.status !== 3 ? '' : `${getUnitConversionWithRatio(moneyDelete(data.examineGrant), 10000)?.unit}元`}
          </div>
        </div>
        <div className="margin-right20">
          <div>申请时间</div>
          <div className="myapplication__list-row-title">{getDate(data.gmtCreate)}</div>
        </div>
      </div>
      <div className="btn-box">
        <Button type="primary" onClick={onSchedule}>
          进度查询
        </Button>
        {
          data?.status === 0 && (
            <Button type="primary" ghost onClick={onCancel}>
              取消申请
            </Button>
          )
        }
        {
          data?.status === 10 && (
            <Button
              type="primary"
              ghost
              onClick={() => {
                Navigate(`/creditGuarantee/application?backId=${data?.applicationId}`);
              }}
            >
              重新申请
            </Button>
          )
        }
      </div>
    </div>
  );
}

CreditGuaranteeCard.propTypes = {
  data: object,
  onSchedule: () => { },
  onCancel: () => { },
};
