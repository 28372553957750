import {
  Form, Input, Select, DatePicker, Cascader, InputNumber,
} from 'antd';
import {
  object, func, bool, array,
} from 'prop-types';
import moment from 'moment';
import { useRequest } from 'ahooks';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import CustomFooter from '@/components/CustomFooter/CustomFooter';
import { getBasicType, getDictType } from '@/services/common';
import { BASIC_LEVEL, BUSINESS_TYPE, BUSINESS_UNIT } from '@/utils/mappingDict';

import './UpdateBasicForm.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
};
const dictParams = [BASIC_LEVEL, BUSINESS_TYPE, BUSINESS_UNIT].join(',');

export default function UpdateBasicForm({
  data,
  onOk,
  onCancel,
  confirmLoading,
}) {
  const [form] = Form.useForm();
  const [businessType, setBusinessType] = useState(null);
  const { area, userType } = useSelector((state) => state);
  const { data: dict = {} } = useRequest(getDictType, {
    // 筛选项字典
    defaultParams: [dictParams],
  });

  const { data: basicType = [] } = useRequest(getBasicType); // 获取主体类型

  useEffect(() => {
    if (!isEmpty(data)) {
      setBusinessType(data?.businessType);
    }
  }, [data]);
  function onFinish() {
    form.validateFields().then((values) => {
      const val = {
        applicationUserBasicInfoVo: {
          basicAddress: values.basicAddress,
          basicArea: values.basicProvicnce?.[2],
          basicCard: values.basicCard,
          basicCity: values.basicProvicnce?.[1],
          basicLevel: values.basicLevel,
          basicName: values.basicName,
          basicNo: values.basicNo,
          basicProvicnce: values.basicProvicnce?.[0],
          basicTel: values.basicTel,
          basicType: values.basicType,
          familyName: values.familyName,
          levelTime: moment(values.levelTime).format('YYYY-MM-DD'),
        },
        applicationUserBusinessInfoVo: {
          businessAmount: values.businessAmount,
          businessType: values.businessType,
          businessYear: values.businessYear,
          plantArea: values.plantArea,
          plantType: values.plantType,
          unitId: values?.unitId,
        },
      };
      onOk(val);
    });
  }

  function handleBusinessType(e) {
    setBusinessType(e);
  }

  const isEnterprise = userType === 3; // 是否企业用户

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="form"
        className="basic-info-form"
        initialValues={{
          ...data,
          levelTime: data?.levelTime && moment(data?.levelTime),
          basicProvicnce: data?.basicProvicnce && [
            data.basicProvicnce,
            data?.basicCity,
            data?.basicArea,
          ],
        }}
      >
        <div className="basic-info-form__title">主体信息</div>
        <Form.Item
          name="basicType"
          label="主体类型"
          rules={[
            {
              required: true,
              message: '请选择主体类型',
            },
          ]}
        >
          <Select placeholder="请选择主体类型">
            {basicType?.map((item) => (
              <Select.Option value={item.val} key={item.val}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {isEnterprise && (
          <>
            <Form.Item
              name="basicName"
              label="主体名称"
              rules={[
                {
                  required: true,
                  message: '请输入主体名称',
                },
              ]}
            >
              <Input placeholder="请输入主体名称" disabled={userType === 3} />
            </Form.Item>
            <Form.Item
              name="basicLevel"
              label="主体等级"
              rules={[
                {
                  required: true,
                  message: '请选择主体等级',
                },
              ]}
            >
              <Select placeholder="请选择主体等级">
                {dict?.basicLevel?.map((item) => (
                  <Select.Option value={item.val} key={item.val}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="levelTime"
              label="等级评定时间"
              rules={[
                {
                  required: true,
                  message: '请输入等级评定时间',
                },
              ]}
            >
              <DatePicker
                placeholder="请输入等级评定时间"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </>
        )}
        {userType === 2 && (
          <Form.Item
            name="familyName"
            label="姓名"
            rules={[
              {
                required: true,
                message: '请输入姓名',
              },
            ]}
          >
            <Input placeholder="请输入姓名" disabled />
          </Form.Item>
        )}
        {userType === 3 && (
          <Form.Item
            name="basicNo"
            label="统一社会信用代码证号"
            rules={[
              {
                required: true,
                message: '请输入统一社会信用代码证号',
              },
            ]}
          >
            <Input placeholder="请输入统一社会信用代码证号" disabled />
          </Form.Item>
        )}
        <Form.Item
          name="basicCard"
          label="身份证号"
          rules={[
            {
              required: true,
              message: '请输入身份证号',
            },
          ]}
        >
          <Input placeholder="请输入身份证号" disabled />
        </Form.Item>
        <Form.Item
          name="basicTel"
          label="联系方式"
          rules={[
            {
              required: true,
              message: '请输入联系方式',
            },
          ]}
        >
          <Input placeholder="请输入联系方式" disabled />
        </Form.Item>
        <Form.Item
          name="basicProvicnce"
          label="经营区域"
          rules={[
            {
              required: true,
              message: '请输入经营区域',
            },
          ]}
        >
          <Cascader
            options={area}
            placeholder="请选择经营区域"
            fieldNames={{ label: 'name', value: 'id', children: 'children' }}
          />
        </Form.Item>
        <Form.Item
          name="basicAddress"
          label="详细地址"
          rules={[
            { required: true, message: '请输入详细地址' },
            {
              max: 50,
              message: '请输入50位以内字符',
            },
          ]}
        >
          <Input placeholder="请输入详细地址" />
        </Form.Item>
        <div className="basic-info-form__title">经营信息</div>
        <Form.Item
          name="businessType"
          label="主营业务类型"
          rules={[
            {
              required: true,
              message: '请选择主营业务类型',
            },
          ]}
        >
          <Select
            placeholder="请选择主营业务类型"
            onChange={handleBusinessType}
          >
            {dict?.businessType?.map((item) => (
              <Select.Option value={item.val} key={item.val}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {(businessType === '1' || businessType === '3') && (
          <Form.Item
            name="plantType"
            label={businessType === '1' ? '种植品种' : '养殖品种'}
            rules={[
              {
                required: true,
                message:
                  businessType === '1' ? '请输入种植品种' : '请输入养殖品种',
              },
              {
                max: 20,
              },
            ]}
          >
            <Input
              placeholder={
                businessType === '1' ? '请输入种植品种' : '请输入养殖品种'
              }
            />
          </Form.Item>
        )}
        {(businessType === '1' || businessType === '3') && (
          <Form.Item
            label={businessType === '1' ? '种植面积' : '养殖数量'}
            rules={[{ required: true }]}
          >
            <Input.Group compact>
              <Form.Item
                noStyle
                name="plantArea"
                rules={[
                  {
                    required: true,
                    message:
                      businessType === '1' ? '请输入种植面积' : '请输入养殖数量',
                  },
                ]}
              >
                <InputNumber
                  min={1}
                  max={99999999}
                  placeholder={
                    businessType === '1' ? '请输入种植面积' : '请输入养殖数量'
                  }
                  style={{ width: '85%' }}
                />
              </Form.Item>
              <Form.Item
                name="unitId"
                noStyle
                rules={[{ required: true, message: '请选择计量单位' }]}
              >
                <Select placeholder="单位" style={{ width: '15%' }}>
                  {dict?.businessUnit?.map((item) => (
                    <Select.Option value={item.val} key={item.val}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        )}
        <Form.Item
          name="businessAmount"
          label="年经营收入"
          rules={[
            {
              required: true,
              message: '请输入年经营收入',
            },
          ]}
        >
          <InputNumber
            min={0.1}
            max={99999999}
            placeholder="请输入年经营收入"
            addonAfter="万元"
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item
          name="businessYear"
          label="实际经营年限"
          rules={[
            {
              required: true,
              message: '请输入实际经营年限',
            },
          ]}
        >
          <InputNumber
            placeholder="请输入实际经营年限"
            addonAfter="年"
            min={0.1}
            max={100}
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Form>
      <CustomFooter
        onOk={() => onFinish()}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
      />
    </>
  );
}

UpdateBasicForm.propTypes = {
  data: object,
  onOk: func,
  onCancel: func,
  confirmLoading: bool,
  options: array,
};

UpdateBasicForm.defaultProps = {
  data: {},
};
