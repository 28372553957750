import { useLocation } from 'react-router-dom';
import BankCard from '@/components/ProductFilter/BankCard';
import FormInfo from '@/components/FormInfo/FormInfo';
import './index.less';

export default function Application() {
  const { state } = useLocation();

  const AddBlankListProduct = {
    productId: state?.productId,
    productName: state?.productName,
    publishOrgId: state?.publishOrgId,
    publishOrgName: state?.publishOrgName,
  };

  return (
    <div className="yhzd-pro-application">
      <div className="content-width padding-top40">
        <BankCard data={state} isShow />
        <div className="yhzd-pro-application__title">产品申请</div>
        <div className="margin-top20">
          <FormInfo type="yhzd" product={AddBlankListProduct} />
        </div>
      </div>

    </div>
  );
}
