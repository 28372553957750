import {
  Col, Form, Input, InputNumber, Row, Select,
} from 'antd';
import { useState } from 'react';

import { object } from 'prop-types';

export default function BusinessForm({ dict }) {
  const [type, setType] = useState(null);

  function handleChange(e) {
    setType(e);
  }

  return (
    <div>
      <Row>
        <Col span={12}>
          <Form.Item
            name="businessType"
            label="主营业务类型"
            rules={[
              {
                required: true,
                message: '请选择主营业务类型',
              },
            ]}
          >
            <Select placeholder="请选择主营业务类型" onChange={handleChange}>
              {
                dict?.businessType?.map((item) => (
                  <Select.Option value={item.val} key={item.val}>
                    {item.name}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        {(type === '1' || type === '3') && (
          <Col span={12}>
            <Form.Item
              name="plantType"
              label={type === '1' ? '种植品种' : '养殖品种'}
              rules={[
                {
                  required: true,
                  message: type === '1' ? '请输入种植品种' : '请输入养殖品种',
                },
                {
                  max: 20,
                },
              ]}
            >
              <Input
                placeholder={type === '1' ? '请输入种植品种' : '请输入养殖品种'}
              />
            </Form.Item>
          </Col>
        )}
        {(type === '1' || type === '3') && (
          <Col span={12}>
            <Form.Item
              label={type === '1' ? '种植面积' : '养殖数量'}
              rules={[{ required: true }]}
            >
              <Input.Group compact>
                <Form.Item
                  noStyle
                  name="plantArea"
                  rules={[
                    {
                      required: true,
                      message:
                        type === '1' ? '请输入种植面积' : '请输入养殖数量',
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    max={99999999}
                    placeholder={
                      type === '1' ? '请输入种植面积' : '请输入养殖数量'
                    }
                    style={{ width: '80%' }}
                  />
                </Form.Item>
                <Form.Item
                  name="unitId"
                  noStyle
                  rules={[{ required: true, message: '请选择计量单位' }]}
                >
                  <Select placeholder="单位" style={{ width: '20%' }}>
                    <Select.Option value="Zhejiang">亩</Select.Option>
                  </Select>
                </Form.Item>
              </Input.Group>
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item
            name="businessAmount"
            label="年经营收入"
            rules={[
              {
                required: true,
                message: '请输入年经营收入',
              },
            ]}
          >
            <InputNumber
              placeholder="请输入年经营收入"
              addonAfter="万元"
              style={{ width: '100%' }}
              min={0.1}
              max={99999999}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="businessYear"
            label="实际经营年限"
            rules={[
              {
                required: true,
                message: '请输入实际经营年限',
              },
            ]}
          >
            <InputNumber
              placeholder="请输入实际经营年限"
              addonAfter="年"
              min={0.1}
              max={100}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
BusinessForm.propTypes = {
  dict: object,
};
