import {
  Button, Form, Input, message,
} from 'antd';
import { useState, useEffect } from 'react';
import { string, bool, number } from 'prop-types';
import { getSmsCode, getSmsCodeApply } from '@/services/common';
import './CodeInput.less';

let timer = null;

/**
 * 获取验证码组件
 * @param {string} phone 手机号
 * @param {bool} reset 是否重置倒计时
 * @param {bool} isApply 是否是申请处验证码
 * @param {number} type 申请验证码类型
 * */

export default function CodeInput({
  phone, reset, isApply, type,
}) {
  const [time, setTime] = useState(0);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    timer && clearInterval(timer);
    return () => timer && clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!reset) {
      setTime(0);
    }
  }, [reset]);

  useEffect(() => {
    if (time === 60) {
      // eslint-disable-next-line no-shadow,no-param-reassign
      timer = setInterval(() => setTime((time) => --time), 1000);
    } else if (time === 0) {
      clearInterval(timer);
    }
  }, [time]);

  function getCode() {
    if (!phone) {
      message.error('请输入手机号');
    } else if (isApply) {
      getSmsCodeApply({ phone, type }).then(() => {
        message.success('已发送，请注意查收！');
        setTime(60);
      });
    } else {
      getSmsCode(phone).then(() => {
        message.success('已发送，请注意查收！');
        setTime(60);
      });
    }
  }

  function checkCode(_, value) {
    const promise = Promise;
    if (value && value.length !== 6) {
      return promise.reject('请输入6位数验证码');
    }
    return promise.resolve();
  }

  return (
    <div className="auth-modal__sms-box">
      <Form.Item
        name="code"
        rules={[
          { required: true, message: '请输入短信验证码' },
          { validator: checkCode },
        ]}
      >
        <Input placeholder="请输入短信验证码" />
      </Form.Item>
      <Button type="primary" disabled={time} onClick={getCode} className="auth-modal__sms-btn">{time ? `${time}s` : '获取验证码'}</Button>
    </div>
  );
}
CodeInput.propTypes = {
  phone: string,
  reset: bool,
  isApply: bool,
  type: number,
};

CodeInput.defaultProps = {
  isApply: false,
};
