import { Popover } from 'antd';
import { useRequest } from 'ahooks';
import weChat from '@/assets/weixin.png';
import { getTel } from '@/services/common';
import kf from '@/assets/kefu.png';
import qrcode from '@/assets/qrcode.jpg';

import './FixMenu.less';

export default function FixMenu() {
  const { data } = useRequest(getTel);

  return (
    <div className="fix-menu">
      <div>
        <Popover
          placement="leftTop"
          title="客服电话"
          content={(
            <div>
              {
                  data?.phone?.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>{item}</div>
                  ))
              }
              <div>{data?.time}</div>
            </div>
          )}
        >
          <div className="fix-menu__item" style={{ display: 'none' }}>
            <img src={kf} alt="" width={31} height={31} />
            <div>客户服务</div>
          </div>
        </Popover>
        <Popover placement="leftTop" title="请微信扫码关注公众号" content={<img src={qrcode} alt="" width={124} height={124} />}>
          <div className="fix-menu__item" style={{ display: 'none' }}>
            <img src={weChat} alt="" width={31} height={31} />
            <div>微信公众号</div>
          </div>
        </Popover>

      </div>

    </div>
  );
}
