import { EditOutlined, TabletOutlined } from '@ant-design/icons';
import {
  Button, Modal, Upload, message, Spin,
} from 'antd';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useRequest } from 'ahooks';
import BasicInfo from '@/components/FormInfo/BasicInfo';
import avatar from '@/assets/user.png';
import empty from '@/assets/empty.png';
import UpdateBasicForm from '@/components/UpdateBasicForm/UpdateBasicForm';
import {
  updatePass, uploadImg, verifyCode, updateAvatar, getUserBasic, getUserInfo,
} from '@/services/user';
import UpdateTel from './components/UpdateTel';
import UpdatePass from './components/UpdatePass';
import './index.less';
import { updateUserBasic } from '@/services/common';

export default function Index() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { userInfo, status } = useSelector((state) => state);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [telModal, setTelModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);

  const { run: userBasicRun, data: info = {} } = useRequest(getUserBasic);

  const { run: basicRun, loading: basicLoading } = useRequest(updateUserBasic, { // 更新用户经营信息
    manual: true,
    onSuccess() {
      setIsModalOpen(false);
      userBasicRun();
      message.success('更新成功');
    },
  });

  const { run, loading } = useRequest(updatePass, { // 更新密码
    manual: true,
    onSuccess() {
      message.success('修改密码成功');
      Navigate('/login');
    },
  });
  const { run: userRun } = useRequest(getUserInfo, {
    manual: true,
    onSuccess: (res) => {
      dispatch({ type: 'USER_INFO', payload: res });
    },
  });

  const { run: avatarRun } = useRequest(updateAvatar, { // 更新头像
    manual: true,
    onSuccess() {
      message.success('更新成功');
      setAvatarLoading(false);
      userRun();
    },
    onError() {
      setAvatarLoading(false);
    },
  });

  const { run: codeRun, loading: codeLoading } = useRequest(verifyCode, { // 修改手机号-校验验证码
    manual: true,
    onSuccess() {
      userRun();
      setTelModal(false);
    },
  });

  function submitForm(val) {
    basicRun(val);
  }

  function closeTleModal() {
    setTelModal(false);
  }

  function close() {
    setIsModalOpen(false);
  }

  function closePassModal() {
    setPasswordModal(false);
  }

  function handleUpdateTel(val) {
    codeRun(val);
  }

  function handleUpdatePassword(val) {
    run({ ...val, userId: userInfo?.id, type: 1 });
  }

  // eslint-disable-next-line no-shadow
  function handleUpload(info) {
    setAvatarLoading(true);
    if (info.file.response?.data) {
      avatarRun({
        id: userInfo?.id,
        photoPath: info.file.response?.data,
      });
    }
    if (info.file.status === 'error') {
      message.error('上传失败');
      setAvatarLoading(false);
    }
  }
  return (
    <div className="user">
      <Spin spinning={avatarLoading}>
        <div className="content-width">
          <div className="user__title">个人中心</div>
          <div className="user__info">
            <div>
              <div>
                <img src={userInfo?.photo || avatar} alt="" width={72} height={72} />
              </div>
              <Upload
                action={uploadImg}
                listType="picture"
                accept=".png,.jpg,.jpeg"
                onChange={handleUpload}
              >
                <div className="user__edit-btn">修改头像</div>
              </Upload>
            </div>
            <div>
              <div>
                <div style={{ marginBottom: 10 }}>
                  <span className="user__tel">{userInfo?.phone}</span>
                  <span className="blue-text" onClick={() => setTelModal(true)} style={{ display: 'none' }}>
                  <EditOutlined />
                    &nbsp;换绑手机
                  </span>
                  <span className="blue-text" onClick={() => setPasswordModal(true)}>
                  <TabletOutlined />
                    &nbsp;修改密码
                  </span>
                </div>
                <div>
                  注册时间：
                  {userInfo?.registerDate}
                </div>
              </div>
              {
                status && <div className="authentication-btn" onClick={() => Navigate('/authentication')}>{'去认证>>'}</div>
              }
            </div>
          </div>
          {
              isEmpty(info?.applicationUserBusinessInfoVo) ? (
                <div className="user__card">
                  <div className="user__card-title">待完善个人信息</div>
                  <div className="user__card-con">
                    <div className="text-center">
                      <img src={empty} alt="" width={134} height={82} />
                      <div className="user__card-con-text">您还没有填写个人信用信息</div>
                      <Button type="primary" onClick={() => setIsModalOpen(true)}>完善信息</Button>
                    </div>
                  </div>
                </div>
              ) : (

                <div className="user__basic-card">
                  <div className="user__basic-card-edit">
                    <EditOutlined />
                    <span className="user__basic-card-edit-btn" onClick={() => setIsModalOpen(true)}>修改信息</span>
                  </div>
                  <BasicInfo data={{ ...info?.applicationUserBasicInfoVo, ...info?.applicationUserBusinessInfoVo }} />
                </div>
              )
          }
        </div>
      </Spin>
      <Modal
        title="完善信息"
        destroyOnClose
        footer={null}
        open={isModalOpen}
        width={700}
        maskClosable={false}
        onCancel={close}
      >
        <UpdateBasicForm
          onCancel={close}
          onOk={submitForm}
          confirmLoading={basicLoading}
          data={{ ...info?.applicationUserBasicInfoVo, ...info?.applicationUserBusinessInfoVo }}
        />
      </Modal>

      <Modal
        title="换绑手机"
        centered
        maskClosable={false}
        destroyOnClose
        footer={null}
        open={telModal}
        width={500}
        onCancel={closeTleModal}
      >
        <UpdateTel onCancel={closeTleModal} onOk={handleUpdateTel} data={userInfo} confirmLoading={codeLoading} />
      </Modal>

      <Modal
        title="修改密码"
        centered
        maskClosable={false}
        destroyOnClose
        footer={null}
        open={passwordModal}
        width={500}
        onCancel={closePassModal}
      >
        <UpdatePass onCancel={closePassModal} onOk={handleUpdatePassword} confirmLoading={loading} />
      </Modal>

    </div>
  );
}
