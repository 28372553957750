import {
  Col, Form, Input, Row, Select, DatePicker, Cascader,
} from 'antd';
import { array, object } from 'prop-types';
import { useSelector } from 'react-redux';

export default function BasicInfoForm({ options, dict }) {
  const { userType } = useSelector((state) => state);

  const isEnterprise = userType === 3; // 是否企业用户

  return (
    <div>
      <Row>
        <Col span={12}>
          <Form.Item
            name="basicType"
            label="主体类型"
            rules={[
              {
                required: true,
                message: '请选择主体类型',
              },
            ]}
          >
            <Select placeholder="请选择主体类型">
              {
                dict?.basicType?.map((item) => (
                  <Select.Option value={item.val} key={item.val}>{item.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        {
          isEnterprise && (
            <>
              <Col span={12}>
                <Form.Item
                  name="basicName"
                  label="主体名称"
                  rules={[
                    {
                      required: true,
                      message: '请输入主体名称',
                    },
                  ]}
                >
                  <Input placeholder="请输入主体名称" disabled={userType === 3} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="basicLevel"
                  label="主体等级"
                  rules={[
                    {
                      required: true,
                      message: '请选择主体等级',
                    },
                  ]}
                >
                  <Select placeholder="请选择主体等级">
                    {
                      dict?.basicLevel?.map((item) => (
                        <Select.Option value={item.val} key={item.val}>{item.name}</Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="levelTime"
                  label="等级评定时间"
                  rules={[
                    {
                      required: true,
                      message: '请输入等级评定时间',
                    },
                  ]}
                >
                  <DatePicker placeholder="请输入等级评定时间" style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </>
          )
        }
        {
          userType === 2 && (
              <Col span={12}>
              <Form.Item
                name="familyName"
                label="姓名"
                rules={[
                  {
                    required: true,
                    message: '请输入姓名',
                  },
                ]}
              >
                <Input placeholder="请输入姓名" disabled />
              </Form.Item>
              </Col>
          )
        }
        {
          userType === 3 && (
            <Col span={12}>
              <Form.Item
                name="basicNo"
                label="统一社会信用代码证号"
                rules={[
                  {
                    required: true,
                    message: '请输入统一社会信用代码证号',
                  },
                ]}
              >
                <Input placeholder="请输入统一社会信用代码证号" disabled />
              </Form.Item>
            </Col>
          )
        }
        <Col span={12}>
          <Form.Item
            name="basicCard"
            label="身份证号"
            rules={[
              {
                required: true,
                message: '请输入身份证号',
              },
            ]}
          >
            <Input placeholder="请输入身份证号" disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="basicTel"
            label="联系方式"
            rules={[
              {
                required: true,
                message: '请输入联系方式',
              },
            ]}
          >
            <Input placeholder="请输入联系方式" disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="basicProvicnce"
            label="经营区域"
            rules={[
              {
                required: true,
                message: '请输入经营区域',
              },
            ]}
          >
            <Cascader options={options} placeholder="请选择经营区域" fieldNames={{ label: 'name', value: 'id', children: 'children' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="basicAddress"
            label="详细地址"
            rules={[
              { required: true, message: '请输入详细地址' },
              {
                max: 50,
                message: '请输入50位以内字符',
              },
            ]}
          >
            <Input placeholder="请输入详细地址" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
BasicInfoForm.propTypes = {
  options: array,
  dict: object,
};
