import {
  Button, Form, Input, Modal, Select,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { useDispatch } from 'react-redux';
import { login } from '@/services/login';
import { getPublickey } from '@/services/common';
import setEncrypt from '@/utils/encrypt';
import ForgetPass from './ForgetPass';
import { setToken } from '@/utils/token';
import '../index.less';

export default function Login() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState('2'); // 用户类型

  useRequest(getPublickey, {
    onSuccess(res) {
      sessionStorage.setItem('publicKey', res);
    },
  });

  const { run } = useRequest((val) => login({ ...val, loginMode: 2 }), {
    manual: true,
    onSuccess(res) {
      setToken(res?.token);
      dispatch({ type: 'USER_TYPE', payload: res?.userType });
      dispatch({ type: 'STATUS', payload: res?.materialStatus });
      if (res?.materialStatus) {
        Navigate('/authentication');
      } else if (state?.url) {
        Navigate(state?.url);
      } else {
        Navigate(-1);
      }
    },
  });

  function onFinish(val) {
    const obj = {
      accountNo: type === '2' ? setEncrypt(val.accountNo) : setEncrypt(val.creditCode),
      phone: val.accountNo,
      password: setEncrypt(val.password),
    };
    run(obj);
  }

  function handleTo() {
    Navigate('/register');
  }

  function close() {
    setIsModalOpen(false);
  }

  function handleUpdatePass() {

  }

  function handleChange(e) {
    setType(e);
  }

  return (
    <>
      <Form
        name="loginForm"
        initialValues={{
          remember: true,
          userType: '2',
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="userType"
          rules={[
            {
              required: true,
              message: '请选择用户类型',
            },
          ]}
        >
          <Select onChange={handleChange}>
            <Select.Option value="2">个人</Select.Option>
            <Select.Option value="3">企业</Select.Option>
          </Select>
        </Form.Item>
        {
          type === '3' && (
            <Form.Item
              name="creditCode"
              rules={[
                {
                  required: true,
                  message: '请输入统一社会信用代码',
                },
              ]}
            >
              <Input placeholder="统一社会信用代码" />
            </Form.Item>
          )
        }
        <Form.Item
          name="accountNo"
          rules={[
            {
              required: true,
              message: '请输入手机号',
            },
          ]}
        >
          <Input suffix={<UserOutlined />} placeholder="请输入手机号" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: '请输入密码',
            },
          ]}
        >
          <Input.Password placeholder="请输入密码" />
        </Form.Item>

        {/* <Form.Item */}
        {/*  name="remember" */}
        {/*  valuePropName="checked" */}
        {/* > */}
        {/*  <div className="flex"> */}
        {/*    <Checkbox>记住密码</Checkbox> */}
        {/*    <span className="color-blue" onClick={() => setIsModalOpen(true)} style={{ display: 'none' }}>忘记密码？</span> */}
        {/*  </div> */}

        {/* </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            登录
          </Button>
        </Form.Item>
        <div>
          <Button type="primary" ghost onClick={() => handleTo()}>
            新用户注册
          </Button>
        </div>
      </Form>
      <Modal
        title="重置密码"
        centered
        maskClosable={false}
        destroyOnClose
        footer={null}
        open={isModalOpen}
        width={500}
        onCancel={close}
      >
        <ForgetPass onCancel={close} onOk={handleUpdatePass} />
      </Modal>
    </>
  );
}
