import { useEffect, useState } from 'react';
import {
  Form, DatePicker, InputNumber, Select,
  Row, Col, Input, Upload, Button,
  message, Checkbox, Modal,
} from 'antd';
import { isNull, isUndefined } from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import {
  bool, number, func, any,
} from 'prop-types';
import moment from 'moment';
import {
  uploadFileUrl,
} from '@/services/majorFinancing';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

export default function FormList({
  disable, field, formRef, loanType, loanAmount,
}) {
  const [loanContractFiles, setLoanContractFiles] = useState([]);
  const [fundsWithdrawalFiles, setFundsWithdrawalFiles] = useState([]);
  const [isShowLoanForFoodProduction, setIsShowLoanForFoodProduction] = useState(false);

  const props = {
    name: 'file',
    accept: '.rar,.zip,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.pdf',
    action: uploadFileUrl,
    headers: {
      authorization: 'authorization-text',
    },
    disabled: disable,
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        message.error('请上传200M以下文件!');
      }
      return isLt2M;
    },
  };

  function checkFileUploading(_, value) {
    const promise = Promise;

    if (!isUndefined(value) && !isNull(value)) {
      const { event } = value;
      if (!isUndefined(event) && event.type === 'progress') {
        return promise.reject('请等待文件上传完成');
      }
    }

    return promise.resolve();
  }

  function getValueFromEvent(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  const generateFileName = (fileList) => fileList.map((file) => (file.name));
  const generateFileUrl = (fileList) => fileList.map((file) => (file?.response?.data));

  useEffect(() => {
    const loanTypeValue = formRef.getFieldValue('loanDetailsVos')[field?.name]?.loanType;
    if (loanTypeValue === 2) {
      setIsShowLoanForFoodProduction(true);
    }
  }, [formRef?.getFieldValue('loanDetailsVos')[field?.name]?.loanType]);

  /**
   * 判断一个时间区间与在另一个时间区间有重叠部分
   */

  function doIntervalsOverlap(start1, end1, start2, end2) {
    // 如果第一个区间的开始时间在第二个区间的开始和结束时间之间
    if (start1.isBetween(start2, end2, null, '[]')) {
      return true;
    }

    // 如果第一个区间的结束时间在第二个区间的开始和结束时间之间
    if (end1.isBetween(start2, end2, null, '[]')) {
      return true;
    }

    // 如果第二个区间的开始时间在第一个区间的开始和结束时间之间
    if (start2.isBetween(start1, end1, null, '[]')) {
      return true;
    }

    // 如果第二个区间的结束时间在第一个区间的开始和结束时间之间
    if (end2.isBetween(start1, end1, null, '[]')) {
      return true;
    }

    // 如果没有以上任何一种情况，则两个区间没有重叠
    return false;
  }

  /**
   * 根据贷款种类检查日期范围是否在指定的时间范围内
   * @param start
   * @param end
   */
  function checkRange([start, end]) {
    const loanDetailsVosValues = formRef.getFieldValue('loanDetailsVos');
    const { loanType: type } = loanDetailsVosValues[field.name];
    loanDetailsVosValues[field.name].loanDateRangeStartTime = moment(start).format(dateFormat);
    loanDetailsVosValues[field.name].loanDateRangeEndTime = moment(end).format(dateFormat);
    const msg = '当前贷款期间不在申报区间内，该笔贷款不能享受贴息，请问确定是否继续填报？';

    if (!type) {
      Modal.info({
        title: '提示',
        content: '请先选择贷款种类',
      });
      // 清空日期选择
      loanDetailsVosValues[field.name].loanDateRange = [];
      return;
    }

    // 1为固定资产贷款，2为流动资金贷款
    if (type === 1 || type === 2) {
      const date1StartTime = moment('2023-4-1');
      const date1EndTime = moment('2024-4-1');
      const isOverlap = doIntervalsOverlap(start, end, date1StartTime, date1EndTime);
      if (!isOverlap) {
        Modal.info({
          title: '提示',
          content: msg,
          okText: '继续填报',
        });
      }
    }
    // 3为天府农博园贷款
    if (type === 3) {
      const date2StartTime = moment('2023-1-1');
      const date2EndTime = moment('2024-1-1');
      const isOverlap = doIntervalsOverlap(start, end, date2StartTime, date2EndTime);
      if (!isOverlap) {
        Modal.info({
          title: '提示',
          content: msg,
          okText: '继续填报',
        });
      }
    }
  }

  return (
    <Row>
      <Col span={12}>
        <Form.Item
          label="贷款种类"
          name={[field.name, 'loanType']}
          rules={[{ required: true, message: '请选择贷款类型!' }]}
        >
          <Select
            placeholder="贷款种类"
            style={{ width: '100%' }}
            onChange={
              (value) => {
                if (value === 2) {
                  setIsShowLoanForFoodProduction(true);
                } else {
                  setIsShowLoanForFoodProduction(false);
                }
              }
            }
          >
            {
              loanType.loanType?.map((item) => (
                <Select.Option value={item.code} key={item.code}>
                  {item.name}
                </Select.Option>
              ))
            }
          </Select>
        </Form.Item>
      </Col>
      {
        isShowLoanForFoodProduction && (
          <Col span={5}>
            <Form.Item
              required
              name={[field.name, 'capitalUse']}
              rules={[{ required: true, message: '请选择贷款种类' }]}
            >
              <Select placeholder="请选择贷款种类" style={{ width: '100%' }}>
                {
                  loanType?.moneyUsage?.map((item) => (
                    <Select.Option value={item.code} key={item.code}>
                      {item.name}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>
        )
      }
      <Col span={24}>
        {
          isShowLoanForFoodProduction
            ? (
              <Form.Item
                label="贷款是否用于粮食生产及初加工"
                labelCol={{
                  sm: { span: 6 },
                }}
                wrapperCol={{
                  sm: { span: 13 },
                }}
                name={[field.name, 'loanForFoodProduction']}
                valuePropName="checked"
              >
                <Checkbox> 是 </Checkbox>
              </Form.Item>
            )
            : ''
        }

      </Col>
      <Col span={12}>
        <Form.Item
          label="贷款银行"
          name={[field.name, 'loanBank']}
          rules={[
            { required: true, message: '请输入贷款银行!' },
            { pattern: /^\s*\S.*$/, message: '请输入贷款银行!' },
          ]}
        >
          <Input disabled={disable} placeholder="请输入贷款银行" style={{ width: '100%' }} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name={[field.name, 'loanDateRangeStartTime']}
          style={{ display: 'none' }}
        />
        <Form.Item
          name={[field.name, 'loanDateRangeEndTime']}
          style={{ display: 'none' }}
        />
        <Form.Item
          label="贷款期间"
          rules={[{ required: true, message: '请输入贷款期间!' }]}
          name={[field.name, 'loanDateRange']}
        >
          <RangePicker
            disabled={disable}
            format={dateFormat}
            style={{ width: '100%' }}
            onChange={checkRange}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="贷款金额"
          name={[field.name, 'loanAmount']}
          rules={[
            { required: true, message: '请输入贷款金额!' },
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('贷款金额必须大于0'))),
            },
          ]}
        >
          <InputNumber
            min={0}
            max={999999999999999}
            placeholder="请输入贷款金额"
            disabled={disable}
            addonAfter="万元"
            style={{ width: '100%' }}
            onChange={loanAmount}

          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="贷款利率" name={[field.name, 'loanInterestRate']} rules={[{ required: true, message: '请输入贷款利率!' }]}>
          <InputNumber
            min={0}
            max={999999999999999}
            placeholder="请输入贷款利率"
            disabled={disable}
            addonAfter="%"
            style={{ width: '100%' }}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          required
          label="贷款使用主要内容"
          labelCol={{
            sm: { span: 4 },
          }}
          wrapperCol={{
            sm: { span: 20 },
          }}
          name={[field.name, 'mainPurposeOfLoan']}
          rules={[{ required: true, message: '请输入贷款使用主要内容！' }]}
        >
          <Input.TextArea maxLength={200} showCount placeholder="请输入主要建设内容和规模" />
        </Form.Item>
      </Col>
      <Col span={24}>
        {
          // 银行贷款汇总表及银行贷款合同文件名
        }
        <Form.Item
          style={{ display: 'none' }}
          name={[field.name, 'tableContractFileName']}
        />
        <Form.Item
          style={{ display: 'none' }}
          name={[field.name, 'bankLoanSummaryTableContract']}
        />
        <Form.Item
          name={[field.name, 'bankLoanSummaryTableContract_i']}
          label="银行贷款汇总表及银行贷款合同"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
          rules={[
            {
              required: true,
              message: '请上传银行贷款汇总表及银行贷款合同！',
            },
            {
              validator: checkFileUploading,
            },
          ]}
        >
          <Upload
            {...props}
            maxCount={1}
            onChange={
              ({ file, fileList }) => {
                // setFacilityLoanFiles(fileList);
                const loanDetailsVosValues = formRef.getFieldValue('loanDetailsVos');
                loanDetailsVosValues[field.name].bankLoanSummaryTableContract = generateFileUrl(fileList).join('');
                loanDetailsVosValues[field.name].tableContractFileName = generateFileName(fileList).join('');
                formRef.setFieldsValue({
                  loanDetailsVos: loanDetailsVosValues,
                });
                if (file.status === 'done') {
                  message.success(`文件【${file.name}】上传成功`);
                } else if (file.status === 'error') {
                  message.error(`文件【${file.name}】上传失败`);
                }
              }
            }
          >
            <Button icon={<UploadOutlined />}>上传文件</Button>
          </Upload>
        </Form.Item>
      </Col>

      <Col span={24}>
        {
          // 支付银行利息汇总表及利息结算清单文件名
        }
        <Form.Item
          style={{ display: 'none' }}
          name={[field.name, 'financialDocumentsFileName']}
        />
        <Form.Item
          style={{ display: 'none' }}
          name={[field.name, 'financialDocuments']}
        />
        <Form.Item
          name={[field.name, 'financialDocuments_i']}
          label="支付银行利息汇总表及利息结算清单"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
          rules={[
            {
              required: true,
              message: '支付银行利息汇总表及利息结算清单',
            },
            {
              validator: checkFileUploading,
            },
          ]}
        >
          <Upload
            {...props}
            fileList={loanContractFiles}
            maxCount={1}
            onChange={
              ({ file, fileList }) => {
                setLoanContractFiles(fileList);
                const loanDetailsVosValues = formRef.getFieldValue('loanDetailsVos');
                loanDetailsVosValues[field.name].financialDocuments = generateFileUrl(fileList).join('');
                loanDetailsVosValues[field.name].financialDocumentsFileName = generateFileName(fileList).join('');
                formRef.setFieldsValue({
                  loanDetailsVos: loanDetailsVosValues,
                });
                if (file.status === 'done') {
                  message.success('上传成功');
                } else if (file.status === 'error') {
                  message.error('上传失败');
                }
              }
            }
          >
            <Button icon={<UploadOutlined />}>上传文件</Button>
          </Upload>
        </Form.Item>
      </Col>

      <Col span={24}>
        {
          // 银行贷款到账凭证及支出明细账文件名
        }
        <Form.Item
          style={{ display: 'none' }}
          name={[field.name, 'expenseDetailFileName']}
        />
        <Form.Item
          style={{ display: 'none' }}
          name={[field.name, 'loanReceiptAndExpenseDetail']}
        />
        <Form.Item
          name={[field.name, 'loanReceiptAndExpenseDetail_i']}
          label="银行贷款到帐凭证及支出明细账"
          valuePropName="fileList"
          getValueFromEvent={getValueFromEvent}
          rules={[
            {
              required: true,
              message: '请上传银行贷款到帐凭证及支出明细账',
            },
            {
              validator: checkFileUploading,
            },
          ]}
        >

          <Upload
            {...props}
            maxCount={1}
            fileList={fundsWithdrawalFiles}
            onPreview={() => { }}
            onChange={
              ({ file, fileList }) => {
                setFundsWithdrawalFiles(fileList);
                const loanDetailsVosValues = formRef.getFieldValue('loanDetailsVos');
                loanDetailsVosValues[field.name].loanReceiptAndExpenseDetail = generateFileUrl(fileList).join('');
                loanDetailsVosValues[field.name].expenseDetailFileName = generateFileName(fileList).join('');
                formRef.setFieldsValue({
                  loanDetailsVos: loanDetailsVosValues,
                });
                if (file.status === 'done') {
                  message.success('上传成功');
                } else if (file.status === 'error') {
                  message.error('上传失败');
                }
              }
            }
          >
            <Button icon={<UploadOutlined />}>上传文件</Button>
          </Upload>
        </Form.Item>
      </Col>
    </Row>
  );
}

FormList.propTypes = {
  initialData: any,
  formKey: number,
  disable: bool,
  loanType: any,
  amountChanged: func,
  field: any,
  formRef: any,
  loanAmount: func,
};
