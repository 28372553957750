import {
  forwardRef, useEffect, useState,
} from 'react';
import {
  Button,
  Form,
  Card,
  Collapse,
  Popconfirm, Col, Row, InputNumber,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { any, number } from 'prop-types';
import FormList from './FormList';
import '../index.less';
import OtherUpload from '@/pages/industryBankLoan/components/OtherUpload';

const Application = forwardRef(({
  loanTypeDict, formRef, loanAmountInit,
}) => {
  const [loanAmountValue, setLoanAmountValue] = useState(loanAmountInit);

  /**
   * 更新贷款金额
   */
  function undateLoanAmount() {
    const loanDetailsVosValues = formRef.getFieldValue('loanDetailsVos');
    const count = loanDetailsVosValues.reduce((acc, cur) => acc + Number(cur.loanAmount ?? 0), 0);
    setLoanAmountValue(count);
  }

  // 重新获取贷款金额
  useEffect(() => {
    setLoanAmountValue(loanAmountInit);
  }, [loanAmountInit]);
  return (
  <>
    <Form.List name="loanDetailsVos">
      {(fields, { add, remove }) => (
        <Card
          title="贷款信息"
          className="form-info__box"
          extra={
            (
              <Button type="primary" ghost onClick={() => add()}>
                <PlusOutlined />
                添加
              </Button>
            )
          }
        >
          <Row style={{ marginBottom: 30 }}>
            <Col span={4} style={{ textAlign: 'right', lineHeight: '28px', paddingRight: 6 }}>
              申请贴息贷款金额:
            </Col>
            <Col span={5}>
              <InputNumber addonAfter="万元" value={loanAmountValue} disabled />
            </Col>
          </Row>
          {
            fields.map((field, index) => {
              const key = `${index}-${field.key}`;
              return (
                <Collapse defaultActiveKey={key} key={key}>
                  <Collapse.Panel
                    header={`贷款信息${index + 1}`}
                    key={key}
                    extra={
                      (
                        fields.length > 1
                          && (
                            <Popconfirm
                              title="确认删除？"
                              onConfirm={() => {
                                remove(field.name);
                                undateLoanAmount();
                              }}
                              onCancel={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Button
                                danger
                                icon={<MinusCircleOutlined />}
                                onClick={(e) => e.stopPropagation()}
                              >
                                  删除
                              </Button>
                            </Popconfirm>
                          )
                      )
                    }
                  >
                    <FormList
                      key={field.key}
                      formRef={formRef}
                      field={field}
                      disable={field?.disable}
                      loanType={loanTypeDict}
                      loanAmount={undateLoanAmount}
                    />
                  </Collapse.Panel>
                </Collapse>
              );
            })
          }
        </Card>
      )}
    </Form.List>
    <OtherUpload formRef={formRef} />
  </>
  );
});

export default Application;

Application.propTypes = {
  loanTypeDict: any,
  formRef: any,
  loanAmountInit: number,
};

Application.defaultProps = {
  loanTypeDict: {},
};
