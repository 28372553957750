import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { bool, func } from 'prop-types';

export default function ExcelUpload({ onDataParsed, disabled }) {
  const handleFileChange = (file) => {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      try {
        const { result } = e.target;
        const workbook = XLSX.read(result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);

        if (onDataParsed) {
          onDataParsed(data);
        }
      } catch (error) {
        if (onDataParsed) {
          onDataParsed(null);
        }
      }
    };
    fileReader.readAsBinaryString(file);
    // 返回false阻止自动上传行为
    return false;
  };

  return (
    <Upload
      onChange={(info) => {
        handleFileChange(info.file);
      }}
      beforeUpload={() => false}
      accept=".xlsx, .xls"
      showUploadList={false}
      maxCount={1}
    >
      <Button
        icon={<UploadOutlined />}
        disabled={disabled}
      >
        上传
      </Button>
    </Upload>
  );
}

ExcelUpload.propTypes = {
  onDataParsed: func,
  disabled: bool,
};
