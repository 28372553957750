import {
  Spin, Row, Col,
} from 'antd';
import { useLocation } from 'react-router-dom';
import { useRequest } from 'ahooks';
import qs from 'query-string';
import { getCapitalDetail } from '@/services/capital';
import './index.less';
import flower from '@/assets/zsyz-flower.png';
import detailIcon from '@/assets/zsyz-detail-icon.png';
import nameIcon from '@/assets/zsyz-name.png';
import typeIcon from '@/assets/zsyz-type.png';
import dwIcon from '@/assets/zsyz-dw.png';
import contentIcon from '@/assets/zsyz-content.png';

export default function Detail() {
  const { search } = useLocation();
  const { id } = qs.parse(search);

  const { data, loading } = useRequest(() => getCapitalDetail(id));

  return (
    <div className="insurance-info">
      <Spin spinning={loading}>
        <div className="zsyz__detail_banner">项目详情</div>
        <div className="content-width detail-content-background">
          <div style={{ position: 'relative' }}>
            <img src={flower} alt="" width={265} height={180} className="detail-content-background-logo" />
          </div>
          <Row justify="space-around">
            <Col span={4}>
              <div style={{ textAlign: 'center' }}>
                <img src={detailIcon} alt="" width={42} height={42} />
                <div>
                  <span style={{ fontSize: 24, fontWeight: 600 }}>项目详情</span>
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div style={{ textAlign: 'center' }}>
                <div className="zsyz-detail-list-row-title">投资总金额（万元）</div>
                <div className="zsyz-detail-list-row-name">
                  <span>{data?.investmentAmount}</span>
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div style={{ textAlign: 'center' }}>
                <div className="zsyz-detail-list-row-title">项目建设地址</div>
                <div className="zsyz-detail-list-row-name">
                  {data?.address}
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div style={{ textAlign: 'center' }}>
                <div className="zsyz-detail-list-row-title">联系人</div>
                <div className="zsyz-detail-list-row-name">
                  {data?.projectPerson}
                </div>
              </div>
            </Col>
            <Col span={4}>
              <div style={{ textAlign: 'center' }}>
                <div className="zsyz-detail-list-row-title">联系电话</div>
                <div className="zsyz-detail-list-row-name">
                  <span>
                    {data?.projectTel}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <div className="zsyz-detail-content-bg">
            <div className="zsyz-detail-content-div">
              <img src={nameIcon} alt="" width={24} height={24} />
              <span className="zsyz-detail-content-title">项目名称</span>
              <div style={{ marginTop: 10 }}>{data?.projectName}</div>
            </div>
            <div className="zsyz-detail-content-div">
              <img src={typeIcon} alt="" width={24} height={24} />
              <span className="zsyz-detail-content-title">项目类型</span>
              <div style={{ marginTop: 10 }}>{data?.projectTypeName}</div>
            </div>
            <div className="zsyz-detail-content-div">
              <img src={dwIcon} alt="" width={24} height={24} />
              <span className="zsyz-detail-content-title">项目推介单位</span>
              <div style={{ marginTop: 10 }}>{data?.orgName}</div>
            </div>
            <div className="zsyz-detail-content-div">
              <img src={contentIcon} alt="" width={24} height={24} />
              <span className="zsyz-detail-content-title">项目内容</span>
              <div style={{ marginTop: 10 }}>{data?.content}</div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}
