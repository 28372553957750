import { Modal, Spin, message } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { isEmpty } from 'lodash';
import moment from 'moment';
import FilterType from '@/components/FilterType/FilterType';
import { getDictType } from '@/services/common';
import { BUSINESS_STATUS, STATUS, GMT_INFORMATION_STATUS } from '@/utils/mappingDict';
import { getApplication, deleteApplication } from '@/services/myApplication';
import EmptyImg from '@/components/EmptyImg/EmptyImg';
import OtherCard from '../components/Card/OtherCard';
import MajorProjectCard from '../components/Card/MajorProjectCard';
import './index.less';
import FacilityLoanCard from '../components/Card/FacilityLoanCard';
import CreditGuaranteeCard from '../components/Card/CreditGuaranteeCard';
import IndustryBankLoanCard from '../components/Card/IndustryBankLoanCard';

const dictParams = [BUSINESS_STATUS, STATUS, GMT_INFORMATION_STATUS].join(',');

export default function Index() {
  const Navigate = useNavigate();
  const [val, setVal] = useState({});

  const { data: dict = {} } = useRequest(getDictType, { // 筛选项字典
    defaultParams: [dictParams],
  });
  const { data, loading, run } = useRequest(getApplication, { // 列表
    manual: true,
  });
  const { run: delRun } = useRequest(deleteApplication, { // 取消申请
    manual: true,
    onSuccess() {
      message.success('取消成功');
      run(val);
    },
  });

  useEffect(() => {
    run({});
  }, []);

  function handleChange(e, type) {
    setVal({ ...val, [type]: e });
    run({ ...val, [type]: e });
  }
  function handleTo(e) {
    Navigate(`/myApplication/detail?id=${e.applicationId}&type=${e.type}`);
  }
  function handleOpen(e) {
    let hintMsg = '您确定取消申请吗？';
    const { filingDeadline } = e;

    // 对于有截止日期的申请，使用不同提醒文字
    if (filingDeadline && moment().isAfter(moment(filingDeadline))) {
      hintMsg = '当前日期已超过申报截止日期，如果取消申报，则无法再填写申报。如果申报内容填写有误，可以请联系相关部门退回申报，您可以在退回申报基础上修改后再提交。请问是否确定取消申报？';
    }

    Modal.confirm({
      content: <div>{hintMsg}</div>,
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        delRun({ id: e.applicationId, type: e.type });
      },
    });
  }

  return (
    <div className="myapplication">
      <Spin spinning={loading}>
        <div className="content-width">
          <div className="myapplication__title">我的申请</div>
          <div className="myapplication__filter">
            <FilterType title="业务类型" data={dict?.businessStatus} onChange={(e) => handleChange(e, 'applicationType')} />
            {/* <FilterType title="申请进度" data={dict?.status} onChange={(e) => handleChange(e, 'status')} /> */}
            <FilterType title="申请时间" data={dict?.gmtInformationStatus} onChange={(e) => handleChange(e, 'gmtInformationStatus')} />
          </div>
          <div className="myapplication__list">
            {
              isEmpty(data) ? <EmptyImg />
                : (
                  <div>
                    {data?.map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index}>
                        {
                          item.type === 5 && <MajorProjectCard data={item} onCancel={() => handleOpen(item)} onSchedule={() => handleTo(item)} />
                        }
                        {
                          item.type === 6 && <FacilityLoanCard data={item} onCancel={() => handleOpen(item)} onSchedule={() => handleTo(item)} />
                        }
                        {
                          item.type === 7 && <CreditGuaranteeCard data={item} onCancel={() => handleOpen(item)} onSchedule={() => handleTo(item)} />
                        }
                        {
                          item.type === 8 && <IndustryBankLoanCard data={item} onCancel={() => handleOpen(item)} onSchedule={() => handleTo(item)} />
                        }
                        {
                          item.type < 5 && <OtherCard data={item} onCancel={() => handleOpen(item)} onSchedule={() => handleTo(item)} />
                        }
                      </div>
                    ))}
                  </div>
                )
            }
          </div>
        </div>
      </Spin>
    </div>
  );
}
