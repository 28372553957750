import {
  Layout, Menu, Dropdown, Cascader,
} from 'antd';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { isEmpty, find, last } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getArea } from '@/services/common';
import logo from '@/assets/logo.png';
import avatar from '@/assets/user.png';
import down from '@/assets/icon_dropdown.png';
import { clearToken, getToken } from '@/utils/token';
import './index.less';
import { getUserBasicInfo, getUserInfo } from '@/services/user';

const { Header, Content, Footer } = Layout;

// 路由
const items = [
  { key: '/home', label: '首页' },
  { key: '/bank', label: '银行直贷' },
  { key: '/insurance', label: '保险助农' },
  { key: '/guarantee', label: '担保助贷' },
  { key: '/majorFinancing', label: '重大项目融资' },
  { key: '/capital', label: '招商项目' },
  {
    key: '/loanBenefits',
    label: '贷款贴息',
    children: [
      { key: '/facilityLoan', label: '设施农业' },
      { key: '/industryBankLoan', label: '农业产业化' },
    ],
  },
  { key: '/creditGuarantee', label: '担保奖补' },
  { key: '/policy', label: '政策发布' },
  { key: '/about', label: '关于我们' },
];

// 页脚固定数据显示
// const footerArr = ['中国人民银行成都分行', '四川省发展和改革委员会', '四川省经济和信息化厅', '四川省科学技术厅',
//   '四川省财政厅', '四川省人力资源和社会保障厅', '四川省生态环境厅', '四川省农业农村厅', '四川省商务厅',
//   '四川省市场监督管理局', '四川省地方金融监督管理局', '四川省大数据中心'];
// const { BMap } = window;

const date = new Date();
const year = date.getFullYear(); // 年

export default function Index() {
  const Navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const token = getToken();
  const user = useSelector((state) => state.userInfo);
  const areaState = useSelector((state) => state.area);
  const { userType } = useSelector((state) => state);
  const [selValue, setSelValue] = useState(['四川省']); // 区域选择数据
  const [selectedKeys, setSelectedKeys] = useState('/home'); // 选中菜单
  const [menu, setMenu] = useState(items); // 菜单

  function getAddress() {
    const areaName = sessionStorage.getItem('areaName');
    if (!areaName) {
      /* 测试期间不使用定位功能 */
      // 获取当前定位
      // const myCity = new BMap.LocalCity();
      // myCity.get((result) => {
      //   setSelValue([result?.name]);
      //   const obj = find(arr, { name: result?.name });
      //   sessionStorage.setItem('areaName', obj?.id);
      // });
      sessionStorage.setItem('areaName', '510000');
    }
  }

  function getNewArea(data, newArr = []) {
    data?.map((item) => {
      newArr.push({
        id: item.id,
        name: item.name,
        code: item.code,
        level: item.level,
      });
      if (!isEmpty(item?.children)) {
        getNewArea(item.children, newArr);
      }
      return item;
    });
    return newArr;
  }

  // 区域数据
  const { data: area = [] } = useRequest(getArea, {
    onSuccess: (res) => {
      dispatch({ type: 'AREA', payload: res });
      const arr = getNewArea(res);
      getAddress(arr);
    },
  });
  // 获取用户信息
  const { run: userRun } = useRequest(getUserInfo, {
    manual: true,
    onSuccess: (res) => {
      dispatch({ type: 'USER_INFO', payload: res });
    },
  });
  // 获取用户经营信息
  const { run: userBasicRun } = useRequest(getUserBasicInfo, {
    manual: true,
    onSuccess: (res) => {
      dispatch({ type: 'USER_BASIC_INFO', payload: res });
    },
  });

  useEffect(() => {
    // 登录成功，token存在获取用户的所有信息
    if (token) {
      userRun();
      userBasicRun();
    }
  }, []);
  useEffect(() => {
    if (userType === 2) {
      const temp = items.filter((item) => item.key !== '/majorFinancing' && item.key !== '/creditGuarantee');
      setMenu(temp);
    }
  }, [userType]);

  useEffect(() => {
    if (pathname === '/') {
      Navigate('/home');
    }
    const temp = pathname.split('/');
    setSelectedKeys(`/${temp[1]}`);
  }, [Navigate, pathname]);

  useEffect(() => {
    const areaName = sessionStorage.getItem('areaName');
    const cityName = sessionStorage.getItem('cityName');
    if (areaName) {
      const arr = getNewArea(areaState);
      const obj = find(arr, { code: areaName });
      let name = [obj?.name];
      if (obj?.level === 3 && cityName) {
        name = [cityName, name];
      }
      setSelValue(name);
    }
  }, []);

  function toPage(e) {
    if (e.key === 'login') {
      clearToken();
      dispatch({ type: 'STATUS', payload: '' });
      dispatch({ type: 'USER_INFO', payload: {} });
      dispatch({ type: 'USER_BASIC_INFO', payload: {} });
      dispatch({ type: 'USER_TYPE', payload: null });
    }
    Navigate(e.key);
  }
  function toLogin(e) {
    Navigate(e);
  }

  function onChange(e) {
    // 处理选中区域，只显示最后层级
    const temp = area[0].children;
    let { name, code } = area[0];
    let cityName = '';
    if (e.length === 2) {
      const obj = find(temp, ['id', last(e)]);
      name = [obj?.name];
      code = obj?.code;
    } else if (e.length === 3) {
      const city = find(temp, ['id', e[1]]);
      const obj = find(city.children, ['id', last(e)]);
      name = [city?.name, obj?.name];
      code = obj?.code;
      cityName = city?.name;
    }
    sessionStorage.setItem('areaName', code);
    sessionStorage.setItem('cityName', cityName);
    setSelValue(name);
  }

  function onDropdownVisibleChange(e) {
    if (!e) {
      window.location.reload();
    }
  }
  return (
    <Layout>
      <Header className="header">
        <div className="header-box">
          <div className="header-logo-box">
            <div className="header-logo">
              <img src={logo} alt="" width={333} height={60} />
              <Cascader
                options={area}
                onChange={onChange}
                onDropdownVisibleChange={onDropdownVisibleChange}
                changeOnSelect
                value={selValue}
                allowClear={false}
                fieldNames={{ label: 'name', value: 'id', children: 'children' }}
              />
            </div>
            <div className="header-right">
              {
                isEmpty(user) ? (
                  <div className="login-btn-box">
                    <div className="login-btn" onClick={() => toLogin('/login')}>登录</div>
                    <div className="register-btn" onClick={() => toLogin('/register')}>注册</div>
                  </div>
                ) : (
                  <div className="user-info">
                    <div className="avatar"><img src={user?.photo || avatar} alt="" width={32} height={32} /></div>
                    <Dropdown overlay={(
                      <Menu
                        items={[
                          { key: 'user', label: '个人中心' },
                          { key: 'myApplication', label: '我的申请' },
                          { key: 'login', label: '退出登录' },
                        ]}
                        onClick={toPage}
                      />
                    )}
                    >
                      <div className="user-name">
                        {user?.realName || user?.userName}
                        {' '}
                        &nbsp;
                        <img src={down} alt="" />
                      </div>
                    </Dropdown>
                  </div>
                )
              }

            </div>
          </div>
          <div className="header-menu-box">
            <Menu
              mode="horizontal"
              items={menu}
              onClick={toPage}
              selectedKeys={selectedKeys}
            />
          </div>
        </div>
      </Header>
      <Content>
        {/* 路由 */}
        <div className="page-height">
          <Outlet />
        </div>
      </Content>
      <Footer>
        <div className="footer__company">
          {/* <div>指导单位：</div> */}
          {/* <div className="footer__company-info"> */}
          {/*  { */}
          {/*    footerArr.map((item, index) => ( */}
          {/*                    // eslint-disable-next-line react/no-array-index-key */}
          {/*                    <div className="footer__company-info-item" key={index.toString()}>{item}</div> */}
          {/*    )) */}
          {/*              } */}

          {/* </div> */}
          <div className="friend-chain">
            友情链接：
            <a href="http://nynct.sc.gov.cn/" target="_blank" rel="noreferrer">四川省农业农村厅</a>
            <a href="https://www.tianfucredit.cn" target="_blank" rel="noreferrer">天府信用通</a>
          </div>
          <div className="footer__filing">
            Copyright©2022-
            {year}
            {' '}
            四川征信有限公司  版权所有
            {/* |  蜀ICP备17042962号-5 */}
          </div>
        </div>
      </Footer>
    </Layout>
  );
}
