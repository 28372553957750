// 注册授权书
const AuthorizationInfo = `<div style="text-align: left">
<h1 style="text-align: center;margin: 20px 0">“信贷直通车”平台用户服务协议</h1>
<div style="text-indent:20px;">欢迎您使用“信贷直通车”平台！</div>
<div style="text-indent:20px;font-weight: bold;margin: 10px 0 20px 0">请您认真阅读并充分理解本协议全部内容。选中本协议链接前的勾选框或点击“同意”即视为您同意并接受本协议的约定。</div>
<div style="text-indent:20px;">名词解释：</div>
<div style="text-indent:20px;">本协议所称“平台”，概指“信贷直通车”平台和平台运营方（即四川征信有限公司）。</div>
<div style="text-indent:20px;">本协议所称“用户”，概指在本平台完成注册、认证并使用本平台服务的您及您所代表的涉农主体（包括不限于农户、自然人、企业、工商个体户、事业单位、社会团体等）。</div>
<h3 style="text-indent:20px;margin: 30px 0 10px 0">一、服务内容和方式</h3>
<p style="text-indent:20px;">1.平台旨在为用户提供优质的综合金融服务与征信服务，包括但不限于为用户与金融机构、中介机构之间的投融资等行为提供信息归集与发布、交易撮合服务、企业征信服务及其它相关服务。</p>
<p style="text-indent:20px;">2.用户在平台注册或使用平台服务时须提供用户身份信息、联系方式等必要的相关信息，并有义务确保所提供全部信息的真实性、准确性和有效性。因用户未提供或未及时更新相关信息，导致造成的损失及法律后果由用户自行承担。</p>
<p style="text-indent:20px;">
3.平台不作为出资方或融资方参与相关交易，第三方机构在平台上所发布信息的合法性、安全性、真实性、准确性均由该第三方机构完全、独立的负责。平台提醒用户应谨慎判断相关信息的真实性、合法性和有效性。
</p>
<p style="text-indent:20px;">4.由于平台建设是持续完善的过程，可能会出现部分服务暂未开通或需完善的情形，但不会影响用户正常使用平台基本功能。用户应知悉并充分理解上述情况，确认不因上述原因追究平台的任何责任。</p>
<h3 style="text-indent:20px;margin: 30px 0 10px 0">二、用户信息与隐私保护</h3>

<p style="text-indent:20px;">5.本协议所指用户信息包括但不限于用户在平台主动填报的信息、用户在使用平台服务时生成的信息、平台依法获取的用户公开信息，以及依法从其他信息提供者处获取的用户其他信息。</p>
<p style="text-indent:20px;">
6.关于信息采集使用及隐私保护相关详细条款请在办理平台相关具体业务时参见《“信贷直通车”平台信息授权协议》。用户办理平台相关具体业务并确认《“信贷直通车”平台信息授权协议》之前，平台不会以任何方式获取、保存、使用您的任何非公开信息。
</p>

<h3 style="text-indent:20px;margin: 30px 0 10px 0">三、用户权利与义务</h3>

<p style="text-indent:20px;">7.用户保证向平台提供的信息不含有任何违反中华人民共和国有关法律法规、监管政策或有违社会公序良俗的内容。</p>
<p style="text-indent:20px;">8.用户应当妥善保存自己的用户账号和密码，不得以任何形式转让或授权他人使用自己的用户账号；用户对其在平台注册用户账号和密码的安全性，以及以其用户账号进行的所有活动和事件负全部责任。</p>
<p style="text-indent:20px;">
9.用户应当合法合规地使用平台服务，平台若发现用户实施违法违规行为，则有权停止该用户使用本平台服务，且该用户应当自行出面接受相关主管机关审查或质询、参与相关方协商或诉讼，承担所有相关费用，并赔偿因此给平台造成的全部损失。
</p>
<p style="text-indent:20px;">10.用户若发现任何非法使用其账号、对用户信息不当使用、平台存在安全漏洞等情况，应立即通知平台，并配合平台采取必要措施以保护用户权益与信息安全。平台可能采取的措施包括不限于：追踪帐号操作行为、停止部分或全部服务、冻结账号等。</p>
<p style="text-indent:20px;">11.用户使用平台服务过程中的问题应及时向平台反馈，平台将及时解决。用户不得捏造、歪曲事实，散布不实、不良言论，侮辱、诽谤平台，给平台造成声誉损失或经济损失。针对上述行为，平台运营方保留依法追究用户损害赔偿责任的权利。</p>
<p style="text-indent:20px;">12.用户有权在与平台或相关第三方机构的全部业务均已终止的情况下，主动注销平台账号。</p>

<h3 style="text-indent:20px;margin: 30px 0 10px 0">四、平台权利与义务</h3>
<p style="text-indent:20px;">
13.平台有权对用户提供的信息进行人工审核，该审核仅为形式审核，无法保证信息内容的真实性、准确性、有效性和完整性；本平台有权对用户信息进行复核，因部分服务场景需要时平台有权要求用户提供佐证信息证明信息的真实性及合法性，若用户无法提供有效证明，平台有权删除相关信息或终止提供相关服务。
</p>
<p style="text-indent:20px;">14.在相关法律法规等许可范围内，平台拥有对用户所提供信息的使用权；该使用权在用户使用平台服务期间持续有效。</p>
<p style="text-indent:20px;">15.平台有权在提供服务期间，通过电子邮件、短信、站内信息等方式向用户发送服务信息；用户有权向平台提出拒绝接受某类信息及相关服务。</p>
<p style="text-indent:20px;">
16.因不可抗力、非平台原因所发生的系统故障影响平台正常运行的，平台承诺故障致因结束后及时修复；用户因此而产生的经济和精神损失，平台不承担任何直接或间接的责任。本条所称“不可抗力”，指本协议签订时双方不可预见，在合同履行过程中不可避免且不能克服的自然灾害和社会性突发事件，如地震、海啸、瘟疫、骚乱、戒严、暴动、战争等情形；本条所称“非平台原因”，
指除用户及平台之外的第三方机构或主体的故意或过失，如黑客袭击、网络运营商故障、合作机构系统故障等。
</p>
<div style="text-indent:20px;">
17.因国家法律法规、监管政策等发生变更，或按照有权机构、监管部门的相关要求，平台有权限制、改变、减少、终止或暂时停止提供平台部分或全部服务功能。用户因此而产生的经济和精神损失，平台不承担任何直接或间接的责任。
</div>
<h3 style="text-indent:20px;margin: 30px 0 10px 0">五、免责声明</h3>

<p style="text-indent:20px;">
18.平台本着开放共享、求真务实的精神，为广大用户提供优质的综合性服务。用户通过平台进行投融资等活动时，应认真审慎做出决策，因用户与平台之外的第三方机构业务所引起的经济、法律纠纷，平台不承担任何法律或赔偿责任。
</p>
<p style="text-indent:20px;">19.平台及第三方机构有权根据从合法渠道获取的信息对用户资信能力等相关情况进行判断，并由第三方机构决定其审批结果。平台不对用户申请的服务最终是否能够获得第三方机构批准作出任何保证，也不承担因此产生的任何责任。</p>
<p style="text-indent:20px;">20.因用户主动注销平台账号或其他非平台原因所造成服务的暂停、中断或终止不代表本协议所约定用户义务与责任的终止，平台仍有权就用户使用平台服务期间的违法违规行为追究用户相关法律法规责任。</p>

<h3 style="text-indent:20px;margin: 30px 0 10px 0">六、版权说明</h3>

<p style="text-indent:20px;">21.平台所有内容均受中华人民共和国相关法律法规等保护，且由平台运营方依法拥有其知识产权。用户如有侵害相关知识产权的行为，将对平台及/或其他权利人承担损害赔偿等法律责任。</p>
<p style="text-indent:20px;">22.未经平台运营方书面许可，任何主体不得以任何方式使用平台或其关联公司的名字、商标、标识或其他专有信息（包括但不限于图像、文字、网页设计或形式），严禁对平台内容进行系统获取，直接或间接创建或编辑文集、汇编、数据库或人名地址录等，否则平台运营方有权追究其损害赔偿等法律责任。</p>

<h3 style="text-indent:20px;margin: 30px 0 10px 0">七、附则</h3>

<p style="text-indent:20px;">23.本协议的订立、执行和解释及争议解决均适用中华人民共和国法律。如因本协议所引起或与其有关的任何争议应向成都仲裁委员会提起仲裁申请。</p>
<p style="text-indent:20px;">24.平台有权在必要时修改服务条款，并在发生修改时在平台首页或公告栏上进行提示，或通过邮件、短信、站内信等其他形式告知用户。用户继续使用平台服务即视为接受服务条款的变动。</p>
<p style="text-indent:20px;">25.本协议对四川征信有限公司所运营的、与“信贷直通车”平台共享用户体系的“天府信用通”平台等其他关联平台同样有效。用户同意并接受本协议，视为对相关关联平台作出等同于对“信贷直通车”平台的承诺与授权。</p>
<p style="text-indent:20px;">26.本协议自用户成功注册平台之日起生效，至用户与平台及第三方全部业务终结并注销平台账号时终止。本协议终止并不免除用户根据本协议或其他有关协议、规则所应承担的义务和责任。</p>

</div>`;

export default AuthorizationInfo;
