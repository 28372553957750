import { Form, Input, Button } from 'antd';
import { func, bool, object } from 'prop-types';
import { useState } from 'react';
import { useRequest } from 'ahooks';
import CodeInput from '@/components/CodeInput/CodeInput';
import CustomFooter from '@/components/CustomFooter/CustomFooter';
import { verifyCode } from '@/services/user';
import '../index.less';

export default function UpdateTel({
  onOk, onCancel, confirmLoading, data,
}) {
  const [form] = Form.useForm();
  const [isCurrentTel, setIsCurrentTel] = useState(true);
  const [phone, setPhone] = useState(data?.phone);

  const { run, loading } = useRequest(verifyCode, { // 校验验证码
    manual: true,
    onSuccess() {
      form.resetFields();
      setIsCurrentTel(false);
    },
  });

  function onFinish() {
    form.validateFields().then((values) => {
      if (isCurrentTel) {
        run({ account: data?.phone, code: values.code });
      } else {
        onOk(values);
      }
    });
  }

  function compareOldPhone(rule, value) {
    const promise = Promise;
    if (value === data?.phone) {
      return promise.reject('新手机号不能和旧手机号码一致!');
    }
    setPhone(value);
    return promise.resolve();
  }

  return (
    <div className="update-tel-form">
      <Form
        form={form}
        name="form"
      >
        {
            isCurrentTel ? (
              <div className="update-tel-form__title">
                手机号码：
                {data?.phone}
              </div>
            ) : (
              <Form.Item
                name="account"
                rules={[
                  {
                    required: true,
                    message: '请输入新手机号',
                  },
                  { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号码格式' },
                  { validator: compareOldPhone },
                ]}
              >
                <Input placeholder="请输入新手机号" />
              </Form.Item>
            )
        }
        <Form.Item>
          <CodeInput phone={phone} reset={isCurrentTel} />
        </Form.Item>

      </Form>
      {
          isCurrentTel ? (
            <div className="modal-footer-btn">
              <Button type="primary" onClick={onFinish} loading={loading}>下一步</Button>
            </div>
          ) : (
            <CustomFooter onOk={onFinish} onCancel={onCancel} confirmLoading={confirmLoading} />
          )
      }
    </div>
  );
}

UpdateTel.propTypes = {
  onOk: func,
  onCancel: func,
  confirmLoading: bool,
  data: object,
};
