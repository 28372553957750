import {
  Col, Collapse, Row, Form, Upload, Button, message, Card,
} from 'antd';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { any } from 'prop-types';
import { useState } from 'react';
import { uploadFileUrl } from '@/services/majorFinancing';

export default function OtherUpload({ formRef }) {
  const props = {
    name: 'file',
    accept: '.rar,.zip,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.pdf',
    action: uploadFileUrl,
    headers: {
      authorization: 'authorization-text',
    },
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        message.error('请上传200M以下文件!');
      }
      return isLt2M;
    },
  };

  function getValueFromEvent(e) {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  }

  // 法人执照副本及章程，国家乡村农业振兴带头人结业证书
  // const [bylawsCertificate, setBylawsCertificate] = useState([]);
  // 上年度财务报表
  // const [lastYearFinancials, setLastYearFinancials] = useState([]);
  // 证明与农民利益联结的相关资料
  // const [farmersBenefitDocs, setFarmersBenefitDocs] = useState([]);
  // 人民银行出具的完整的《企业信用报告》
  const [pboeCreditReport, setPboeCreditReport] = useState([]);
  // 承诺书
  const [commitmentLetter, setCommitmentLetter] = useState([]);
  // 人民银行签章的《信用报告异议信息专项证明》
  const [creditReportDisputeCertificate, setCreditReportDisputeCertificate] = useState([]);
  // 其他必要的相关材料
  const [otherRequiredDocuments, setOtherRequiredDocuments] = useState([]);
  /**
   * 生成图片列表
   */
  const generateFileInfo = (fileList) => fileList.map((file) => {
    console.log('file', file);
    return {
      fileName: file.name || file.fileName,
      fileURL: file?.response?.data || file.fileURL || file.url,
    };
  });

  return (
    <Card title="其他证明材料">
      <Collapse defaultActiveKey={['1']}>
        <Collapse.Panel key="1" header="证明材料">
          <Row>
            <Col span={24}>
              <Form.Item
                style={{ display: 'none' }}
                name={['documentation', 'bylawsCertificate']}
              />
              <Form.Item
                required
                name={['documentation', 'bylawsCertificate_i']}
                label="法人执照副本及章程，国家乡村产业振兴带头人结业证书"
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                rules={[
                  {
                    required: true,
                    message: '请上传法人执照副本及章程，国家乡村产业振兴带头人结业证书！',
                  },
                ]}
                labelCol={{
                  sm: { span: 9 },
                }}
                wrapperCol={{
                  sm: { span: 15 },
                }}
              >
                <Upload
                  {...props}
                  // fileList={bylawsCertificate}
                  onChange={
                    ({ file, fileList }) => {
                      // setBylawsCertificate(fileList);
                      formRef.setFieldsValue({
                        documentation: {
                          bylawsCertificate: generateFileInfo(fileList),
                        },
                      });
                      if (file.status === 'done') {
                        message.success(`文件【${file.name}】上传成功`);
                      } else if (file.status === 'error') {
                        message.error('上传失败');
                      }
                    }
                  }
                >
                  <Button icon={<UploadOutlined />}>上传文件</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                style={{ display: 'none' }}
                name={['documentation', 'lastYearFinancials']}
              />
              <Form.Item
                required
                label="上年度财务报表"
                name={['documentation', 'lastYearFinancials_i']}
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                rules={[
                  {
                    required: true,
                    message: '请上传上年度财务报表！',
                  },
                ]}
                labelCol={{
                  sm: { span: 9 },
                }}
                wrapperCol={{
                  sm: { span: 15 },
                }}
              >
                <Upload
                  {...props}
                  // fileList={lastYearFinancials}
                  onChange={
                    ({ file, fileList }) => {
                      // setLastYearFinancials(fileList);
                      formRef.setFieldsValue({
                        documentation: {
                          lastYearFinancials: generateFileInfo(fileList),
                        },
                      });
                      if (file.status === 'done') {
                        message.success(`${file.name}上传成功`);
                      } else if (file.status === 'error') {
                        message.error('上传失败');
                      }
                    }
                  }
                >
                  <Button icon={<UploadOutlined />}>上传文件</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                style={{ display: 'none' }}
                name={['documentation', 'farmersBenefitDocs']}
              />
              <Form.Item
                required
                label="证明与农民利益联结的相关资料"
                name={['documentation', 'farmersBenefitDocs_i']}
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                rules={[
                  {
                    required: true,
                    message: '请上传证明与农民利益联结的相关资料',
                  },
                ]}
                labelCol={{
                  sm: { span: 9 },
                }}
                wrapperCol={{
                  sm: { span: 15 },
                }}
              >
                <Upload
                  {...props}
                  // fileList={farmersBenefitDocs}
                  onChange={
                    ({ file, fileList }) => {
                      // setFarmersBenefitDocs(fileList);
                      formRef.setFieldsValue({
                        documentation: {
                          farmersBenefitDocs: generateFileInfo(fileList),
                        },
                      });
                      if (file.status === 'done') {
                        message.success('上传成功');
                      } else if (file.status === 'error') {
                        message.error('上传失败');
                      }
                    }
                  }
                >
                  <Button icon={<UploadOutlined />}>上传文件</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                style={{ display: 'none' }}
                name={['documentation', 'pboeCreditReport']}
              />
              <Form.Item
                required
                label="人民银行出具的完整的《企业信用报告》"
                name={['documentation', 'pboeCreditReport_i']}
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                rules={[
                  {
                    required: true,
                    message: '请上传人民银行出具的完整的《企业信用报告》！',
                  },
                ]}
                labelCol={{
                  sm: { span: 9 },
                }}
                wrapperCol={{
                  sm: { span: 15 },
                }}
              >
                <Upload
                  {...props}
                  fileList={pboeCreditReport}
                  onChange={
                    ({ file, fileList }) => {
                      setPboeCreditReport(fileList);
                      formRef.setFieldsValue({
                        documentation: {
                          pboeCreditReport: generateFileInfo(fileList),
                        },
                      });
                      if (file.status === 'done') {
                        message.success('上传成功');
                      } else if (file.status === 'error') {
                        message.error('上传失败');
                      }
                    }
                  }
                >
                  <Button icon={<UploadOutlined />}>上传文件</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                style={{ display: 'none' }}
                name={['documentation', 'commitmentLetter']}
              />
              <Form.Item
                required
                label={(
                  <div>
                    承诺书
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      onClick={
                        () => {
                          const a = document.createElement('a');
                          a.href = './TLP/tlp2.docx';
                          a.download = '承诺书.docx';
                          a.click();
                        }
                      }
                    >
                      模板下载
                    </Button>
                  </div>
                )}
                name={['documentation', 'commitmentLetter_i']}
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                rules={[
                  {
                    required: true,
                    message: '请上传承诺书！',
                  },
                ]}
                labelCol={{
                  sm: { span: 9 },
                }}
                wrapperCol={{
                  sm: { span: 15 },
                }}
              >

                <Upload
                  {...props}
                  fileList={commitmentLetter}
                  valuePropName="fileList"
                  getValueFromEvent={getValueFromEvent}
                  onChange={
                      ({ file, fileList }) => {
                        setCommitmentLetter(fileList);
                        formRef.setFieldsValue({
                          documentation: {
                            commitmentLetter: generateFileInfo(fileList),
                          },
                        });
                        if (file.status === 'done') {
                          message.success('上传成功');
                        } else if (file.status === 'error') {
                          message.error('上传失败');
                        }
                      }
                    }
                >
                    <Button icon={<UploadOutlined />}>上传文件</Button>
                </Upload>

              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                style={{ display: 'none' }}
                name={['documentation', 'creditReportDisputeCertificate']}
              />
              <Form.Item
                name={['documentation', 'creditReportDisputeCertificate_i']}
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                label={(
                  <div style={{ lineHeight: '16px', textAlign: 'center' }}>
                    人民银行签章的《信用报告异议信息专项证明》
                    <Button
                      type="link"
                      icon={<DownloadOutlined />}
                      style={{ height: 20, padding: 0, overflow: 'hidden' }}
                      onClick={
                        () => {
                          const a = document.createElement('a');
                          a.href = './TLP/tlp1.docx';
                          a.download = '信用报告异议信息专项证明.docx';
                          a.click();
                        }
                      }
                    >
                      模板下载
                    </Button>
                    <br />
                    （对企业信用报告有异议的才提供，需当地人民银行所盖鲜章）

                  </div>
                )}
                labelCol={{
                  sm: { span: 9 },
                }}
                wrapperCol={{
                  sm: { span: 15 },
                }}
              >
                <Upload
                  {...props}
                  fileList={creditReportDisputeCertificate}
                  onChange={
                    ({ file, fileList }) => {
                      setCreditReportDisputeCertificate(fileList);
                      formRef.setFieldsValue({
                        documentation: {
                          creditReportDisputeCertificate: generateFileInfo(fileList),
                        },
                      });
                      if (file.status === 'done') {
                        message.success('上传成功');
                      } else if (file.status === 'error') {
                        message.error('上传失败');
                      }
                    }
                  }
                >
                  <Button icon={<UploadOutlined />}>上传文件</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                style={{ display: 'none' }}
                name={['documentation', 'otherRequiredDocuments']}
              />
              <Form.Item
                required
                name={['documentation', 'otherRequiredDocuments_i']}
                valuePropName="fileList"
                getValueFromEvent={getValueFromEvent}
                label={(
                  <div style={{ lineHeight: '16px', textAlign: 'center' }}>
                    其他必要的相关材料
                    <br />
                    （如：证明用于固定资产投资的材料等）
                  </div>
                )}
                rules={[
                  {
                    required: true,
                    message: '请上传其他必要的相关材料！',
                  },
                ]}
                labelCol={{
                  sm: { span: 9 },
                }}
                wrapperCol={{
                  sm: { span: 15 },
                }}
              >
                <Upload
                  {...props}
                  fileList={otherRequiredDocuments}
                  onChange={
                    ({ file, fileList }) => {
                      setOtherRequiredDocuments(fileList);
                      formRef.setFieldsValue({
                        documentation: {
                          otherRequiredDocuments: generateFileInfo(fileList),
                        },
                      });
                      if (file.status === 'done') {
                        message.success('上传成功');
                      } else if (file.status === 'error') {
                        message.error('上传失败');
                      }
                    }
                  }
                >
                  <Button icon={<UploadOutlined />}>上传文件</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </Card>
  );
}
OtherUpload.propTypes = {
  formRef: any,
};
