import { object } from 'prop-types';
import './CapitalCard.less';
import { Button, Tooltip } from 'antd';
import folder from '@/assets/folder.png';

/**
 * 保险产品card
 * @param {object} data 数据源
 * @param {string} type 政策性保险还是涉农保险
 * @param {string} areaCode 市级code
 * */

export default function CapitalCard({
  data,
}) {
  function handleChange(id) {
    window.open(`#/capital/detail?id=${id}`, '_blank');
  }

  return (
    <div className="product-list-row">
      <div className="capital-type-tag">{data?.projectTypeName}</div>
      <img src={folder} alt="" width={50} height={50} />
      <div className="product-list-row-img">
        <div>
          <div>项目名称</div>
          <div className="capital-list-row-name" style={{ color: '#12A366', width: 230 }}>
            <Tooltip title={data?.projectName} placement="topLeft">
              <span>{data?.projectName}</span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="capital-list-row-info">
        <div>
          <div>项目推介单位</div>
          <div className="capital-list-row-name" style={{ width: 230 }}>
            <Tooltip title={data?.orgName} placement="topLeft">
              <span>{data?.orgName}</span>
            </Tooltip>
          </div>
        </div>
        <div>
          <div>
            项目建设地址
          </div>
          <div className="capital-list-row-name" style={{ width: 190 }}>
            <Tooltip title={data?.address} placement="topLeft">
              {data?.address}
            </Tooltip>
          </div>
        </div>
        <div>
          <div>投资总金额（万元）</div>
          <div className="capital-list-row-name">
            {data?.investmentAmount}
          </div>
        </div>
        <div>
          <div>联系人/联系电话</div>
          <div className="capital-list-row-name" style={{ width: 190 }}>
            <Tooltip title={`${data?.projectPerson}/${data?.projectTel}`} placement="topLeft">
              <span>
                {data?.projectPerson}
                /
                {data?.projectTel}
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
      <div>
        <Button type="primary" className="capital-product-btn" onClick={() => handleChange(data.id)}>
          详情
        </Button>
      </div>
    </div>
  );
}

CapitalCard.propTypes = {
  data: object,
};
