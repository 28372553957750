import {
  USER_INFO, USER_BASIC_INFO, AREA, STATUS, USER_TYPE,
} from './types';

const initialState = {
  userInfo: {},
  userBasicInfo: {},
  area: [],
  status: '',
  userType: null,
};

const global = (state = initialState, action) => {
  switch (action.type) {
    case USER_INFO:
      return { ...state, userInfo: action.payload };
    case USER_BASIC_INFO:
      return { ...state, userBasicInfo: action.payload };
    case AREA:
      return { ...state, area: action.payload };
    case STATUS:
      return { ...state, status: action.payload };
    case USER_TYPE:
      return { ...state, userType: action.payload };
    default:
      return state;
  }
};
export default global;
