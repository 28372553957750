import { useEffect } from 'react';
import { Spin, Button, Affix } from 'antd';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { useRequest } from 'ahooks';
import { isEmpty, isNull } from 'lodash';
import moment from 'moment';
import { attchmentFileDownload2, getFacilityLoanDetail } from '@/services/industryBankLoan';
import { getToken } from '@/utils/token';
import './index.less';
import { downloadFile } from '@/utils/utils';

export default function Detail() {
  const { search } = useLocation();
  const Navigate = useNavigate();

  const { status } = useSelector((state) => state);

  const { data, loading } = useRequest(() => getFacilityLoanDetail(qs.parse(search).id));
  const { run: downloadRun } = useRequest(attchmentFileDownload2, {
    manual: true,
    onSuccess: ({ data: fileData, filename }) => {
      downloadFile(fileData, filename);
    },
  });

  useEffect(() => {
  }, []);

  // 下载附件
  // const handleDownload = (url) => {
  //   window.open(url);
  // };

  function handleChange(item) {
    const token = getToken();
    if (!token) {
      Navigate('/login');
    } else if (status) {
      Navigate('/authentication');
    } else {
      const { id } = item;
      Navigate(`/industryBankLoan/application?id=${id}`, { state: item });
    }
  }

  return (
    <div className="zczx-detail">
      <Spin spinning={loading}>
        <div className="content-width">
          <div className="zczx-detail__title">{data?.title}</div>
          <div>
            &emsp;&emsp;发布时间：
            {data?.gmtCreate}
          </div>
          <div className="zczx-detail__con">
            <div dangerouslySetInnerHTML={{ __html: data?.content }} />
          </div>
          {!isEmpty(data?.loanSubsidyFileVos) && (
            <div className="zczx-detail_appendix">
              <div style={{ lineHeight: '32px' }}>附件：</div>
              {data?.loanSubsidyFileVos.map((item) => (
                <div key={item.id}>
                  <span>{item.fileName}</span>
                  &nbsp;
                  <Button
                    type="primary"
                    icon={<VerticalAlignBottomOutlined />}
                    onClick={() => downloadRun(item.id)}
                  />
                </div>
              ))}
            </div>
          )}
          <Affix style={{ marginTop: 20 }} offsetBottom={0}>
            <div style={{ backgroundColor: 'white' }}>
              {
                (isNull(data?.filingDeadline) || (moment().diff(moment(data?.filingDeadline), 'days') <= 0)) && (
                  <Button style={{ marginBottom: 10 }} type="primary" onClick={() => handleChange(data)}>
                    填写申报材料
                  </Button>
                )
              }
              <div className="zczx-detail_bottom-text">
                申报截止日期：
                {isNull(data?.filingDeadline) ? '-' : moment(data?.filingDeadline).format('YYYY-MM-DD')}
              </div>
            </div>
          </Affix>
        </div>
      </Spin>
    </div>
  );
}
