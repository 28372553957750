/**
 * @description 文件下载方法
 * */
export const downloadFile = (blobData, fileName) => {
  if (!blobData) return;
  const blob = new Blob([blobData]);
  const url = window.URL.createObjectURL(blob); // 创建 url 并指向 blob
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url); // 释放该 url
};

/**
 * @description 单位处理
 * @param {number} num 数据源
 * @return object
 * */
export const getUnitConversion = (num = 0) => {
  const money = { num: 0, unit: '' };
  if (num) {
    // 金额单位数组
    const AmountUnitList = ['', '万', '亿', '兆', '京', '垓', '杼'];
    // 将数字金额转为字符串
    let strnum = num.toString();
    // 声明一个变量用于接收金额单位
    let AmountUnit = '';
    // 循环遍历单位数组
    AmountUnitList.find((item) => {
      let newNum = '';
      // 判断一下传进来的金额是否包含小数点
      if (strnum.indexOf('.') !== -1) {
        // 若有则将小数点前的字符截取出来
        newNum = strnum.substring(0, strnum.indexOf('.'));
      } else {
        // 没有则直接等于原金额
        newNum = strnum;
      }
      // 判断一下经过小数点截取后的金额字符长度是否小于5
      if (newNum.length < 5) {
        // 若小于5则接收当前单位，并跳出迭代
        AmountUnit = item;
        return true;
      }
      // 若不小于5则将经过小数点截取处理过后的字符除以10000后作为下一轮迭代的初始金额重新判断(每一个单位之间相距4位数，故除以10000)
      strnum = ((newNum * 1) / 10000).toString();
      return false;
    });
    // 保留2位小数
    if (num > 0) {
      money.num = strnum.length >= 5 ? (strnum * 1).toFixed(2) : strnum;
    } else {
      money.num = 0;
    }
    // 接收单位
    money.unit = AmountUnit;
  } else {
    money.unit = '';
  }
  return money;
};

/**
 * @description 单位处理
 * @param {number} num 数据源
 * @param {number} baseRatio 倍率
 * @return object
 * */
export const getUnitConversionWithRatio = (num = 0, baseRatio = 1) => {
  const result = getUnitConversion(num * baseRatio);
  return result;
};

/**
 * @description 去掉金额分隔符
 * @param {string} data 数据源
 * @return number
 * */
export const moneyDelete = (data) => {
  if (data && data !== undefined && data !== null) {
    let _num = data;
    _num = _num.toString();
    _num = _num.replace(/,/gi, '');
    return Number(_num);
  }
  return 0;
};
