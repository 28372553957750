/**
 * @name:annualInformation
 * @description:年度信息
 * @author:Jiangh
 */
import { Collapse } from 'antd';
import { any } from 'prop-types';

function AnnualInformation({ annualInformation }) {
  return (
    <div style={{ marginBottom: 20 }}>
      {annualInformation.map((annual, index) => (
        <Collapse defaultActiveKey={index + 1} key={`k-${index + 1}`}>
          <Collapse.Panel key={`${index + 1}`} header={`${annual.year}年度`}>
            <div
              className="myapplication-detail__grid"
              style={{ borderBottom: 'none', paddingBottom: 0 }}
            >
              <div>
                职工人数（农民合作社成员数）:
                {annual.employeeCount}
              </div>
              <div>
                营业收入（万元）:
                {annual.operatingIncome}
              </div>
              <div>
                利润总额(万元):
                {annual.totalProfit}
              </div>
              <div>
                负债总额（万元）:
                {annual.totalLiabilities}
              </div>
              <div>
                资产总额(万元):
                {annual.totalAssets}
                （其中：固定资产（万元）：
                {annual.fixedAssets}
                ）
              </div>
              <div>
                所有者权益(万元):
                {annual.ownerEquity}
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>
      ))}
    </div>
  );
}

export default AnnualInformation;
AnnualInformation.propTypes = {
  annualInformation: any,
};
