import {
  Button, Select, Form, Input, message, Checkbox, Modal,
} from 'antd';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { isEqual } from 'lodash';
import { getSmsCode, register, getRegisterBasicType } from '@/services/login';
import AuthorizationInfo from './authInfo';
import './index.less';

let timer = null;

export default function Index() {
  const Navigate = useNavigate();
  const [form] = Form.useForm();

  const [type, setType] = useState('2'); // 用户类型
  const [time, setTime] = useState(0); // 倒计时
  const [authInfoModal, setAuthInfoModal] = useState(false);

  // 获取主体类型
  const { data: basicType, run: basicTypeRun } = useRequest((val = 2) => getRegisterBasicType(val), {
    onSuccess(res) {
      if (!isEqual(basicType, res)) {
        form.setFieldsValue({ basicType: undefined });
      }
    },
  });
  // 获取验证码
  const { run: codeRun } = useRequest((val) => getSmsCode(val), {
    manual: true,
    onSuccess() {
      message.success('已发送，请注意查收');
    },
    onError() {
      setTime(0);
    },
  });
  // 注册
  const { run, loading } = useRequest((val) => register(val), {
    manual: true,
    onSuccess() {
      message.success('注册成功');
      Navigate('/login', { state: { url: '/home' } });
    },
  });

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    timer && clearInterval(timer);
    return () => timer && clearInterval(timer);
  }, []);

  useEffect(() => {
    if (time === 60) {
      // eslint-disable-next-line no-shadow,no-param-reassign
      timer = setInterval(() => setTime((time) => --time), 1000);
    } else if (time === 0) {
      clearInterval(timer);
    }
  }, [time]);

  function getCode() {
    const phone = form.getFieldValue('phone');
    if (phone) {
      codeRun(phone);
      setTime(60);
    } else {
      message.error('请输入手机号码');
    }
  }

  function handleChange(e) {
    setType(e);
    basicTypeRun(e);
  }

  function compareToFirstPassword(_, value) {
    const promise = Promise;
    if (value && value !== form.getFieldValue('password')) {
      return promise.reject('新密码不一致');
    }
    return promise.resolve();
  }

  function checkCode(_, value) {
    const promise = Promise;
    if (value && value.length !== 6) {
      return promise.reject('请输入6位数验证码');
    }
    return promise.resolve();
  }

  function checkAuthorize(_, value) {
    const promise = Promise;
    if (!value) {
      return promise.reject('请勾选同意协议');
    }
    return promise.resolve();
  }

  function handleToChange() {
    Navigate('/login', { state: { url: '/home' } });
  }

  return (
    <div className="register">
      <div className="register-form">
        <div className="text-center">四川农业信贷直通车 </div>
        <div className="register-form__title">新用户注册</div>
        <Form
          form={form}
          name="loginForm"
          initialValues={{
            userType: '2',
          }}
          onFinish={(val) => run(val)}
          autoComplete="off"
        >
          <Form.Item
            name="userType"
            rules={[
              {
                required: true,
                message: '请选择用户类型',
              },
            ]}
          >
            <Select onChange={handleChange}>
              <Select.Option value="2">个人</Select.Option>
              <Select.Option value="3">企业</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="basicType"
            rules={[
              {
                required: true,
                message: '请选择主体类型',
              },
            ]}
          >
            <Select placeholder="请选择主体类型">
              {
                basicType?.map((item) => (
                  <Select.Option value={item.val} key={item.val}>{item.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>

          {
            type === '3' && (
              <Form.Item
                name="creditCode"
                rules={[
                  {
                    required: true,
                    message: '请输入统一社会信用代码',
                  },
                  { pattern: /^[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}$/, message: '请输入正确的统一社会信用代码格式' },
                ]}
              >
                <Input placeholder="统一社会信用代码" />
              </Form.Item>
            )
          }

          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: '请输入手机号码',
              },
              { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: '请输入正确的手机号码格式' },
            ]}
          >
            <Input placeholder="请输入手机号码" />
          </Form.Item>

          <Form.Item
            name="smsCode"
            rules={[
              {
                required: true,
                message: '请输入短信验证码',
              },
              { validator: checkCode },
            ]}
          >
            <div className="code-box">
              <Input placeholder="请输入短信验证码" />
              <Button type="primary" disabled={time} onClick={getCode} className="auth-modal__sms-btn">{time ? `${time}s` : '获取验证码'}</Button>
            </div>
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '请设置密码',
              },
              {
                pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{8,16}$/,
                message: '密码必须为8~16位非空字符,并包含大写字母、小写字母、数字、特殊字符中的3种',
              },
            ]}
          >
            <Input.Password placeholder="请设置密码" />
          </Form.Item>
          <Form.Item
            name="repeatPassword"
            rules={[
              {
                required: true,
                message: '请再次输入密码确认设置',
              },
              { validator: compareToFirstPassword },
            ]}
          >
            <Input.Password placeholder="请再次输入密码确认设置" />
          </Form.Item>
          <Form.Item
            name="authorize"
            wrapperCol={{ span: 24 }}
            valuePropName="checked"
            rules={[
              { validator: checkAuthorize },
            ]}
          >
            <Checkbox>
              同意并接受
              <Button type="link" className="authorize-name" onClick={() => setAuthInfoModal(true)}>《平台用户服务协议》</Button>
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              注册
            </Button>
            <Button type="link" className="authorize-name" style={{ width: '100%', marginTop: 10, textAlign: 'right' }} onClick={handleToChange}>已有账号？去登录</Button>
          </Form.Item>
        </Form>
      </div>
      <Modal title="平台用户服务协议" open={authInfoModal} width={900} footer={null} onCancel={() => setAuthInfoModal(false)}>
        <div dangerouslySetInnerHTML={{ __html: AuthorizationInfo }} style={{ maxHeight: 600, overflowY: 'scroll' }} />
      </Modal>

    </div>
  );
}
