import {
  Card, Collapse, Row, Form, Col, Input, Select, DatePicker, Cascader, InputNumber,
} from 'antd';
import { any } from 'prop-types';
import { useEffect, useState } from 'react';
import moment from 'moment';

const { Panel } = Collapse;
function SubjectBasicInfo({
  formRef, initialValues, dict, area,
}) {
  const [isRequired, setIsRequired] = useState(true);
  const unitFormOption = ['农业企业', '农民合作社', '国家乡村产业振兴带头人'].map((item) => ({ value: item, label: item }));
  const industrySectorOption = ['川粮油', '川猪', '川茶', '川菜', '川果', '川药', '川牛羊', '川鱼', '现代农业种业', '现代农业装备', '现代农业冷链物流', '其他'].map((item) => ({ value: item, label: item }));

  // 禁用今天之后的日期
  const disabledDate = (current) => current && current.isAfter(moment().endOf('day'));

  useEffect(() => {
    if (formRef.getFieldValue('subjectBasicInfo')?.unitForm === '国家乡村产业振兴带头人') {
      setIsRequired(false);
    } else {
      setIsRequired(true);
    }
  }, [formRef.getFieldValue('subjectBasicInfo').unitForm]);
  return (
    <Card title="申报主体信息" className="form-info__box">
      <Collapse defaultActiveKey={['1-1']}>
        <Panel key="1-1" header="申报主体基本信息">
          <Row>
            <Col span={11}>
              <Form.Item
                name={['subjectBasicInfo', 'unitName']}
                label="单位名称/姓名"
                rules={[
                  {
                    required: true,
                    message: '单位名称/姓名',
                  },
                  { pattern: /^\s*\S.*$/, message: '单位名称/姓名' },
                ]}
              >
                <Input placeholder="单位名称/姓名" disabled />
              </Form.Item>
            </Col>

            <Col span={13}>
              <Form.Item
                name={['subjectBasicInfo', 'identityCode']}
                label="统一社会信用代码/身份证号码"
                rules={[
                  {
                    required: true,
                    message: '统一社会信用代码/身份证号码',
                  },
                ]}
              >
                <Input placeholder="统一社会信用代码/身份证号码" disabled />
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                name={['subjectBasicInfo', 'unitForm']}
                label="申报主体类型"
                rules={[
                  {
                    required: true,
                    message: '申报主体类型',
                  },
                ]}
              >
                <Select
                  placeholder="请选择申报主体类型"
                  options={unitFormOption}
                  onChange={(value) => {
                    if (value === '国家乡村产业振兴带头人') {
                      setIsRequired(false);
                    } else {
                      setIsRequired(true);
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={13}>
              <Form.Item
                style={{ display: 'none' }}
                name={['subjectBasicInfo', 'registrationLocation']}
              />
              <Form.Item
                name={['subjectBasicInfo', 'registrationLocation_i']}
                label="注册地点/户籍所在地"
                rules={[
                  {
                    required: true,
                    message: '请选择项注册地点/户籍所在地',
                  },
                ]}
              >
                <Cascader
                  options={area}
                  placeholder="请选择项注册地点/户籍所在地"
                  fieldNames={{ label: 'name', value: 'id', children: 'children' }}
                  onChange={
                    (value) => {
                      // 赋值给后端字段
                      formRef.setFieldsValue({
                        subjectBasicInfo: {
                          registrationLocation: value.join(','),
                          cityOrState: +value[1],
                        },
                      });
                    }
                  }
                />
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                name={['subjectBasicInfo', 'registrationTime']}
                label="注册时间"
                rules={isRequired ? [
                  {
                    required: true,
                    message: '请选择注册时间',
                  },
                ] : []}
              >
                <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} />
              </Form.Item>
            </Col>

            <Col span={13}>
              <Form.Item
                name={['subjectBasicInfo', 'registeredCapital']}
                label="注册资本"
                rules={isRequired ? [
                  {
                    required: true,
                    message: '请输入注册资本（万元）',
                  },
                ] : []}
              >
                <InputNumber
                  placeholder="请输入注册资本（万元）"
                  addonAfter="万元"
                  min={0}
                  max={99999999}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                name={['subjectBasicInfo', 'industrySector']}
                label="产业类型"
                rules={[
                  {
                    required: true,
                    message: '请输入产业类型',
                  },
                ]}
              >
                <Select placeholder="请输入产业类型" options={industrySectorOption} />
              </Form.Item>
            </Col>

            <Col span={13}>
              <Form.Item
                required
                name={['subjectBasicInfo', 'cityOrState']}
                label="所属市（州）"
                rules={[
                  {
                    required: true,
                    message: '请选择所属市（州）',
                  },
                ]}
              >
                <Select placeholder="请选择所属市（州）" style={{ width: '100%' }} disabled>
                  {
                    dict?.citys?.map((city) => <Select.Option key={city.code} value={city.code}>{city.name}</Select.Option>)
                  }

                </Select>
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                name={['subjectBasicInfo', 'businessScope']}
                label="经营范围"
                rules={[
                  {
                    required: true,
                    message: '请输入经营范围',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={13}>
              <Form.Item
                name={['subjectBasicInfo', 'mainProducts']}
                label="主要产品"
                rules={[
                  {
                    required: true,
                    message: '请输入主要产品',
                  },
                ]}
              >
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                name={['subjectBasicInfo', 'legalRepresentative']}
                label="法人代表"
                rules={
                  isRequired ? [
                    {
                      required: true,
                      message: '请输入法人代表',
                    },
                  ] : []
              }
              >
                <Input placeholder="请输入法人代表" />
              </Form.Item>
            </Col>

            <Col span={13}>
              <Form.Item
                name={['subjectBasicInfo', 'contactPerson']}
                label="联系人"
                rules={[
                  {
                    required: true,
                    message: '请输入联系人',
                  },
                ]}
              >
                <Input placeholder="请输入联系人" />
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                name={['subjectBasicInfo', 'contactPhone']}
                label="联系电话"
                rules={[
                  {
                    required: true,
                    message: '请输入联系电话',
                  },
                  { pattern: /(^1[3-9]\d{9}$)|(^(\d{3,4}-?|\d{3,4}\))?\d{7,8}$)/, message: '电话号码格式错误' },
                ]}
              >
                <Input placeholder="请输入联系电话" />
              </Form.Item>
            </Col>

            <Col span={13}>
              <Form.Item
                name={['subjectBasicInfo', 'contactAddress']}
                label="联系地址"
                rules={[
                  {
                    required: true,
                    message: '请输入联系地址',
                  },
                ]}
              >
                <Input placeholder="请输入联系地址" />
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                name={['subjectBasicInfo', 'bankName']}
                label="开户银行"
                rules={[
                  {
                    required: true,
                    message: '请输入开户银行',
                  },
                ]}
              >
                <Input placeholder="请输入用于接收贷款贴息资金发放的开户银行" />
              </Form.Item>
            </Col>

            <Col span={13}>
              <Form.Item
                name={['subjectBasicInfo', 'bankAccountNumber']}
                label="银行卡账号"
                rules={[
                  {
                    required: true,
                    message: '请输入银行卡账号',
                  },
                  { pattern: /^\s*\S.*$/, message: '银行卡账号' },
                ]}
              >
                <Input placeholder="请输入用于接收贷款贴息资金发放的银行卡账号" />
              </Form.Item>
            </Col>

            <Col span={11}>
              <Form.Item
                name={['subjectBasicInfo', 'faxNumber']}
                label="传  真"
                rules={[
                  { pattern: /^(?:\d{3,4}-)?\d{7,8}(?:-\d{1,6})?$/, message: '传真格式错误' },
                ]}
              >
                <Input placeholder="请输入传真" />
              </Form.Item>
            </Col>

            <Col span={13}>
              <Form.Item
                name={['subjectBasicInfo', 'postalCode']}
                label="邮政编码"
                rules={[
                  {
                    required: true,
                    message: '请输入邮政编码',
                  },
                  { pattern: /^[1-9]\d{5}$/, message: '输入正确的邮政编码' },
                ]}
              >
                <Input placeholder="请输入邮政编码" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col style={{ width: '15.3%', textAlign: 'right' }}>
              备注：
            </Col>
            <Col style={{ width: '84.7%' }}>
              <Form.Item
                name={['subjectBasicInfo', 'remark']}
                label="备注"
                noStyle
              >
                <Input.TextArea maxLength={500} showCount placeholder="请输入备注" />
              </Form.Item>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <Form.List name="annualInformation">
        {(fields) => (
          fields.map((field, index) => {
            const key = `${index}-${field.key}`;
            return (
              <Collapse defaultActiveKey={key} key={key}>
                <Panel key={key} header={`${initialValues.annualInformation[index].year}年度信息`}>
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        required
                        name={[field.name, 'employeeCount']}
                        label={(
                          <div style={{ lineHeight: '18px' }}>
                            职工人数（农民
                            <br />
                            合作社成员数）
                          </div>
                        )}
                        rules={[
                          {
                            required: true,
                            message: '请输入项目建设内容',
                          },
                        ]}
                      >
                        <InputNumber placehadle="请输入" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        required
                        name={[field.name, 'operatingIncome']}
                        label="营业收入"
                        rules={[
                          {
                            required: true,
                            message: '请输入营业收入（万元）',
                          },
                        ]}
                      >
                        <InputNumber addonAfter="万元" placehadle="请输入" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        required
                        label="利润总额"
                        name={[field.name, 'totalProfit']}
                        rules={[
                          {
                            required: true,
                            message: '利润总额',
                          },
                        ]}
                      >
                        <InputNumber addonAfter="万元" placehadle="请输入" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        required
                        label="负债总额"
                        name={[field.name, 'totalLiabilities']}
                        rules={[
                          {
                            required: true,
                            message: '请输入负债总额',
                          },
                        ]}
                      >
                        <InputNumber addonAfter="万元" placehadle="请输入" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        required
                        label="资产总额"
                        name={[field.name, 'totalAssets']}
                        rules={[
                          {
                            required: true,
                            message: '请输入资产总额',
                          },
                        ]}
                      >
                        <InputNumber addonAfter="万元" placehadle="请输入" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        required
                        label="（其中：固定资产）"
                        name={[field.name, 'fixedAssets']}
                        rules={[
                          {
                            required: true,
                            message: '请输入固定资产',
                          },
                        ]}
                      >
                        <InputNumber addonAfter="万元" placehadle="请输入" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        required
                        label="所有者权益"
                        name={[field.name, 'ownerEquity']}
                        rules={[
                          {
                            required: true,
                            message: '请输入所有者权益(万元)',
                          },
                        ]}
                      >
                        <InputNumber addonAfter="万元" placehadle="请输入" style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Panel>
              </Collapse>
            );
          })
        )}
      </Form.List>
    </Card>

  );
}

SubjectBasicInfo.propTypes = {
  formRef: any,
  initialValues: any,
  dict: any,
  area: any,
};

export default SubjectBasicInfo;
