import { object, bool, string } from 'prop-types';
import './InsuranceCard.less';
import { Button, Tooltip } from 'antd';

/**
 * 保险产品card
 * @param {object} data 数据源
 * @param {bool} isShow 是否显示按钮
 * @param {string} type 政策性保险还是涉农保险
 * @param {string} areaCode 市级code
 * */

export default function InsuranceCard({
  data, isShow, type, areaCode,
}) {
  function handleChange(id) {
    window.open(`#/insurance/detail?id=${id}&type=${type}&areaCode=${areaCode}`, '_blank');
  }

  return (
    <div className="product-list-row">
      <div className="product-list-row-img">
        <img src={data?.url} alt="" width={250} height={75} />
      </div>
      <div className="insurance-list-row-info">
        <div>
          <div className="product-list-row-name">
            <Tooltip title={data?.productName}>
              <span>{data?.productName}</span>
            </Tooltip>
          </div>
          <div>{data?.orgName || data?.publishOrgName}</div>
        </div>
        <div>
          <div className="product-list-row-title">
            {data?.premium}
            <span className="product-list-row-sub-title">元</span>
          </div>
          <div>
            /
            {data?.premiumUnit}
          </div>
        </div>
        <div>
          <div className="product-list-row-title">
            {data?.insuranceAmount}
            <span className="product-list-row-sub-title">元</span>
          </div>
          <div>最高保障额度</div>
        </div>
      </div>
      <div style={{ width: 126 }}>
        {
            !isShow
            && (
            <Button type="primary" className="bank-product-btn" onClick={() => handleChange(data.productId)}>
              立即办理
            </Button>
            )
        }
      </div>
    </div>
  );
}

InsuranceCard.propTypes = {
  data: object,
  isShow: bool,
  type: string,
  areaCode: string,
};
