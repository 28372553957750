import request from '@/utils/request';
import { apiBase } from '@/utils/config';

// 重大项目融资首页滚动数据
export function getMajorFinancingList() {
  return request({
    url: '/project/home/page/dynamic',
    method: 'GET',
  });
}
// 文件上传返回URL
export function uploadFileUrl() {
  return `${apiBase}/common/addfileurl`;
}

// 重大项目融资申请
export function addApply(data) {
  return request({
    url: '/project/add/major',
    method: 'POST',
    data,
  });
}

export function updateBackApply(data) {
  return request({
    url: '/project/edit/major',
    method: 'POST',
    data,
  });
}

// 申请记录查看退回最新记录
export function getApplicationInfo(id) {
  return request({
    url: '/project/detail',
    method: 'GET',
    params: { id },
  });
}

// 项目类型
export function getMajorProjectTypeSel() {
  return request({
    url: '/project/listItemType',
    method: 'GET',
  });
}
