import {
  Col,
  Form,
  Input,
  Row,
  Select,
  Radio,
  message,
  InputNumber,
} from 'antd';
import { useEffect } from 'react';
import { object, bool, string } from 'prop-types';
import { useRequest } from 'ahooks';
import { isEmpty, isEqual } from 'lodash';
import { getCityCode, getCityList, getSelectAbleOrg } from '@/services/common';

export default function FinancingNeeds({
  data, isHide, type, form,
}) {
  const {
    product: { productId },
    loanBank,
  } = data || {};

  const { data: cityList = [], run: cityRun } = useRequest(getCityList, {
    manual: true,
  }); // 银行直贷-意向区域列表

  const { data: bankAbleOrg = [], run } = useRequest(getSelectAbleOrg, {
    manual: true,
    onSuccess(res) {
      if (!isEqual(res, bankAbleOrg)) {
        form.setFieldsValue({ orgId: undefined });
      }
      if (isEmpty(res)) {
        message.error('该产品在该区域不可选购，请重新选择');
      }
    },
  }); // 银行直贷-意向银行下拉框数据

  const { run: cityCodeRun } = useRequest(getCityCode, {
    manual: true,
    onSuccess(res) {
      if (res) {
        form.setFieldsValue({ yxqy: res });
        run({ name: res, productId });
      }
    },
  }); // 银行直贷-意向区域回显

  useEffect(() => {
    if (type === 'yhzd') {
      cityRun();
      cityCodeRun();
    }
  }, [type]);

  function onChange(e) {
    run({ name: e, productId });
  }
  const arr = type === 'yhzd' ? bankAbleOrg : loanBank;

  return (
    <div>
      <Row>
        <Col span={12}>
          <Form.Item
            name="loanAmount"
            label="贷款申请金额"
            rules={[
              {
                required: true,
                message: '请输入贷款申请金额',
              },
            ]}
          >
            <InputNumber
              placeholder="请输入贷款申请金额"
              addonAfter="万元"
              min={0.1}
              max={9999}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="loanYear"
            label="贷款使用期限"
            rules={[
              {
                required: true,
                message: '请输入贷款使用期限',
              },
            ]}
          >
            <InputNumber
              placeholder="请输入贷款使用期限"
              addonAfter="月"
              min={1}
              max={999}
              precision={0}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
        {type === 'yhzd' && (
          <Col span={12}>
            <Form.Item
              name="yxqy"
              label="意向区域"
              rules={[
                {
                  required: true,
                  message: '请选择意向区域',
                },
              ]}
            >
              <Select placeholder="请选择意向区域" onChange={onChange}>
                {cityList.map((item) => (
                  <Select.Option value={item.val} key={item.val}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}
        {
          type !== 'dbzd' && (
            <Col span={12}>
              <Form.Item
                name="orgId"
                label="意向贷款银行"
                rules={[
                  {
                    required: true,
                    message: '请选择意向贷款银行',
                  },
                ]}
              >
                <Select
                  placeholder="请选择意向贷款银行"
                  showSearch
                  optionFilterProp="children"
                >
                  {arr?.map((item) => (
                    <Select.Option value={item.key} key={item.key}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )
        }
        {!isHide && (
          <Col span={12}>
            <Form.Item
              name="loanRate"
              label="可接受的贷款利率"
              rules={[
                {
                  required: true,
                  message: '请输入可接受的最高贷款利率',
                },
              ]}
            >
              <InputNumber
                min={0.1}
                max={100}
                placeholder="请输入可接受的最高贷款利率"
                addonAfter="%"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        )}

        <Col span={12}>
          <Form.Item
            name="loanType"
            label="资金用途"
            rules={[
              {
                required: true,
                message: '请选择资金用途分类',
              },
            ]}
          >
            <Select placeholder="请选择资金用途分类">
              {
                data?.loanType?.map((item) => (
                  <Select.Option value={item.val} key={item.val}>
                    {item.name}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="loanStatus"
            label="可提供担保抵押"
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={0}>是</Radio>
              <Radio value={1}>否</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="orientation"
            label="贷款投向"
            rules={[{ required: true, message: '请选择贷款投向' }]}
          >
            <Select placeholder="请选择贷款投向">
              {
                data?.orientatio_type?.map((item) => (
                  <Select.Option value={item.val} key={item.val}>
                    {item.name}
                  </Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="loanInfo"
            label="资金详细用途"
            rules={[{ required: true, message: '请输入资金的详细用途' }]}
          >
            <Input.TextArea
              rows={4}
              placeholder="请输入资金的详细用途"
              showCount
              maxLength={100}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
FinancingNeeds.propTypes = {
  data: object,
  isHide: bool,
  type: string,
  form: object,
};
