import { useEffect, useState } from 'react';
import { indexOf, isEmpty } from 'lodash';
import {
  array, string, func, bool,
} from 'prop-types';
import './FilterType.less';

/**
 * 筛选组件
 * @param {string} title 标题
 * @param {array} data 数据源
 * @param {func} onChange
 * @param {bool} radio 是否是单选
 * */

export default function FilterTypeChild({
  title, data, onChange, radio,
}) {
  const [select, setSelect] = useState([0]);

  // 切换数据源时，重置选项
  useEffect(() => {
    setSelect([0]);
  }, [data]);

  function handleAll() {
    setSelect([0]);
    onChange();
  }

  function handleChange(e) {
    let temp = [...select];
    const current = indexOf(select, e);
    if (current === -1) {
      temp = temp.filter((item) => item !== 0);
      if (radio) { // 是否单选
        temp = [e];
      } else {
        temp.push(e);
      }
    } else {
      temp = temp.filter((item) => item !== e);
    }
    if (isEmpty(temp)) {
      temp = [0];
      onChange();
    } else {
      onChange(temp);
    }
    setSelect(temp);
  }

  return (
    <div className="filter-type">
      <div className="filter-type__row">
        <div className="filter-type__title" style={{ width: 88 }}>{title}</div>
        <div className="filter-type-detail__box">
          <div className={`filter-type-detail__row-item ${indexOf(select, 0) !== -1 && 'filter-type-detail__active'}`} key={0} onClick={handleAll}>
            全部
          </div>
          {
            data?.map((item) => (
              <div
                className={`filter-type-detail__row-item ${indexOf(select, item.val || item.id) !== -1 && 'filter-type-detail__active'}`}
                key={item.val || item.id}
                onClick={() => handleChange(item.val || item.id)}
              >
                {item.name}
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
}

FilterTypeChild.propTypes = {
  data: array,
  title: string,
  onChange: func,
  radio: bool,
};
