import request from '@/utils/request';

/*
* 公共请求接口
* */

// 获取公钥
export function getPublickey() {
  return request({
    url: '/common/publickey',
    method: 'GET',
  });
}

// 获取字典
export function getDictType(keys) {
  return request({
    url: '/common/values',
    method: 'GET',
    params: { keys },
  });
}

// 查询合作的银行
export function getBank() {
  return request({
    url: '/bank/cooperation/bank',
    method: 'GET',
  });
}

// 查询合作的保险
export function getInsurance() {
  return request({
    url: '/insurance/info/cooperation/insurance',
    method: 'GET',
  });
}

// 银行产品信息查询/合作机构银行产品查询
export function getBankProduct(data) {
  const areaName = sessionStorage.getItem('areaName'); // 定位区域或用户选择区域
  return request({
    url: '/bank/query/financing/bank',
    method: 'POST',
    data: { ...data, areaName },
  });
}

// 保险公司产品查询
export function getInsuranceProduct(data) {
  const areaName = sessionStorage.getItem('areaName'); // 定位区域或用户选择区域
  const obj = { ...data, areaName: data.areaName ? data.areaName : areaName };
  return request({
    url: '/insurance/info/query/insurance',
    method: 'POST',
    data: obj,
  });
}

// 通过区域名称获取该区域下级
export function getArea(type) {
  const areaName = sessionStorage.getItem('areaName'); // 定位区域或用户选择区域
  return request({
    url: '/common/listChildByProvince',
    method: 'GET',
    params: { name: type ? areaName : undefined },
  });
}

// 通过区域名称获取该区域下级-默认跟随顶端地区选项
export function getAreaWithName(type = sessionStorage.getItem('areaName')) {
  return request({
    url: '/common/listChildByProvince',
    method: 'GET',
    params: { name: type },
  });
}

// 修改用户基本信息-经营信息
export function updateUserBasic(data) {
  return request({
    url: '/basic/update/basic/loan',
    method: 'POST',
    data,
  });
}

// 意向贷款银行
export function getLoanBank(type) {
  const areaName = sessionStorage.getItem('areaName'); // 定位区域或用户选择区域
  return request({
    url: '/common/intention/org',
    method: 'GET',
    params: { name: areaName, type },
  });
}

// 联系方式、客户服务
export function getTel() {
  const areaName = sessionStorage.getItem('areaName'); // 定位区域或用户选择区域
  return request({
    url: '/common/complains/phone',
    method: 'GET',
    params: { name: areaName },
  });
}

// 获取验证码
export function getSmsCode(phone) {
  return request({
    url: '/user/getSmsCode',
    method: 'GET',
    params: { phone },
  });
}

// 银行 - 申请
export function addBank(data) {
  return request({
    url: '/bank/add/loan/product',
    method: 'POST',
    data,
  });
}

// 广播融资 - 申请
export function addBroadcast(data) {
  return request({
    url: '/bank/add/loan/financing',
    method: 'POST',
    data,
  });
}

// 保险 - 融资担保-添加融资担保申报
export function addInsurance(data) {
  return request({
    url: '/insurance/info/add/insurance',
    method: 'POST',
    data,
  });
}

// 担保助农 - 申请
export function addGuarantee(data) {
  return request({
    url: '/insurance/info/add/guarantee',
    method: 'POST',
    data,
  });
}

// 融资申请前判断验证码是否有效
export function applyVerifyCode(data) {
  return request({
    url: '/common/verify/apply/code',
    method: 'POST',
    data,
  });
}

// 获取保险类别
export function getInsuranceType() {
  return request({
    url: '/common/listInsurance',
    method: 'GET',
  });
}
// 保险区域
export function getInsuranceArea() {
  return request({
    url: '/common/listInsuranceRegion',
    method: 'GET',
  });
}

// 根据主体信息获取可选的主体类型
export function getBasicType() {
  return request({
    url: '/common/basicType',
    method: 'GET',
  });
}

// 根据产品ID和区域获取意向银行
export function getSelectAbleOrg(params) {
  return request({
    url: '/common/selectAbleOrg',
    method: 'GET',
    params,
  });
}

// 银行直贷-意向区域回显
export function getCityCode() {
  const areaCode = sessionStorage.getItem('areaName'); // 定位区域或用户选择区域
  return request({
    url: `/common/cityCode/${areaCode}`,
    method: 'GET',
  });
}

// 银行直贷-意向区域列表（市级）
export function getCityList() {
  return request({
    url: '/common/citylist',
    method: 'GET',
  });
}

// 获取验证码
export function getSmsCodeApply(params) {
  // type:  1：产品融资申请 2 广播融资申请 3 担保申请 4 登录验证码
  return request({
    url: '/user/getSmsCodeForApply',
    method: 'GET',
    params,
  });
}

// 统计产品详情
export function getProductNum(data) {
  // productType:  1：银行   2：保险
  // platformType  1: web端 2：h5
  return request({
    url: '/product/access',
    method: 'POST',
    data,
  });
}
