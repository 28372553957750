import { Form, Input } from 'antd';
import { func, bool } from 'prop-types';
import CustomFooter from '@/components/CustomFooter/CustomFooter';

import '../index.less';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
};

export default function UpdatePass({ onOk, onCancel, confirmLoading }) {
  const [form] = Form.useForm();

  function onFinish() {
    form.validateFields().then((values) => {
      onOk(values);
    });
  }

  function compareToFirstPassword(rule, value) {
    const promise = Promise;
    if (value && value !== form.getFieldValue('newPassword')) {
      return promise.reject('两次输入的密码不匹配!');
    }
    return promise.resolve();
  }

  return (
    <>
      <Form
        form={form}
        {...formItemLayout}
        name="form"
        className="update-tel-form"
      >

        <Form.Item
          label="旧密码"
          name="oldPassword"
          rules={[{ required: true, message: '请输入旧密码' }]}
        >
          <Input.Password placeholder="请输入旧密码" />
        </Form.Item>
        <Form.Item
          label="新密码"
          name="newPassword"
          rules={[
            { required: true, message: '请输入新密码' },
            {
              pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{8,16}$/,
              message: '密码必须为8~16位非空字符,并包含大写字母、小写字母、数字、特殊字符中的3种',
            },
          ]}
        >
          <Input.Password placeholder="请输入新密码" />
        </Form.Item>
        <Form.Item
          label="新密码确认"
          name="repeatPassword"
          rules={[
            { required: true, message: '请再次输入新密码' },
            { validator: compareToFirstPassword },
          ]}
        >
          <Input.Password placeholder="请再次输入新密码" />
        </Form.Item>
      </Form>
      <CustomFooter onOk={() => onFinish()} onCancel={onCancel} confirmLoading={confirmLoading} />
    </>

  );
}

UpdatePass.propTypes = {
  onOk: func,
  onCancel: func,
  confirmLoading: bool,
};
