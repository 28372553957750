import { isEmpty } from 'lodash';

// 文件回显处理
export const getFileList = (files = []) => {
  const arr = [];
  if (!isEmpty(files)) {
    files.forEach((item, index) => {
      arr.push({
        uid: index.toString(),
        name: item.fileName,
        status: 'done',
        url: item.fileUrl,
      });
    });
  }
  return arr;
};

// 附件提交处理
export const getFileListVal = (data) => {
  let arr = [];
  if (data?.fileList) {
    arr = data?.fileList.map((item) => ({
      fileName: item.name,
      fileUrl: item?.response?.data || item?.url,
    }));
  } else if (data) {
    arr = data.map((item) => ({
      fileName: item.fileName,
      fileUrl: item.fileUrl,
    }));
  }
  return arr;
};
