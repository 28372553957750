import './index.less';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import Timeline from '../components/Timeline/Timeline';
import MajorProjectTimeline from '../components/Timeline/MajorProjectTimeline';
import FacilityLoanTimeline from '../components/Timeline/FacilityLoanTimeline';
import CreditGuaranteeTimeline from '../components/Timeline/CreditGuaranteeTimeline';
import IndustryBankLoanTimeline from '../components/Timeline/IndustryBankLoanTimeline';

export default function Detail() {
  const { search } = useLocation();
  return (
    <div className="myapplication-detail">
      <div className="content-width">
        {
          qs.parse(search).type === '5' && <MajorProjectTimeline />
        }
        {
          qs.parse(search).type === '6' && <FacilityLoanTimeline />
        }
        {
          qs.parse(search).type === '7' && <CreditGuaranteeTimeline />
        }
        {
          qs.parse(search).type === '8' && <IndustryBankLoanTimeline />
        }
        {
          qs.parse(search).type !== '5'
          && qs.parse(search).type !== '6'
          && qs.parse(search).type !== '7'
          && qs.parse(search).type !== '8'
          && <Timeline />
        }
      </div>
    </div>
  );
}
