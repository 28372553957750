import request from '@/utils/request';

// 我的申请列表
export function getApplication(data) {
  return request({
    url: '/basic/financing',
    method: 'POST',
    data,
  });
}

// 申请进度详情
export function getFinancingInfo({ id, type }) {
  return request({
    url: `/basic/financing/detail?id=${id}&type=${type}`,
    method: 'GET',
  });
}

// 流程 我的申请取消
export function deleteApplication(data) {
  return request({
    url: '/bank/application/cancel',
    method: 'DELETE',
    data: { ...data },
  });
}

// 我的申请-根据流程表id查询重大项目信息详情
export function getProjectFinancingInfo(id) {
  return request({
    url: '/project/financing/detail',
    method: 'GET',
    params: { id },
  });
}

// 我的申请-用户完结
export function updateEnd(id) {
  return request({
    url: '/project/finish',
    method: 'GET',
    params: { id },
  });
}
