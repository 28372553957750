// 设置userInfo 用户信息
export const USER_INFO = 'USER_INFO';
// 设置userBasicInfo 用户基本信息
export const USER_BASIC_INFO = 'USER_BASIC_INFO';
// 设置area 区域三级联动
export const AREA = 'AREA';
// 设置status 是否认证
export const STATUS = 'STATUS';
// 设置userType 用户类型
export const USER_TYPE = 'USER_TYPE';
