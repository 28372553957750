import { useState } from 'react';
import {
  Steps, Form, Cascader, Button, Spin, Pagination,
} from 'antd';
import { useRequest } from 'ahooks';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { string } from 'prop-types';
import InsuranceCard from '@/components/ProductFilter/InsuranceCard';
import { getInsuranceProduct, getInsuranceType, getInsuranceArea } from '@/services/common';
import EmptyImg from '@/components/EmptyImg/EmptyImg';
import './PolicyInsurance.less';

export default function PolicyInsurance({ insuranceId }) {
  const [form] = Form.useForm();
  const Navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [val, setVal] = useState({});

  const { data: insuranceType } = useRequest(getInsuranceType); // 保险类别
  const { data: insuranceArea } = useRequest(getInsuranceArea); // 保险区域

  const { run, loading, data } = useRequest((e) => getInsuranceProduct({ ...e, insuranceType: 2, insuranceId: insuranceId ? [insuranceId] : undefined }), { // 保险产品
    manual: true,
    onSuccess(res) {
      if (res?.totalRows === 1) {
        const id = res?.items?.[0].productId;
        Navigate(`/insurance/detail?id=${id}&type=zcbx&areaCode=${val?.cityId}`);
      }
    },
  });

  function handleNext() {
    form.validateFields().then((values) => {
      const obj = {
        cityId: values?.area?.[0] || val?.cityId,
        areaId: values?.area?.[1] || val?.areaId,
        townId: values?.area?.[2] || val?.townId,
        insurancePlant: values?.type?.[0],
        insurancePlantFlood: values?.type?.[1],
        insurancePlantThree: values?.type?.[2],
      };
      if (current === 1) {
        run(obj);
      }
      setVal(obj);
      setCurrent(current + 1);
    });
  }

  function handlePrev() {
    setCurrent(current - 1);
  }

  function onChange(page, size) {
    run({ pageNum: page, pageSize: size, ...val });
  }
  return (
    <div className="policy-insurance">
      <Spin spinning={loading}>
        <div>
          政策性保险是以保险公司市场化经营为依托，政府通过保费补贴等政策扶持，对种植业、养殖业、林业因遭受自然灾害和意外事故造成的经济损失提供的直接物化成本保险。
          政策性保险将财政手段与市场机制相对接，可以创新政府救灾方式，提高财政资金使用效益，分散农业风险，促进涉农主体收入可持续增长。
        </div>
        <div className="policy-insurance__steps">
          <Steps current={current} type="navigation">
            <Steps.Step title="步骤一" description="选择区域" />
            <Steps.Step title="步骤二" description="选择保险类型" />
            <Steps.Step title="步骤三" description="选择产品" />
          </Steps>
          <Form
            form={form}
            name="basic"
            className="policy-insurance__form"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 6,
            }}
            autoComplete="off"
          >
            {
              current === 0 && (
                <Form.Item
                  label="区域选择"
                  name="area"
                  rules={[
                    {
                      required: true,
                      message: '请选择区域',
                    },
                  ]}
                >
                  <Cascader
                    placeholder="请选择区域"
                    options={insuranceArea}
                    allowClear={false}
                    fieldNames={{ label: 'name', value: 'id', children: 'children' }}
                  />
                </Form.Item>
              )
            }
            {
              current === 1 && (
                <Form.Item
                  label="保险分类"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: '请选择保险分类',
                    },
                  ]}
                >
                  <Cascader
                    placeholder="请选择保险分类"
                    options={insuranceType}
                    allowClear={false}
                    fieldNames={{ label: 'name', value: 'code', children: 'children' }}
                  />
                </Form.Item>
              )
            }
          </Form>
          {
            current === 2 && (
              <div>
                {
                  isEmpty(data?.items) ? <EmptyImg />
                    : (
<div className="policy-insurance__list">
                      {data?.items?.map((item) => (
                        <InsuranceCard data={item} key={item.productId} type="zcbx" areaCode={val?.cityId} />
                      ))}
                      <div className="pagination-box">
                        <Pagination
                          showQuickJumper
                          defaultCurrent={data?.pageNum}
                          total={data?.totalRows}
                          onChange={onChange}
                        />
                      </div>
</div>
                    )
                }
              </div>
            )
          }
          <div className="policy-insurance__form-btn">
            {
              current !== 0 && <Button type="primary" ghost onClick={handlePrev}>上一步</Button>
            }
            {
              current !== 2 && <Button type="primary" onClick={handleNext}>下一步</Button>
            }
          </div>
        </div>
      </Spin>
    </div>
  );
}

PolicyInsurance.propTypes = {
  insuranceId: string,
};
