import { useState } from 'react';
import {
  Spin, Collapse, Tooltip,
} from 'antd';
import { CheckOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'query-string';
import { useRequest } from 'ahooks';
import moment from 'moment';
import { isNull } from 'lodash';
import { getfacilityLoanApplicationDetail, attchmentFileDownload } from '@/services/facilityLoan';
import './MajorProjectTimeline.less';
import AttachmentDownload from '@/components/AttachmentDownload/AttachmentDownload';
import { downloadFile } from '@/utils/utils';
import { getDictType } from '@/services/common';
import { FACILITY_LOAN_TYPE } from '@/utils/mappingDict';

const obj = {
  0: 'processing', 2: 'processing', 3: 'success', 4: 'success', 5: 'error', 9: 'cancel', // status 为0正常状态，进行中UI为实心数字； 1 拒绝； 2取消； 3：所有流程完成
};

export default function FacilityLoanTimeline() {
  const { search } = useLocation();
  const { id } = qs.parse(search);
  const { userType } = useSelector((state) => state);

  const { data: dict = {} } = useRequest(getDictType, { // 筛选项字典
    defaultParams: [FACILITY_LOAN_TYPE],
  });

  const [activeKeys, setActiveKeys] = useState([1]);

  const { data = {}, loading } = useRequest(() => getfacilityLoanApplicationDetail(id), {
    onSuccess: (res) => {
      const allDetail = res.facilityApplicationLoanVos.map((item) => item.id);
      setActiveKeys(allDetail);
    },
  });
  const { loading: downloadLoading, run: downloadRun } = useRequest(attchmentFileDownload, {
    manual: true,
    onSuccess: ({ data: fileData, filename }) => {
      downloadFile(fileData, filename);
    },
  });

  function getIcon(status, index) {
    if (status === 0 || status === 2) {
      return index;
    } if (status === 5 || status === 9) {
      return <CloseOutlined />;
    }
    return <CheckOutlined />;
  }

  return (
    <Spin spinning={loading}>
      <div>
        <div className="myapplication-detail__title">进度查询</div>
        <div className="myapplication-detail__sub-title">我的申请信息</div>
        <div className="myapplication-detail__grid">
          <div>
            项目名称：
            {data?.projectName}
          </div>
          <div>
            申报区域：
            {data?.declareCity}
            -
            {data?.declareArea}
          </div>
          <div>
            申报年度：
            {data?.year}
          </div>
          <div>
            项目进展：
            {data?.projectProgress}
          </div>
          <div>
            项目所在区域：
            {data?.provinceId}
            -
            {data?.cityId}
            -
            {data?.areaId}
          </div>
          <div>
            项目详细地址：
            {data?.address}
          </div>
          <div>
            自有资金（万元）：
            {data?.ownFunds}
          </div>
          <div>
            财政资金（万元）：
            {data?.financialFunds}
          </div>
          <div>
            其他资金（万元）：
            {data?.otherFunds}
          </div>
          <div>
            总投资金额（万元）：
            {data?.investmentAmount}
          </div>
          <div>
            项目建设内容：
            {data?.projectContent}
          </div>
          <div>
            其他可补充信息：
            {data?.content}
          </div>
        </div>

        {
          userType === 3 && (
            <>

              <div className="myapplication-detail__sub-title">申报主体信息（企业）</div>
              <div className="myapplication-detail__grid">
                <div>
                  企业名称：
                  {data?.applicantName}
                </div>
                <div>
                  统一社会信用代码：
                  {data?.applicantNo}
                </div>
                <div>
                  联系人：
                  {data?.contactPerson}
                </div>
                <div>
                  联系方式：
                  {data?.contactTel}
                </div>
                <div>
                  开户银行：
                  {data?.bankDeposit}
                </div>
                <div>
                  开户账号：
                  {data?.openAccount}
                </div>
                <div className="myapplication-detail__grid" style={{ marginTop: 0, gridTemplateColumns: 'none' }}>
                  <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <span style={{ width: 270 }}>法人执照副本及章程扫描件：</span>
                    {data?.legalPersonFileVos?.map((file) => (
                      <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
                    ))}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <span style={{ width: 270 }}>企业信用报告：</span>
                    {data?.creditReportFileVos?.map((file) => (
                      <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
                    ))}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <span style={{ width: 270 }}>开户许可证：</span>
                    {data?.openingPermitFileVos?.map((file) => (
                      <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
                    ))}
                  </div>
                </div>
              </div>
            </>
          )
        }

        {
          userType === 2 && (
            <>
              <div className="myapplication-detail__sub-title">申报主体信息（个人）</div>
              <div className="myapplication-detail__grid">
                <div>
                  姓名：
                  {data?.applicantName}
                </div>
                <div>
                  身份证号码：
                  {data?.applicantNo}
                </div>
                <div>
                  联系方式：
                  {data?.contactTel}
                </div>
                <div>
                  开户银行：
                  {data?.bankDeposit}
                </div>
                <div>
                  开户账号：
                  {data?.openAccount}
                </div>
              </div>
            </>
          )
        }

        <div className="myapplication-detail__sub-title">贴息金额</div>
        <div className="myapplication-detail__grid">
          <div>
            总贷款金额（万元）：
            {data?.loanAmount}
          </div>
          <div>
            总贴息金额（元）：
            {Number(data?.discountAmount) === 0 || data?.releaseFlag === 0 ? '-' : data?.discountAmount}
          </div>
          <div />
        </div>
        <Collapse activeKey={activeKeys} onChange={(key) => setActiveKeys(key)}>
          {data?.facilityApplicationLoanVos?.map((item, index) => (
            <Collapse.Panel
              header={`贷款信息${index + 1}`}
              key={item.id}
            >
              <div className="myapplication-detail__grid">
                <div>
                  贷款银行：
                  {item.lendingBank}
                </div>
                <div>
                  贷款期间：
                  {moment(item.lendingStart).format('YYYY-MM-DD')}
                  ~
                  {moment(item.lendingEnd).format('YYYY-MM-DD')}
                </div>
                <div>
                  贷款金额（万元）：
                  {item.loanAmount}
                </div>
                <div>
                  贷款利率（%）：
                  {item.loanRate}
                </div>
                <div>
                  贷款类型：
                  {dict?.facilityLoanType?.find((e) => e.val === item.loanType.toString())?.name ?? '-'}
                </div>
              </div>
              <div className="myapplication-detail__grid" style={{ marginTop: 10 }}>
                <div>
                  实际贷款时间：
                  {
                    isNull(item.actualLoanTime) || data?.releaseFlag === 0 ? '-' : (
                      <span>
                        {item.actualLoanTime}
                        {item.actualLoan === 1 ? '天' : '月'}
                      </span>
                    )
                  }
                </div>
                <div>
                  折算贷款额（万元）：
                  {isNull(item.loanConvertAmount) || data?.releaseFlag === 0 ? '-' : item.loanConvertAmount}
                </div>
                <div>
                  LPR（%）：
                  {isNull(item.lpr) || data?.releaseFlag === 0 ? '-' : item.lpr}
                </div>
                <div>
                  贴息率（%）：
                  {isNull(item.interestRate) || data?.releaseFlag === 0 ? '-' : item.interestRate}
                </div>
                <div>
                  贴息金额（元）
                  <span>
                    <Tooltip
                      title={(
                        <>
                          <div>
                            贴息金额 = 折算贷款额 ×
                            中国人民银行发布的2023年1月同期LPR×70%（若LPR的70%超过2%，则按2%的比例给予贴息）
                            单个年度获得的贴息资金不超过200万元。
                          </div>
                          <div>折算贷款额 = 实际贷款额 × 实际贷款时间(天) / 365</div>
                          <div>或 折算贷款额 = 实际贷款额 × 实际贷款时间(月) / 12</div>
                        </>
                      )}
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                  ：
                  {isNull(item.discountAmount) || data?.releaseFlag === 0 ? '-' : item.discountAmount}
                </div>
              </div>
              <div className="myapplication-detail__grid" style={{ marginTop: 20, gridTemplateColumns: 'none' }}>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                  <span style={{ width: 270 }}>贷款已获得其他各级财政资金贴息情况：</span>
                  {item?.facilityLoanFileVos?.map((file) => (
                    <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
                  ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                  <span style={{ width: 270 }}>贷款合同扫描件：</span>
                  {item?.loanContractFileVos?.map((file) => (
                    <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
                  ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                  <span style={{ width: 270 }}>贷款资金提款清单扫描件：</span>
                  {item?.fundsWithdrawalFileVos?.map((file) => (
                    <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
                  ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                  <span style={{ width: 270 }}>贷款资金使用情况说明：</span>
                  {item?.loanFundsFileVos?.map((file) => (
                    <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
                  ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                  <span style={{ width: 270 }}>支付银行利息汇总表及利息结算清单：</span>
                  {item?.bankInterestFileVos?.map((file) => (
                    <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
                  ))}
                </div>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                  <span style={{ width: 270 }}>其他材料：</span>
                  {item?.otherFileVos?.map((file) => (
                    <AttachmentDownload key={file.id} attachmentId={file.id} attachmentName={file.fileName} downloadFunc={downloadRun} downloadLoading={downloadLoading} />
                  ))}
                </div>
              </div>
            </Collapse.Panel>
          ))}
        </Collapse>

        <div className="myapplication-detail__sub-title">进度信息</div>
        <div className="major-project-timeline">
          {
            data?.userLogs?.map((item, index) => (
              <div className={`timeline-item timeline-item-${index + 1 === data?.userLogs.length && obj?.[item.status]}`}>
                <div className="timeline-icon">
                  {
                    index + 1 === data?.userLogs.length
                      ? (
                        <>
                          {getIcon(item.status, index + 1)}
                        </>
                      ) : <CheckOutlined />
                  }
                </div>
                <div className="timeline-cont">
                  <div className="timeline-title">{item.title}</div>
                  <div>{item.operateTime}</div>
                  <div>
                    {!isNull(item.reasonDetail) ? '不通过原因：' : null}
                    {item.reasonDetail}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </Spin>
  );
}
