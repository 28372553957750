import { Spin, Button } from 'antd';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useRequest } from 'ahooks';
import { isEmpty } from 'lodash';
import { getPolicyDetail } from '@/services/policy';
import './index.less';

export default function Detail() {
  const { search } = useLocation();
  const { data, loading } = useRequest(() => getPolicyDetail(qs.parse(search).id));

  // 下载附件
  const handleDownload = (url) => {
    window.open(url);
  };

  return (
    <div className="zczx-detail ">
      <Spin spinning={loading}>
        <div className="content-width">
          <div className="zczx-detail__title">{data?.title}</div>
          <div>
            新闻来源：
            {data?.articleSource || '-'}
&emsp;&emsp;发布时间：
            {data?.gmtPublish}
          </div>
          <div className="zczx-detail__con">
            <div dangerouslySetInnerHTML={{ __html: data?.content }} />
          </div>
          {!isEmpty(data?.appendixList) && (
            <div className="zczx-detail_appendix">
              <div style={{ lineHeight: '32px' }}>附件：</div>
              {data?.appendixList.map((item) => (
                <div className="manage-detail-tab_grid-download" key={item.id}>
                  <span>{item.appendixName}</span>
                  &nbsp;
                  <Button
                    type="primary"
                    icon={<VerticalAlignBottomOutlined />}
                    onClick={() => handleDownload(item.appendixPath)}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
}
