import { Tabs } from 'antd';
import { useEffect } from 'react';
import { useRequest } from 'ahooks';
import LoanDeclar from './components/LoanDeclar';
import PublicList from './components/PublicList';
import FixMenu from '@/components/FixMenu/FixMenu';
import { getAreaWithName } from '@/services/common';
import './index.less';

export default function Index() {
  const { data: area = [] } = useRequest(getAreaWithName, {
    defaultParams: ['510000'],
  }); // 固定获取四川省内区域

  const data = { area };

  const items = [
    { label: '担保奖补申报', key: '2', children: <LoanDeclar type="bx" data={data} filter={false} /> },
    { label: '公示名单', key: '3', children: <PublicList type="bx" data={data} /> },
  ];

  useEffect(() => {
  }, []);

  return (
    <div className="bxzn">
      <div className="dbjb-banner">
        担保奖补申报通知
      </div>
      <div className="content-width">
        <Tabs items={items} centered />
      </div>
      <FixMenu />
    </div>
  );
}
