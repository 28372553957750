import { Button } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import {
  bool, func, number, string,
} from 'prop-types';
import './AttachmentDownload.less';

export default function LoanFileDownload({
  attachmentId, attachmentName, downloadFunc, filetype, downloadLoading,
}) {
  return (
    <div className="detail-tab_grid-download">
      <span>{attachmentName}</span>
      &nbsp;
      <Button type="primary" icon={<CloudDownloadOutlined />} loading={downloadLoading} onClick={() => downloadFunc(attachmentId, filetype)} />
    </div>
  );
}

LoanFileDownload.propTypes = {
  attachmentId: number,
  attachmentName: string,
  downloadFunc: func,
  downloadLoading: bool,
  filetype: number,
};

LoanFileDownload.defaultProps = {
  attachmentId: -1,
  attachmentName: '',
  downloadFunc: () => { },
  downloadLoading: false,
  filetype: 1,
};
